import axios from "axios";
import { BASE_URL } from "../constants";

export const signIn = ({ username, password }) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/auth/signIn",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
    data: JSON.stringify({ username, password }),
  });
};