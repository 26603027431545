import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/authSlice";
import { signIn } from "../services/auth";
import { setUserData } from "../utils/localStorage";
import { errorMessage } from "../utils/alertMessage";
import logo from "../assets/logo.png";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();
  const usernameRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    signIn({
      username: usernameRef.current.value,
      password: passwordRef.current.value,
    })
      .then((response) => {
        if (!response.data.error) {
          const token = response.data.accessToken;
          setUserData({ token });
          dispatch(setToken(token));
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  return (
    <main className="main">
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <div className="logo d-flex align-items-center w-auto">
                    <span className="d-lg-block">
                      <img
                        src={logo}
                        alt="MVN Financeseva"
                        style={{ maxHeight: "100px" }}
                      />
                    </span>
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-2 pb-2 text-center">
                      <h5 className="card-title pb-0 fs-4">Login</h5>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-12">
                        <label htmlFor="mobile" className="form-label">
                          Username
                        </label>
                        <div className="input-group has-validation">
                          <input
                            ref={usernameRef}
                            type="text"
                            name="username"
                            className="form-control"
                            id="username"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="otp" className="form-label">
                          Password
                        </label>
                        <div className="input-group has-validation">
                          <input
                            ref={passwordRef}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                            id="password"
                            required
                          />
                          {/* Move the eye icon inside the input box */}
                          <span
                            className="input-group-addon"
                            onClick={handleTogglePassword}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "relative",
                              left: "-10px",
                              marginLeft:"-15px"
                            }}
                          >
                            <i
                              className={`bi ${
                                !showPassword
                                  ? "bi-eye-slash-fill"
                                  : "bi-eye-fill"
                              }`}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Login;
