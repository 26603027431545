import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const addJobUser = (data) => {
  return axios({
    method: "post",
    // url: BASE_URL + "api/secure/v1/mvn-job-user",
    url: BASE_URL + "api/secure/v2/mvn-job-user",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getJobUser = () => {
  return axios({
    method: "get",
    // url: BASE_URL + "api/secure/v1/mvn-job-user",
    url: BASE_URL + "api/secure/v2/mvn-job-user",
    headers: getHeaders(),
  });
};

export const updateJobUser = (data) => {
  return axios({
    method: "put",
    // url: BASE_URL + "api/secure/v1/mvn-job-user",
    url: BASE_URL + "api/secure/v2/mvn-job-user",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

