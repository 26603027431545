import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link } from "react-router-dom";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getClient } from "../../services/client";
import Table from "react-bootstrap/Table";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "jspdf-autotable";
import jsPDF from "jspdf";
import logoBig from "../../assets/logo1.png";

export default function ClientList() {
  const [products, setProducts] = useState(null);
  const [layout, setLayout] = useState("list");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [clientInfo,setClientInfo] = useState([])

  const fetchClient = () => {
    getClient()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setProducts(
            Res.sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
          );
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  // const fetchClientById = (clientId) => {
  //   getClientById(clientId)
  //     .then((response) => {
  //       if (!response.data.error) {
  //         const Res = response.data;
  //         const newData = Res.customerFileJobDTOList.map((item) => ({
  //           id: item.customerFile.id,
  //           fees: item.customerFile.totalCost,
  //           frequency: item.mvnJobList[0].frequency,
  //           service: item.mvnJobList[0].service,
  //           task: item.mvnJobList.map((job) => job.name),
  //         }));

  //         console.log('newData', newData);
  //         setClientInfo(newData);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error:", err);
  //     });
  // };

  useEffect(() => {
    fetchClient();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products?.filter((p) =>
      p.name.toLowerCase().includes(searchQueryValue.toLowerCase())
    );
    setFilteredProducts(
      filteredProducts.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      )
    );
    setSearchQuery(searchQueryValue);
  };

  const downloadPDF = (data, type) => {
    const doc = new jsPDF("landScape");
    const logoImg = new Image();
    logoImg.src = logoBig;

    doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
    doc.setFontSize(20);
    doc.text("Customer Data", 220, 15);

    doc.autoTable({
      head: [["Name", "Profile", "Auth Person", "Mobile No.", "Email Id"]],
      body: data.map((item) => [
        item.name,
        item.profile,
        item.authorizedPerson,
        item.authorizedPersonMobile,
        item.email,
      ]),
      startY: 25,
      theme: "grid",
      headStyles: {},
      styles: {
        overflow: "linebreak",
        fontSize: 10,
      },
      // columnStyles: {
      //   0: { cellWidth: 60 },
      //   1: { cellWidth: 30 },
      //   2: { cellWidth: 30 },
      //   3: { cellWidth: 30 },
      //   4: { cellWidth: 40 },
      // },
    });

    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      doc.save("Customer-data.pdf");
    }
  };

  const renderListItem = (data, indexing) => {
    return (
      <div className="col-12 ">
        <Table className="backgroundDesign">
          <tr>
            <td
              style={{
                width: "25%",
                fontSize: "13px",
                padding: "0px 5px",
                fontWeight: 500,
              }}
            >
              {data.name}
            </td>
            <td
              style={{
                width: "15%",
                fontSize: "13px",

                fontWeight: 500,
              }}
            >
              {data.profile}
            </td>
            <td style={{ width: "15%", fontSize: "13px", fontWeight: 500 }}>
              {data.authorizedPerson}
            </td>
            <td style={{ width: "10%", fontSize: "13px", fontWeight: 500 }}>
              {data.authorizedPersonMobile}
            </td>
            <td style={{ width: "15%", fontSize: "13px", fontWeight: 500 }}>
              {data.email?.substring(0, 15)}
              {data.email?.length > 15 ? "..." : ""}
            </td>
            <td style={{ width: "10%" }}>
              <div className="rowDiv">
                <div style={{ marginRight: 10 }}>
                  <Link
                    to={"/customer-file/list"}
                    state={data.id}
                    className="move-btn-small move-btn-vu center-text"
                  >
                    <i className="bi bi-box-arrow-in-right"></i>
                  </Link>
                </div>
                <div style={{ marginRight: 10 }}>
                  <Link
                    to={"/client/edit"}
                    state={data.id}
                    className="move-btn-small move-btn-re center-text"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                </div>
                <Link
                  to={"/client/view"}
                  state={data.id}
                  className="move-btn-small move-btn-vu center-text"
                >
                  <i class="bi bi-file-earmark-bar-graph"></i>
                </Link>
              </div>
            </td>
          </tr>
        </Table>
      </div>
    );
  };

  const renderGridItem = (data, index) => {
    return (
      <div className="col-12">
        <div className="col-12 backgroundDesign">
          <div className="rowDiv">
            <div className="col-5">
              <p className="p-class">
                <i className="pi pi-user product-category-icon blueColorIcon"></i>
                {data.name}
              </p>
            </div>
            <div className="col-5">
              <p className="p-class">
                <i className="pi pi-building product-category-icon orangeColorIcon"></i>
                {data.profile}
              </p>
            </div>
            <div className="col-2">
              <Link
                to={"/customer-file/list"}
                state={data.id}
                className="move-btn-small move-btn-vu center-text"
              >
                <i className="bi bi-box-arrow-in-right"></i>
              </Link>
            </div>
          </div>

          <div className="rowDiv">
            <div className="col-5">
              <p className="p-class">
                <i class="bi bi-person-bounding-box product-category-icon blueColorIcon"></i>
                {data.authorizedPerson}
              </p>
            </div>
            <div className="col-5">
              <p className="p-class">
                <i className="pi pi-phone product-category-icon orangeColorIcon"></i>
                {data.authorizedPersonMobile}
              </p>
            </div>
            <div className="col-2">
              <Link
                to={"/client/edit"}
                state={data.id}
                className="move-btn-small move-btn-re center-text"
              >
                <i className="bi bi-pencil-square"></i>
              </Link>
            </div>
          </div>
          <div className="rowDiv">
            <div className="col-10">
              <p className="p-class">
                <i className="pi pi-envelope product-category-icon blueColorIcon"></i>
                {data.email}
              </p>
            </div>
            <div className="col-2">
              <Link
                to={"/client/view"}
                state={data.id}
                className="move-btn-small move-btn-vu center-text"
              >
                <i class="bi bi-file-earmark-bar-graph"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    const layout = windowWidth > 460 ? "list" : "grid";
    return (
      <div className="grid grid-nogutter">
        <div className="col-6" style={{ textAlign: "left", margin: "5px" }}>
          <InputText
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>{" "}
        <div className="col-6" style={{ display: "none", textAlign: "right" }}>
          <DataViewLayoutOptions
            layout={layout}
            onChange={(e) => setLayout(e.value)}
          />
        </div>
        {layout === "list" ? (
          <div className="col-12">
            <Table className="table-design-heading">
              <tr>
                <th style={{ width: "25%", padding: "5px 5px" }}>
                  Customer Name
                </th>
                <th style={{ width: "15%", padding: "5px 1px" }}>Profile</th>
                <th style={{ width: "15%", padding: "5px 1px" }}>
                  Auth Person
                </th>
                <th style={{ width: "10%", padding: "5px 1px" }}>Mobile No.</th>
                <th style={{ width: "15%", padding: "5px 1px" }}>Email Id</th>
                <th style={{ width: "10%", padding: "5px 1px" }}>Action</th>
              </tr>
            </Table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const header = renderHeader();

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Customer List</h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <InputText
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 10,
                      // justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <div className="col-md-2" style={{ alignSelf: "center" }}>
                      <Link
                        to="/client/create"
                        className="move-btn move-btn-su center-text"
                      >
                        Create
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 20,
                          marginTop: 10,
                        }}
                      >
                        <i
                          onClick={() =>
                            downloadPDF(
                              filteredProducts || products,
                              "download"
                            )
                          }
                          className="pi pi-download"
                          style={{
                            fontSize: "2rem",
                            color: "#1e439b",
                            cursor: "pointer",
                          }}
                        ></i>
                        <i
                          onClick={() =>
                            downloadPDF(filteredProducts || products, "print")
                          }
                          className="pi pi-print"
                          style={{
                            fontSize: "2rem",
                            color: "#ff0101",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dataview-demo">
                  <div className="card">
                    {/*<DataView
                      value={filteredProducts || products}
                      layout={layout}
                      header={header}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
                />*/}
                    <DataTable
                      value={filteredProducts || products}
                      paginator
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Name"
                        resizeable={false}
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="profile"
                        header="Profile"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="authorizedPerson"
                        header="Auth Person"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="authorizedPersonMobile"
                        header="Mobile No."
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="email"
                        header="Email Id"
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            {rowData.email?.substring(0, 15)}
                            {rowData.email?.length > 15 ? "..." : ""}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="data.customerFileProcessJobDTOList[0].status"
                        header="Action"
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            {
                              <div className="rowDiv">
                                <div style={{ marginRight: 10 }}>
                                  <Link
                                    to={"/customer-file/list"}
                                    state={rowData.id}
                                    className="move-btn-small move-btn-vu center-text"
                                  >
                                    <i className="bi bi-box-arrow-in-right"></i>
                                  </Link>
                                </div>
                                <div style={{ marginRight: 10 }}>
                                  <Link
                                    to={"/client/edit"}
                                    state={rowData.id}
                                    className="move-btn-small move-btn-re center-text"
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </Link>
                                </div>
                                <Link
                                  to={"/client/view"}
                                  state={rowData.id}
                                  className="move-btn-small move-btn-vu center-text"
                                >
                                  <i class="bi bi-file-earmark-bar-graph"></i>
                                </Link>
                              </div>
                            }
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
