import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getUser } from "../../services/userMasterUser";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { getEmployeeRevenue } from "../../services/EmployeeRevenue";
import { DataView } from "primereact/dataview";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatDate } from "../../constants/FormateDate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logoBig from "../../assets/logo1.png";
import "jspdf-autotable";

export default function EmployeeRevenue() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [activeIndices, setActiveIndices] = useState([]);

  let startDate = new Date();
  let initialEndDate = new Date();

  const [input, setInput] = useState({
    users: "",
    startDate: startDate,
    endDate: initialEndDate,
    status: "COMPLETED",
  });
  const [errors, setErrors] = useState({
    users: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  const [usersData, setUsersData] = useState([]);
  const [employeeRevenueData, setEmployeeRevenueData] = useState([]);

  const fetchUserData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const removeAccountantUser = response.data.filter(
            (item) => item?.role !== "ROLE_ACCOUNTANT"
          );
          const filteredData = removeAccountantUser.map((users) => ({
            label: users.firstName + " " + users.lastName,
            value: users.id,
          }));
          setUsersData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  useEffect(() => {
    fetchUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (usersData.length > 0) fetchData();
  }, [usersData]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.jobs === "") {
      newErrors.jobs = "Please select any Job";
      isValid = false;
    } else {
      newErrors.jobs = "";
    }
    if (input.users === "") {
      newErrors.users = "Please select any user";
      isValid = false;
    } else {
      newErrors.users = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (startDate, endDate) => {
    if (validateForm()) {
      let data = {
        users: input.users,
        startDate: startDate,
        endDate: endDate,
        status: [input.status],
        dateFilterType: "completedOn",
      };
      getEmployeeRevenue(data)
        .then((response) => {
          if (!response.data.error) {
            if (response.data.success === false) {
              errorMessage(response.data.message);
            } else {
              successMessage(response.data.message);
              setEmployeeRevenueData(
                response.data.sort((a, b) =>
                  a.jobs[0].jobUser.user.firstName
                    .trim()
                    .localeCompare(b.jobs[0].jobUser.user.firstName.trim())
                )
              );
              setActiveIndices(response.data.map((_, index) => index));
            }
          } else {
            // errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  startDate.setDate(startDate.getDate() - 7);

  const fetchData = () => {
    let data = {
      users: usersData.map((item) => item.value),
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(initialEndDate).toISOString(),
      status: [input.status],
      dateFilterType: "completedOn",
    };

    getEmployeeRevenue(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            setEmployeeRevenueData(response.data);
            setActiveIndices(employeeRevenueData.map((_, index) => index));
          }
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const generatePDF = (type) => {
    setIsGeneratingPDF(true);
    const allIndices = employeeRevenueData.map((_, index) => index);
    setActiveIndices(allIndices); // Open all accordions

    setTimeout(() => {
      // const pdf = new jsPDF("p", "mm", "a4");
      const pdf = new jsPDF("landscape");

      employeeRevenueData.forEach((data, index) => {
        const logoImg = new Image();
        logoImg.src = logoBig;

        pdf.addImage(logoImg, "PNG", 10, 5, 50, 15);
        pdf.setFontSize(24);
        pdf.text("Employee Revenue List", 200, 15);

        if (index > 0) {
          pdf.addPage();
        }
        pdf.setFontSize(12);
        pdf.text(
          data.jobs[0].jobUser.user.firstName +
            " " +
            data.jobs[0].jobUser.user.lastName,
          14,
          30
        );
        pdf.text("Revenue " + data.totalWorkRevenue.toFixed(0), 14, 40);

        pdf.text("Cost " + data.totalWorkCost.toFixed(0), 90, 40);

        pdf.text(
          "Profit " +
            `${(data.totalWorkRevenue - data.totalWorkCost).toFixed(0)}`,
          150,
          40
        );

        const tableData = data.jobs.map((job) => [
          formatDate(job.customerFileProcessJob.startOn) +
            " - " +
            formatDate(job.customerFileProcessJob.endOn),
          formatDate(job.customerFileProcessJob.completedOn),
          job.customerFileProcessJob.customerFileJob.mvnJob.name,
          job.customerFileProcessJob.customerFileJob.customerFile.customer.name,
          job.workRevenue.toFixed(0),
          job.workCost.toFixed(0),
          (job.workRevenue - job.workCost).toFixed(0),
          job.customerFileProcessJob.invoiceNo,
        ]);

        pdf.autoTable({
          startY: 45,
          head: [
            [
              "Task Period",
              "Completed On",
              "Task",
              "Customer Name",
              "Revenue",
              "Cost",
              "Profit",
              "Invoice",
            ],
          ],
          body: tableData,
          theme: "grid",
          styles: {
            overflow: "linebreak",
            fontSize: 10,
          },
        });
      });

      if (type === "print") {
        // Create blob URL
        const blob = pdf.output("blob");
        const blobURL = URL.createObjectURL(blob);

        // Open blob URL in a new window
        const printWindow = window.open(blobURL);
        if (printWindow) {
          printWindow.onload = () => {
            printWindow.print();
          };
        }
      } else {
        pdf.save("employee-revenue.pdf");
      }
      setIsGeneratingPDF(false);
      setActiveIndices([]); // Reset accordions to their previous state
    }, 1000);
  };

  const renderListItem = (data, indexing) => {
    let tableData = data.jobs.sort((a, b) =>
      a.customerFileProcessJob.customerFileJob.customerFile.customer.name
        .trim()
        .localeCompare(b.customerFileProcessJob.customerFileJob.customerFile.customer.name.trim())
    );
    return (
      <div className="col-12 ">
        <Accordion
          className="accordion-custom"
          activeIndex={activeIndices}
          multiple
        >
          <AccordionTab
            header={
              <React.Fragment>
                <div className="product-list-item backgroundDesign">
                  <div className="product-list-detail">
                    <div className="product-category">
                      <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                      {data.jobs[0].jobUser.user.firstName +
                        " " +
                        data.jobs[0].jobUser.user.lastName}
                    </div>
                  </div>
                  <div className="product-list-detail">
                    <div className="product-category">
                      <i className="bi bi-currency-rupee product-category-icon orangeColorIcon"></i>
                      Revenue {data.totalWorkRevenue.toFixed(0)}
                    </div>
                  </div>
                  <div className="product-list-detail">
                    <div className="product-category">
                      <i className="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                      Cost {data.totalWorkCost.toFixed(0)}
                    </div>
                  </div>
                  <div className="product-list-detail">
                    <div className="product-category">
                      <i className="bi bi-currency-rupee product-category-icon orangeColorIcon"></i>
                      Profit{" "}
                      {(data.totalWorkRevenue - data.totalWorkCost).toFixed(0)}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          >
            <DataTable
              value={tableData}
              paginator={!isGeneratingPDF}
              scrollable
              scrollHeight="flex"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              dataKey="id"
              className="p-datatable-customers"
              showGridlines
              resizableColumns
              columnResizeMode="fit"
            >
              <Column
                field="customerFileProcessJob.startOn"
                header="Task Period"
                body={(rowData) => (
                  <span>
                    {formatDate(rowData.customerFileProcessJob.startOn)} -{" "}
                    {formatDate(rowData.customerFileProcessJob.endOn)}
                  </span>
                )}
                className="product-category table-design"
              ></Column>
              <Column
                field="customerFileProcessJob.completedOn"
                header="Completed On"
                body={(rowData) => (
                  <span>
                    {formatDate(rowData.customerFileProcessJob.completedOn)}
                  </span>
                )}
                className="product-category table-design"
              ></Column>
              <Column
                field="customerFileProcessJob.customerFileJob.mvnJob.name"
                header="Task"
                className="product-category table-design"
              ></Column>
              <Column
                field="customerFileProcessJob.customerFileJob.customerFile.customer.name"
                header="Customer Name"
                className="product-category table-design"
              ></Column>
              {/*<Column
                field="customerFileProcessJob.customerFileJob.customerFile.fileName"
                header="File"
                body={(rowData) => (
                  <span>
                    {
                      rowData.customerFileProcessJob.customerFileJob
                        .customerFile.fileName
                    }{" "}
                    |{" "}
                    {
                      rowData.customerFileProcessJob.customerFileJob
                        .customerFile.fileNumber
                    }
                  </span>
                )}
                className="product-category table-design"
              ></Column>*/}
              <Column
                field="workRevenue"
                header="Revenue"
                className="product-category table-design"
              ></Column>
              <Column
                field="workCost"
                header="Cost"
                className="product-category table-design"
              ></Column>
              <Column
                field="workRevenue"
                header="Profit"
                body={(rowData) => (
                  <span>{rowData.workRevenue - rowData.workCost}</span>
                )}
                className="product-category table-design"
              ></Column>
              <Column
                field="customerFileProcessJob.invoiceNo"
                header="Invoice"
                body={(rowData) => (
                  <span>{rowData.customerFileProcessJob.invoiceNo}</span>
                )}
                className="product-category table-design"
              ></Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const renderGridItem = (data, index) => {
    let tableData = data.jobs.sort((a, b) =>
      a.customerFileProcessJob.customerFileJob.customerFile.customer.name
        .trim()
        .localeCompare(
          b.customerFileProcessJob.customerFileJob.customerFile.customer.name.trim()
        )
    );
    return (
      <div className="col-12">
        <Accordion
          className="accordion-custom"
          activeIndex={activeIndices}
          multiple
        >
          <AccordionTab
            header={
              <React.Fragment>
                <div className="product-grid-item backgroundDesign">
                  <div className="product-list-detail">
                    <div
                      className="product-category"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                        {data.jobs[0].jobUser.user.firstName +
                          " " +
                          data.jobs[0].jobUser.user.lastName}
                      </div>
                      <div>
                        <i className="bi bi-currency-rupee product-category-icon orangeColorIcon"></i>
                        Revenue {data.totalWorkRevenue.toFixed(0)}
                      </div>
                    </div>
                    <div
                      className="product-category"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <i className="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                        Cost {data.totalWorkCost.toFixed(0)}
                      </div>
                      <div>
                        <i className="bi bi-currency-rupee product-category-icon orangeColorIcon"></i>
                        Profit{" "}
                        {(data.totalWorkRevenue - data.totalWorkCost).toFixed(
                          0
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          >
            <div>
              <DataTable
                value={tableData}
                paginator={!isGeneratingPDF}
                paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                dataKey="id"
                resizableColumns
                columnResizeMode="fit"
              >
                <Column
                  field="customerFileProcessJob.startOn"
                  header="Task Period"
                  resizeable={false}
                  body={(rowData) => (
                    <span>
                      {formatDate(rowData.customerFileProcessJob.startOn)} -{" "}
                      {formatDate(rowData.customerFileProcessJob.endOn)}
                    </span>
                  )}
                  className="product-category table-design"
                ></Column>
                <Column
                  field="customerFileProcessJob.customerFileJob.mvnJob.name"
                  header="Task"
                  className="product-category table-design"
                ></Column>
                <Column
                  field="customerFileProcessJob.customerFileJob.customerFile.customer.name"
                  header="Client Name"
                  className="product-category table-design"
                ></Column>
                {/*<Column
                  field="customerFileProcessJob.customerFileJob.customerFile.fileName"
                  header="File"
                  body={(rowData) => (
                    <span>
                      {
                        rowData.customerFileProcessJob.customerFileJob
                          .customerFile.fileName
                      }{" "}
                      |{" "}
                      {
                        rowData.customerFileProcessJob.customerFileJob
                          .customerFile.fileNumber
                      }
                    </span>
                  )}
                  className="product-category table-design"
                ></Column>*/}
                <Column
                  field="workRevenue"
                  header="Revenue"
                  className="product-category table-design"
                ></Column>
                <Column
                  field="workCost"
                  header="Cost"
                  className="product-category table-design"
                ></Column>
                <Column
                  field="workRevenue"
                  header="Profit"
                  body={(rowData) => (
                    <span>{rowData.workRevenue - rowData.workCost}</span>
                  )}
                  className="product-category table-design"
                ></Column>
                <Column
                  field="customerFileProcessJob.invoiceNo"
                  header="Invoice"
                  className="product-category table-design"
                ></Column>
              </DataTable>
            </div>
            {/*data.jobs.map((job, index) => (
              <div className="product-grid-item" key={index}>
                <div className="product-list-detail">
                  <div>
                    <i className="pi pi-calendar product-category-icon blueColorIcon"></i>{" "}
                    Start Date: {formatDate(job.customerFileProcessJob.startOn)}
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>{" "}
                    End Date: {formatDate(job.customerFileProcessJob.endOn)}
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="pi pi-briefcase product-category-icon blueColorIcon"></i>{" "}
                    Task:
                    {job.customerFileProcessJob.customerFileJob.mvnJob.name}
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="pi pi-user product-category-icon orangeColorIcon"></i>{" "}
                    Client Name:{" "}
                    {
                      job.customerFileProcessJob.customerFileJob.customerFile
                        .customer.name
                    }
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="pi pi-file product-category-icon blueColorIcon"></i>{" "}
                    File Name:{" "}
                    {
                      job.customerFileProcessJob.customerFileJob.customerFile
                        .fileName
                    }
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="pi pi-file product-category-icon orangeColorIcon"></i>{" "}
                    File No.:{" "}
                    {
                      job.customerFileProcessJob.customerFileJob.customerFile
                        .fileNumber
                    }
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                    Revenue: ₹ {job.workRevenue.toFixed(0)}
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="bi bi-currency-rupee product-category-icon orangeColorIcon"></i>
                    Cost: ₹ {job.workCost.toFixed(0)}
                  </div>
                </div>
                <div className="product-list-detail">
                  <div>
                    <i className="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                    Profit: ₹ {(job.workRevenue - job.workCost).toFixed(0)}
                  </div>
                </div>
              </div>
                  ))*/}
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }

    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const handleMonthButtonClick = (month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, month, 0).getDate(); // Get the number of days in the specified month

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const startDate = `${currentYear}-${formattedMonth}-01T00:00:00.000Z`;
    const endDate = `${currentYear}-${formattedMonth}-${daysInMonth}T23:59:59.999Z`;

    handleSubmit(startDate, endDate);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Employee Revenue List</h5>
                  {employeeRevenueData.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 20,
                          marginTop: 10,
                        }}
                      >
                        <i
                          onClick={() => generatePDF("download")}
                          className="pi pi-download"
                          style={{
                            fontSize: "2rem",
                            color: "#1e439b",
                            cursor: "pointer",
                          }}
                        ></i>
                        <i
                          onClick={() => generatePDF("print")}
                          className="pi pi-print"
                          style={{
                            fontSize: "2rem",
                            color: "#ff0101",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
                <form>
                  {/* <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="startDate" className="form-label">
                        Start Date
                      </label>
                      <Calendar
                        id="startDate"
                        name="startDate"
                        value={input.startDate}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            startDate: e.target.value,
                          });
                        }}
                        showIcon
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="endDate" className="form-label">
                        End Date
                      </label>
                      <Calendar
                        id="endDate"
                        name="endDate"
                        value={input.endDate}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            endDate: e.target.value,
                          });
                        }}
                        showIcon
                        required
                      />
                    </div>
                      </div>*/}

                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="jobUser" className="form-label">
                        Job User
                      </label>
                      <MultiSelect
                        display="chip"
                        value={input.users}
                        style={{ width: "100%" }}
                        options={usersData}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            users: e.target.value,
                          });
                        }}
                      />
                      <div className="text-danger mt-1">{errors.users}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <InputText
                        type="text"
                        id="status"
                        name="status"
                        style={{ width: "100%", textTransform: "capitalize" }}
                        value="COMPLETED"
                        onChange={(e) => {
                          setInput({
                            ...input,
                            status: e.target.value,
                          });
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    {/* Buttons for each month */}
                    {[...Array(12).keys()].map((month) => (
                      <div style={{ flex: 1, marginBottom: "5px" }} key={month}>
                        <button
                          type="button"
                          className="move-btn move-btn-month"
                          onClick={() => handleMonthButtonClick(month + 1)}
                        >
                          {new Date(2000, month).toLocaleString("default", {
                            month: "short",
                          })}
                        </button>
                      </div>
                    ))}
                  </div>
                </form>
                <div className="dataview-demo">
                  <div className="card" id="pdfContent">
                    <DataView
                      value={employeeRevenueData}
                      itemTemplate={itemTemplate}
                      paginator={!isGeneratingPDF}
                      rows={9}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
