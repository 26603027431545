import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getEmployeeReportRole = (role) => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/employee-report/" + role,
    // https://mvn.financeseva.com/revenue-management/api/secure/v1/employee-report/employee
    headers: getHeaders(),
  });
};

export const addEmployeeReport = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/employee-report",
    // https://mvn.financeseva.com/revenue-management/api/secure/v1/employee-report
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateEmployeeReport = (data) => {
  return axios({
    method: "put",
    url: BASE_URL + "api/secure/v1/employee-report",
    // https://mvn.financeseva.com/revenue-management/api/secure/v1/employee-report
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getEmployeeReportList = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/employee-report-list",
    // https://mvn.financeseva.com/revenue-management/api/secure/v1/employee-report-list
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};