import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getJobProcess } from "../../services/process";
import "../../assets/css/AccordionDemo.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import logoBig from "../../assets/logo1.png";
import { getUser } from "../../services/userMasterUser";
import { errorMessage } from "../../utils/alertMessage";
import { getEmployeeReportList } from "../../services/dailyReporting";
import { formatDate } from "../../constants/FormateDate";
import { replaceUnderscoresWithSpaces } from "../../components/Regex";
import { Link } from "react-router-dom";
import { Calendar } from "primereact/calendar";

function CommitmentAndAchievement() {
  const [products, setProducts] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const fetchUserData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          const filteredData = response.data.map((users) => ({
            label: users.firstName + " " + users.lastName,
            value: users.id,
          }));
          let data = {
            employeeId: filteredData.value,
            // startDate: "",
            // endDate: "",
          };
          getEmployeeReportList(data)
            .then((response) => {
              if (!response.data.error) {
                const Res = response.data;
                setProducts(
                  Res.sort((a, b) =>
                    b.createdDate
                      .trim()
                      .localeCompare(a.createdDate.trim())
                  )
                  
                  // Res.sort((a, b) =>
                  //   a.user.firstName
                  //     .trim()
                  //     .localeCompare(b.user.firstName.trim())
                  // )
                );
              } else {
                // errorMessage(response.data.errors.errors[0].message);
              }
            })
            .catch((err) => {
              errorMessage();
            });
          //   setUsersData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((item) => {
      const name = item.user.firstName.toLowerCase();
      const report = item.reportType.toLowerCase();

      const matchesName = name.includes(searchQueryValue.toLowerCase());
      const matchesReport = report.includes(searchQueryValue.toLowerCase());

      return matchesName || matchesReport;
    });
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  const downloadPDF = (data, type) => {
    const doc = new jsPDF("landscape");

    const logoImg = new Image();
    logoImg.src = logoBig;

    doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
    doc.setFontSize(24);
    doc.text("Commitment And Achievement", 100, 15);

    doc.autoTable({
      head: [
        [
          "Report Type",
          "Date",
          "Employee Name",
          "Today Commitment",
          "Achievement",
        ],
      ],
      body: data.map((item) => [
        item.reportType,
        formatDate(item.createdDate),
        `${item.user.firstName + " " + item.user.lastName}`,
        item.data.todayCommitment,
        // templates[item.reportType](item.data),
        generateAchievementText(item.reportType, item.data),
      ]),
      startY: 25,
      theme: "grid",
      styles: {
        overflow: "linebreak",
        fontSize: 12,
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 35 },
        2: { cellWidth: 40 },
        3: { cellWidth: 60 },
        4: { cellWidth: 80 },
      },
    });

    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      doc.save("Commitment-And-Achievement.pdf");
    }
  };

  const generateAchievementText = (reportType, data) => {
    switch (reportType) {
      case "Course_Department":
        return `No. Of New Leads: ${data.no_of_new_lead}\nNo. Of Calls: ${data.no_of_call}\nNo. Of Sales: ${data.no_of_sale}`;
      case "Business_Development":
        return `No. Of Old Broker Meet: ${data.no_of_old_broker_meet}\nNo. Of New Broker Meet: ${data.no_of_new_broker_meet}\nNo. Of Proposal Recd Loan Project Report Other: ${data.no_of_proposal_recd_loan_project_report_other}\nLoan Amount Lacs: ${data.loan_amount_lacs}\nProject Report Other Fees: ${data.project_report_other_fees}\nNew Booking Amount: ${data.new_booking_amount}\nOld Balance Recovered: ${data.old_balance_recovered}\nTotal Record: ${data.total_record}`;
      case "Digital_Marketing":
        return `Long Video: ${data.long_video}\nShorts: ${data.shorts}\nBlog: ${data.blog}`;
      case "Loan_Back_Office":
        return `New File Login: ${data.new_file_login}\nAmount (Lacs): ${data.new_file_login_amount_lacs}\nFile Sanction: ${data.file_sanction}\nAmount (Lacs): ${data.file_sanction_amount_lacs}\nFile Disburse: ${data.file_disburse}\nAmount (Lacs): ${data.file_disburse_amount_lacs}`;
      case "Project_Report_Sales":
        return `No. Of New Lead: ${data.no_of_new_lead}\nNo. Of New Project Report Software Booked: ${data.no_of_new_project_report_software_booked}\nNew Booking Amount: ${data.new_booking_amount}\nOld Balance Recovered: ${data.old_balance_recovered}\nTotal Collection: ${data.total_collection}`;
      case "Tele_calling_Team_Loan":
        return `No. Of New Call: ${data.no_of_new_call}\nNo. Of Follow Up: ${data.no_of_follow_up}\nNo. Of File Login: ${data.no_of_file_login}\nNo. Of File Sanction: ${data.no_of_file_sanction}\nNo. Of File Disburse: ${data.no_of_file_disburse}`;
      case "Project_Report_Back_Office":
        return `No. Of Project Report Prepared: ${data.no_of_project_report_prepared}`;
      case "Account_Department":
        return `No. Of Bill Raised: ${data.no_of_bill_raised}\nAmount: ${data.amount}`;
      // Add more cases as needed
      default:
        return "";
    }
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const filtered = products?.filter((item) => {
      const createdDate = new Date(item.createdDate);
      return (
        (!startDate || createdDate >= new Date(startDate)) &&
        (!endDate || createdDate <= new Date(endDate))
      );
    });
    setFilteredProducts(filtered);
  }, [startDate, products]);

  const templates = {
    Course_Department: (data) => {
      return (
        <React.Fragment>
          No. Of New Leads: {data.no_of_new_lead}
          <br />
          No. Of Calls: {data.no_of_call}
          <br />
          No. Of Sales: {data.no_of_sale}
        </React.Fragment>
      );
    },
    Business_Development: (data) => {
      return (
        <React.Fragment>
          No. Of Old Broker Meet: {data.no_of_old_broker_meet}
          <br />
          No. Of New Broker Meet: {data.no_of_new_broker_meet}
          <br />
          No. Of Proposal Recd Loan Project Report Other:{" "}
          {data.no_of_proposal_recd_loan_project_report_other}
          <br />
          Loan Amount Lacs: {data.loan_amount_lacs}
          <br />
          Project Report Other Fees: {data.project_report_other_fees}
          <br />
          New Booking Amount: {data.new_booking_amount}
          <br />
          Old Balance Recovered: {data.old_balance_recovered}
          <br />
          Total Record: {data.total_record}
        </React.Fragment>
      );
    },
    Digital_Marketing: (data) => {
      return (
        <React.Fragment>
          Long Video: {data.long_video}
          <br />
          Shorts: {data.shorts}
          <br />
          Blog: {data.blog}
        </React.Fragment>
      );
    },
    Loan_Back_Office: (data) => {
      return (
        <React.Fragment>
          New File Login: {data.new_file_login}
          <br />
          Amount (Lacs): {data.new_file_login_amount_lacs}
          <br />
          File Sanction: {data.file_sanction}
          <br />
          Amount (Lacs): {data.file_sanction_amount_lacs}
          <br />
          File Disburse: {data.file_disburse}
          <br />
          Amount (Lacs): {data.file_disburse_amount_lacs}
        </React.Fragment>
      );
    },
    Project_Report_Sales: (data) => {
      return (
        <React.Fragment>
          No. Of New Lead: {data.no_of_new_lead}
          <br />
          No. Of New Project Report Software Booked:{" "}
          {data.no_of_new_project_report_software_booked}
          <br />
          New Booking Amount: {data.new_booking_amount}
          <br />
          Old Balance Recovered: {data.old_balance_recovered}
          <br />
          Total Collection: {data.total_collection}
        </React.Fragment>
      );
    },
    Tele_calling_Team_Loan: (data) => {
      return (
        <React.Fragment>
          No. Of New Call: {data.no_of_new_call}
          <br />
          No. Of Follow Up: {data.no_of_follow_up}
          <br />
          No. Of File Login: {data.no_of_file_login}
          <br />
          No. Of File Sanction: {data.no_of_file_sanction}
          <br />
          No. Of File Disburse: {data.no_of_file_disburse}
        </React.Fragment>
      );
    },
    Project_Report_Back_Office: (data) => {
      return (
        <React.Fragment>
          No. Of Project Report Prepared: {data.no_of_project_report_prepared}
        </React.Fragment>
      );
    },
    Account_Department: (data) => {
      return (
        <React.Fragment>
          No. Of Bill Raised: {data.no_of_bill_raised}
          <br />
          Amount: {data.amount}
        </React.Fragment>
      );
    },
    // Add more templates as needed
  };

  return (
    <main id="main" className="main">
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              {/* Sales Card */}
              <div className="col-md-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 className="card-title">Commitment And Achievement</h5>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 20,
                        }}
                      >
                        <i
                          onClick={() =>
                            downloadPDF(
                              filteredProducts || products,
                              "download"
                            )
                          }
                          className="pi pi-download"
                          style={{
                            fontSize: "2rem",
                            color: "#1e439b",
                            cursor: "pointer",
                          }}
                        ></i>
                        <i
                          onClick={() =>
                            downloadPDF(filteredProducts || products, "print")
                          }
                          className="pi pi-print"
                          style={{
                            fontSize: "2rem",
                            color: "#ff0101",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div
                      className="col-12"
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <InputText
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />

                      <Calendar
                        value={startDate}
                        onChange={(e) => setStartDate(e.value)}
                        showIcon
                      />
                    </div>

                    <div id="pdfContent">
                      <DataTable
                        value={filteredProducts || products}
                        // value={commitmentAndAchievementData}
                        paginator={!isGeneratingPDF}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        dataKey="id"
                        className="p-datatable-customers client-table-design"
                        resizableColumns
                        columnResizeMode="fit"
                      >
                        <Column
                          field="reportType"
                          header="Report Type"
                          resizeable={false}
                          className="product-category table-design"
                          body={(rowData) => (
                            <span>
                              {replaceUnderscoresWithSpaces(rowData.reportType)}
                            </span>
                          )}
                        ></Column>
                        <Column
                          header="Date"
                          field="date"
                          className="product-category table-design"
                          dataType="date"
                          body={(rowData) => (
                            <span>{formatDate(rowData.createdDate)}</span>
                          )}
                        />
                        <Column
                          field="Employee Name"
                          header="Employee Name"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span>
                              {rowData.user.firstName +
                                " " +
                                rowData.user.lastName}
                            </span>
                          )}
                        ></Column>
                        <Column
                          field="name"
                          header="Today Commitment"
                          resizeable={false}
                          className="product-category table-design"
                          body={(rowData) => (
                            <span className="word-wrap">
                              {rowData.data.todayCommitment}

                              <Link
                                className="btn"
                                to={"/commitment-edit"}
                                // state={item}
                                state={{
                                  userId: rowData.user.id,
                                  edit: rowData,
                                }}
                              >
                                <i
                                  className="bi bi-pencil"
                                  style={{ fontSize: 15, color: "blue" }}
                                ></i>
                              </Link>
                            </span>
                          )}
                        ></Column>
                        <Column
                          field="Achievement"
                          header="Achievement"
                          className="product-category table-design"
                          body={(rowData) =>
                            templates[rowData.reportType](rowData.data)
                          }
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default CommitmentAndAchievement;
