import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../../utils/alertMessage";
import { addUser, updateUser } from "../../../services/userMasterUser";
import {
  validEmail,
  validateFullName,
  validatePhone,
} from "../../../components/Regex";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { getServicesType } from "../../../services/ServicesType";

export default function UserCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [input, setInput] = useState({
    firstName: state?.firstName || "",
    lastName: state?.lastName || "",
    email: state?.email || "",
    mobile: state?.mobile || "",
    workingDays: state?.workingDays || "",
    cost: state?.cost || "",
    password: state?.password || "",
    role: state?.role || "ROLE EMPLOYEE",
    serviceList: state?.serviceList || "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    workingDays: "",
    cost: "",
    password: "",
    role: "",
    serviceList: "",
  });

  const [serviceData, setServiceData] = useState();

  const fetchServiceAndFrequency = () => {
    getServicesType()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const services = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setServiceData(services);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchServiceAndFrequency();
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Example: Validate email format
    // const emailRegex = /^\S+@\S+\.\S+$/;
    if (!validEmail.test(input.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    } else {
      newErrors.email = "";
    }
    if (!validateFullName.test(input.firstName)) {
      newErrors.firstName = "Please Correct First Name";
      isValid = false;
    } else {
      newErrors.firstName = "";
    }
    if (!validateFullName.test(input.lastName)) {
      newErrors.lastName = "Please Correct Last Name";
      isValid = false;
    } else {
      newErrors.lastName = "";
    }
    if (!validatePhone.test(input.mobile)) {
      newErrors.mobile = "Please Enter 10 Digit Mobile Number";
      isValid = false;
    } else {
      newErrors.mobile = "";
    }
    if (
      input.workingDays === "" ||
      isNaN(input.workingDays) ||
      parseInt(input.workingDays) <= 0
    ) {
      newErrors.workingDays = "Please Enter Correct Working Days";
      isValid = false;
    } else {
      newErrors.workingDays = "";
    }

    if (input.cost === "" || isNaN(input.cost) || parseFloat(input.cost) <= 0) {
      newErrors.cost = "Please Correct the Cost";
      isValid = false;
    } else {
      newErrors.cost = "";
    }
    if (input.serviceList === "") {
      newErrors.serviceList = "Please Choose Service";
      isValid = false;
    } else {
      newErrors.serviceList = "";
    }

    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (state) {
        let data = {
          id: state.id,
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
          mobile: input.mobile,
          workingDays: input.workingDays,
          cost: input.cost,
          password: input.password,
          role: input.role,
          serviceList: input.serviceList,
        };
        updateUser(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                successMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/user-master/user/list");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      } else {
        let data = {
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
          mobile: input.mobile,
          workingDays: input.workingDays,
          cost: input.cost,
          password: input.password,
          role: input.role,
          serviceList: input.serviceList,
        };
        addUser(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                successMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/user-master/user/list");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  User {state ? "Update" : "Create"}
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Full Name
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="firstName"
                        name="firstName"
                        value={input.firstName}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            firstName: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.firstName}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Last Name
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="lastName"
                        name="lastName"
                        value={input.lastName}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            lastName: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.lastName}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Email Id
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="email"
                        name="email"
                        value={input.email}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            email: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.email}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Mobile Number
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="mobile"
                        name="mobile"
                        value={input.mobile}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            mobile: e.target.value,
                          });
                        }}
                        required
                        max={10}
                      />
                      <div className="text-danger">{errors.mobile}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="workingDays" className="form-label">
                        No. of Working Days
                      </label>
                      <InputNumber
                        inputId="horizontal"
                        value={input.workingDays}
                        id="workingDays"
                        name="workingDays"
                        style={{ width: "100%" }}
                        onValueChange={(e) =>
                          setInput({
                            ...input,
                            workingDays: e.target.value,
                          })
                        }
                        showButtons
                        buttonLayout="horizontal"
                        step={1}
                        decrementButtonClassName="p-button-danger"
                        incrementButtonClassName="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        mode="decimal"
                        minFractionDigits={0}
                        min={0}
                        required
                      />
                      <div className="text-danger">{errors.workingDays}</div>
                    </div>
                    <div className=" field col-md-6">
                      <label htmlFor="cost" className="form-label">
                        Cost
                      </label>
                      <InputNumber
                        inputId="horizontal"
                        value={input.cost}
                        id="cost"
                        name="cost"
                        style={{ width: "100%" }}
                        onValueChange={(e) =>
                          setInput({
                            ...input,
                            cost: e.target.value,
                          })
                        }
                        showButtons
                        buttonLayout="horizontal"
                        step={1}
                        decrementButtonClassName="p-button-danger"
                        incrementButtonClassName="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        mode="currency"
                        currency="INR"
                        minFractionDigits={0}
                        min={0}
                        required
                      />
                      {/*<InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="cost"
                        name="cost"
                        value={input.cost}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            cost: e.target.value,
                          });
                        }}
                        required
                      />*/}
                      <div className="text-danger">{errors.cost}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Password
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="password"
                        name="password"
                        value={input.password}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            password: e.target.value,
                          });
                        }}
                      />
                    </div>
                    {!state && (
                      <div className="col-md-6">
                        <label htmlFor="score" className="form-label">
                          Role
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="role"
                          name="role"
                          value="ROLE EMPLOYEE"
                          onChange={(e) => {
                            setInput({
                              ...input,
                              role: e.target.value,
                            });
                          }}
                          readOnly
                        />
                        {/* <Dropdown
                        value={input.role}
                        options={roleData}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            role: e.target.value,
                          });
                        }}
                        placeholder="Select a role"
                      />*/}
                      </div>
                    )}
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="serviceList" className="form-label">
                        Service List
                      </label>
                      <MultiSelect
                        display="chip"
                        style={{ width: "100%" }}
                        options={serviceData}
                        value={input.serviceList}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            serviceList: e.target.value,
                          });
                        }}
                      />
                      <div className="text-danger">{errors.serviceList}</div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
