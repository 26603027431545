import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getClient = () => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/customer",
    headers: getHeaders(),
  });
};

export const getClientById = (id) => {
  return axios({
    method: "get",
    // url: BASE_URL + "api/secure/v1/customer/" + id,
    url: BASE_URL + "api/secure/v2/customer/" + id,
    headers: getHeaders(),
  });
};

export const addClient = (data) => {
  return axios({
    method: "post",
    // url: BASE_URL + "api/secure/v1/customer",
    url: BASE_URL + "api/secure/v2/customer",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateClient = (data) => {
  return axios({
    method: "put",
    // url: BASE_URL + "api/secure/v1/customer",
    url: BASE_URL + "api/secure/v2/customer",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};


export const fileStatus = (data, customerId) => {
  return axios({
    method: "post",
    // url: BASE_URL + "api/secure/v1/customer",
    url: BASE_URL + "api/secure/v1/customer/" + customerId + "/file-status",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};