import React, { useEffect, useState } from "react";
import { getUserProfile } from "../services/userProfile";
import { formatDate } from "../constants/FormateDate";

const ProfileView = () => {

  const [profile, setProfile] = useState({});

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setProfile(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, []);

  return (
    <main id="main" className="main">
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              {/* Customers Card */}
              <div className="col-xxl-4 col-xl-12">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    <h5 className="card-title">Profile</h5>
                    <div className="d-flex align-items-center mb-3">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people" />
                      </div>
                      <div className="ps-3">
                        <h6>
                          {profile.firstName} {profile.lastName}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <span className="user-profile-data">
                          <i
                            className="pi pi-mobile product-category-icon blueColorIcon"
                            style={{ fontSize: "16px" }}
                          ></i>{" "}
                          {profile.mobile}
                        </span>
                      </div>
                      <div className="col-md-6 mb-3">
                        <span className="user-profile-data">
                          <i
                            className="pi pi-envelope product-category-icon orangeColorIcon"
                            style={{ fontSize: "16px" }}
                          ></i>{" "}
                          {profile.email}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <span className="user-profile-data">
                          <i
                            className="pi pi-user product-category-icon blueColorIcon"
                            style={{ fontSize: "16px" }}
                          ></i>{" "}
                          {profile.userLoginId}
                        </span>
                      </div>
                      <div className="col-md-6 mb-3">
                        <span className="user-profile-data">
                          <i
                            className="pi pi-id-card product-category-icon orangeColorIcon"
                            style={{ fontSize: "16px" }}
                          ></i>{" "}
                          {profile?.role}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <span className="user-profile-data">
                          <i
                            className="pi pi-calendar product-category-icon blueColorIcon"
                            style={{ fontSize: "16px" }}
                          ></i>{" "}
                          {formatDate(profile.createdDate)}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <span className="user-profile-data">
                          <i
                            className="pi pi-building product-category-icon orangeColorIcon"
                            style={{ fontSize: "16px" }}
                          ></i>{" "}
                          {profile.address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Customers Card */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProfileView;
