// mvn.financeseva.com/revenue-management/api/secure/v1/customer-job-allocation
import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getResourceAllocation = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/customer-job-allocation",
    data: JSON.stringify(data),
    headers: getHeaders(),
  });
};
