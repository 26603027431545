import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const uploadDocument = (processId, processJobId, fileData) => {
  const formData = new FormData();
  formData.append("fileData", fileData); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url:
      BASE_URL +
      "api/secure/v1/customer-job-process/" +
      processId +
      "/" +
      processJobId +
      "/document",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

// export const getDocument = (processId, processJobId) => {
//   return axios({
//     method: "get",
//     url:
//       BASE_URL +
//       "api/secure/v1/customer-job-process/" +
//       processId +
//       "/" +
//       processJobId +
//       "/document",
//   });
// };
export const getDocument = (processId, processJobId) => {
  return axios({
    method: "get",
    url:
      BASE_URL +
      "api/secure/v3/customer-job-process/" +
      processId +
      "/" +
      processJobId +
      "/document",
    headers: getHeaders("multipart/form-data"),
  });
};

export const getDocumentDownload = (processId, processJobId, fileName) => {
  return axios({
    method: "get",
    url:
      BASE_URL +
      "api/secure/v1/customer-job-process/" +
      processId +
      "/" +
      processJobId +
      "/document/" +
      fileName,
    // headers: getHeaders("multipart/form-data"),
  });
};
