import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getUser } from "../../services/userMasterUser";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { getEmployeeRevenue } from "../../services/EmployeeRevenue";
import { DataView } from "primereact/dataview";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatDate } from "../../constants/FormateDate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { getEmployeeReportList } from "../../services/dailyReporting";
import { Dropdown } from "primereact/dropdown";

export default function AdminDailyReports() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let startDate = new Date();
  let initialEndDate = new Date();

  const [input, setInput] = useState({
    users: "",
    startDate: startDate,
    endDate: initialEndDate,
    status: "COMPLETED",
  });
  const [errors, setErrors] = useState({
    users: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  const [usersData, setUsersData] = useState([]);
  const [employeeRevenueData, setEmployeeRevenueData] = useState([]);

  const fetchUserData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          // const removeAccountantUser = response.data.filter(
          //   (item) => item.role !== "ROLE_ACCOUNTANT"
          // );
          const filteredData = response.data.map((users) => ({
            label: users.firstName + " " + users.lastName,
            value: users.id,
          }));
          setUsersData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  useEffect(() => {
    fetchUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   if (usersData.length > 0) fetchData();
  // }, [usersData]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.jobs === "") {
      newErrors.jobs = "Please select any Job";
      isValid = false;
    } else {
      newErrors.jobs = "";
    }
    if (input.users === "") {
      newErrors.users = "Please select any user";
      isValid = false;
    } else {
      newErrors.users = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (startDate, endDate) => {
    if (validateForm()) {
      let data = {
        employeeId: input.users,
        startDate: startDate,
        endDate: endDate,
      };
      getEmployeeReportList(data)
        .then((response) => {
          if (!response.data.error) {
            const Res = response.data;

            // Filter Course_Department reports
            const courseDepartmentReports = Res.filter(
              (report) => report.reportType === "Course_Department"
            );
            const businessDevelopmentReports = Res.filter(
              (report) => report.reportType === "Business_Development"
            );
            const digitalMarketingReports = Res.filter(
              (report) => report.reportType === "Digital_Marketing"
            );
            const loanBackOfficeReports = Res.filter(
              (report) => report.reportType === "Loan_Back_Office"
            );
            const projectReportSalesReports = Res.filter(
              (report) => report.reportType === "Project_Report_Sales"
            );

            const teleCallingTeamLoanReports = Res.filter(
              (report) => report.reportType === "Tele_calling_Team_Loan"
            );
            const projectReportBackOfficeReports = Res.filter(
              (report) => report.reportType === "Project_Report_Back_Office"
            );
            const accountantDepartmentReportsReports = Res.filter(
              (report) => report.reportType === "Account_Department"
            );

            // Set state
            setCourseDepartment(courseDepartmentReports);
            setBusinessDevelopment(businessDevelopmentReports);
            setDigitalMarketing(digitalMarketingReports);
            setLoanBackOffice(loanBackOfficeReports);
            setProjectReportSales(projectReportSalesReports);
            setTeleCallingTeamLoan(teleCallingTeamLoanReports);
            setProjectReportBackOffice(projectReportBackOfficeReports);
            setAccountantDepartment(accountantDepartmentReportsReports);
          } else {
            // errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  startDate.setDate(startDate.getDate() - 7);

  const handleMonthButtonClick = (month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, month, 0).getDate(); // Get the number of days in the specified month

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const startDate = `${currentYear}-${formattedMonth}-01T00:00:00.000Z`;
    const endDate = `${currentYear}-${formattedMonth}-${daysInMonth}T23:59:59.999Z`;

    handleSubmit(startDate, endDate);
  };

  const [courseDepartment, setCourseDepartment] = useState([]);
  const [businessDevelopment, setBusinessDevelopment] = useState([]);
  const [digitalMarketing, setDigitalMarketing] = useState([]);
  const [loanBackOffice, setLoanBackOffice] = useState([]);
  const [projectReportSales, setProjectReportSales] = useState([]);
  const [teleCallingTeamLoan, setTeleCallingTeamLoan] = useState([]);
  const [projectReportBackOffice, setProjectReportBackOffice] = useState([]);
  const [accountantDepartment, setAccountantDepartment] = useState([]);

   const constructMessage = (rowData, template) => {
     let message =
       `*${rowData.user.firstName} ${
         rowData.user.lastName
       } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
       `Created Date: *${formatDate(rowData.createdDate)}*\n`;

     if (rowData.data.todayCommitment) {
       message += `*Today's Commitment:* \n${rowData.data.todayCommitment} \n`;
     }

     // Check if rowData.data has any keys other than todayCommitment
     const dataKeys = Object.keys(rowData.data).filter(
       (key) =>
         key !== "todayCommitment" &&
         rowData.data[key] !== null &&
         rowData.data[key] !== undefined &&
         rowData.data[key] !== ""
     );
     if (dataKeys.length > 0) {
       message += `*Today's Achievement:* \n` + template(rowData.data);
     }

     return message;
   };

   const templates = {
     template1: (data) => {
       return (
         `No. Of New Leads: *${data.no_of_new_lead}*\n` +
         `No. Of Calls: *${data.no_of_call}*\n` +
         `No. Of Sales: *${data.no_of_sale}*`
       );
     },
     template2: (data) => {
       return (
         `No. Of Old Broker Meet: *${data.no_of_old_broker_meet}*\n` +
         `No. Of New Broker Meet: *${data.no_of_new_broker_meet}*\n` +
         `No. Of Proposal Recd Loan Project Report Other: *${data.no_of_proposal_recd_loan_project_report_other}*\n` +
         `Loan Amount Lacs: *${data.loan_amount_lacs}*\n` +
         `Project Report Other Fees: *${data.project_report_other_fees}*\n` +
         `New Booking Amount: *${data.new_booking_amount}*\n` +
         `Old Balance Recovered: *${data.old_balance_recovered}*\n` +
         `Total Record: *${data.total_record}*\n`
       );
     },
     template3: (data) => {
       return (
         `Long Video: *${data.long_video}*\n` +
         `Shorts: *${data.shorts}*\n` +
         `Blog: *${data.blog}*\n`
       );
     },
     template4: (data) => {
       return (
         `New File Login: *${data.new_file_login}*\n` +
         `Amount (Lacs): *${data.new_file_login_amount_lacs}*\n` +
         `File Sanction: *${data.file_sanction}*\n` +
         `Amount (Lacs): *${data.file_sanction_amount_lacs}*\n` +
         `File Disburse: *${data.file_disburse}*\n ` +
         `Amount (Lacs): *${data.file_disburse_amount_lacs}*\n`
       );
     },
     template5: (data) => {
       return (
         `No. Of New Lead: *${data.no_of_new_lead}*\n` +
         `No. Of New Project Report Software Booked: *${data.no_of_new_project_report_software_booked}*\n` +
         `New Booking Amount: *${data.new_booking_amount}*\n` +
         `Old Balance Recovered: *${data.old_balance_recovered}*\n` +
         `Total Collection: *${data.total_collection}*\n`
       );
     },
     template6: (data) => {
       return (
         `No. Of New Call: *${data.no_of_new_call}*\n` +
         `No. Of Follow Up: *${data.no_of_follow_up}*\n` +
         `No. Of File Login: *${data.no_of_file_login}*\n` +
         `No. Of File Sanction: *${data.no_of_file_sanction}*\n` +
         `No. Of File Disburse: *${data.no_of_file_disburse}*\n`
       );
     },
     template7: (data) => {
       return `No. Of Project Report Prepared: *${data.no_of_project_report_prepared}*\n`;
     },
     template8: (data) => {
       return (
         `No. Of Bill Raised: *${data.no_of_bill_raised}*\n` +
         `Amount: *${data.amount}*\n`
       );
     },
     // Add more templates as needed
   };

   const updateMessage = (rowData, template) => {
     const constructedMessage = constructMessage(rowData, template);
     sendMessage(constructedMessage);
   };

   const sendMessage = (message) => {
     const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
     window.open(url, "_blank");
   };

   const courseDepartmentTotal = (field) => {
     let total = 0;
     for (let item of courseDepartment) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };
   const businessDevelopmentTotal = (field) => {
     let total = 0;
     for (let item of businessDevelopment) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };

   const digitalMarketingTotal = (field) => {
     let total = 0;
     for (let item of digitalMarketing) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };

   const loanBackOfficeTotal = (field) => {
     let total = 0;
     for (let item of loanBackOffice) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };

   const projectReportSalesTotal = (field) => {
     let total = 0;
     for (let item of projectReportSales) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };

   const teleCallingTeamLoanTotal = (field) => {
     let total = 0;
     for (let item of teleCallingTeamLoan) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };

   const projectReportBackOfficeTotal = (field) => {
     let total = 0;
     for (let item of projectReportBackOffice) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };

   const accountantDepartmentTotal = (field) => {
     let total = 0;
     for (let item of accountantDepartment) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
     }
     return total;
   };

   const footerGroupCourseDepartment = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column footer={courseDepartmentTotal("no_of_new_lead")} />
         <Column footer={courseDepartmentTotal("no_of_call")} />
         <Column footer={courseDepartmentTotal("no_of_sale")} />
       </Row>
     </ColumnGroup>
   );

   const footerGroupBusinessDevelopment = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column footer={businessDevelopmentTotal("no_of_new_broker_meet")} />
         <Column footer={businessDevelopmentTotal("no_of_old_broker_meet")} />
         <Column
           footer={businessDevelopmentTotal(
             "no_of_proposal_recd_loan_project_report_other"
           )}
         />
         <Column footer={businessDevelopmentTotal("loan_amount_lacs")} />
         <Column
           footer={businessDevelopmentTotal("project_report_other_fees")}
         />
         <Column footer={businessDevelopmentTotal("new_booking_amount")} />
         <Column footer={businessDevelopmentTotal("old_balance_recovered")} />
         <Column footer={businessDevelopmentTotal("total_record")} />
       </Row>
     </ColumnGroup>
   );

   const footerGroupDigitalMarketing = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column footer={digitalMarketingTotal("long_video")} />
         <Column footer={digitalMarketingTotal("shorts")} />
       </Row>
     </ColumnGroup>
   );

   const footerGroupLoanBackOffice = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column footer={loanBackOfficeTotal("new_file_login")} />
         <Column footer={loanBackOfficeTotal("new_file_login_amount_lacs")} />
         <Column footer={loanBackOfficeTotal("file_sanction")} />
         <Column footer={loanBackOfficeTotal("file_sanction_amount_lacs")} />
         <Column footer={loanBackOfficeTotal("file_disburse")} />
         <Column footer={loanBackOfficeTotal("file_disburse_amount_lacs")} />
       </Row>
     </ColumnGroup>
   );

   const footerGroupProjectReportSales = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column footer={projectReportSalesTotal("no_of_new_lead")} />
         <Column
           footer={projectReportSalesTotal(
             "no_of_new_project_report_software_booked"
           )}
         />
         <Column footer={projectReportSalesTotal("new_booking_amount")} />
         <Column footer={projectReportSalesTotal("old_balance_recovered")} />
         <Column footer={projectReportSalesTotal("total_collection")} />
       </Row>
     </ColumnGroup>
   );

   const footerGroupTeleCallingTeamLoan = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column footer={teleCallingTeamLoanTotal("no_of_new_call")} />
         <Column footer={teleCallingTeamLoanTotal("no_of_follow_up")} />
         <Column footer={teleCallingTeamLoanTotal("no_of_file_login")} />
         <Column footer={teleCallingTeamLoanTotal("no_of_file_sanction")} />
         <Column footer={teleCallingTeamLoanTotal("no_of_file_disburse")} />
       </Row>
     </ColumnGroup>
   );

   const footerGroupProjectReportBackOffice = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column
           footer={projectReportBackOfficeTotal(
             "no_of_project_report_prepared"
           )}
         />
       </Row>
     </ColumnGroup>
   );

   const footerGroupAccountantDepartment = (
     <ColumnGroup>
       <Row>
         <Column footer="Totals:" colSpan={2} />
         <Column footer={accountantDepartmentTotal("no_of_bill_raised")} />
         <Column footer={accountantDepartmentTotal("amount")} />
       </Row>
     </ColumnGroup>
   );

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Employee Daily Report List</h5>
                </div>
                <form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="jobUser" className="form-label">
                        Employee
                      </label>
                      <Dropdown
                        value={input.users}
                        options={usersData}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            users: e.target.value,
                          });
                        }}
                        placeholder="Select a Employee"
                      />
                      <div className="text-danger mt-1">{errors.users}</div>
                    </div>
                  </div>
                  <div className="row mb-5">
                    {/* Buttons for each month */}
                    {[...Array(12).keys()].map((month) => (
                      <div style={{ flex: 1, marginBottom: "5px" }} key={month}>
                        <button
                          type="button"
                          className="move-btn move-btn-month"
                          onClick={() => handleMonthButtonClick(month + 1)}
                        >
                          {new Date(2000, month).toLocaleString("default", {
                            month: "short",
                          })}
                        </button>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            {courseDepartment.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Course Department Reports</h5>
                  <DataTable
                    value={courseDepartment}
                    paginator
                    footerColumnGroup={footerGroupCourseDepartment}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. of New Leads"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_new_lead}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. of Calls"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => <span>{rowData.data.no_of_call}</span>}
                    ></Column>
                    <Column
                      field="name"
                      header="No. of Sales"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => <span>{rowData.data.no_of_sale}</span>}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template1)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}

            {businessDevelopment.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Business Development Reports</h5>
                  <DataTable
                    value={businessDevelopment}
                    paginator
                    footerColumnGroup={footerGroupBusinessDevelopment}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of New Broker Meet"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_new_broker_meet}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of Old Broker Meet"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_old_broker_meet}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of Proposal Recd Loan Project Report Other"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          {
                            rowData.data
                              .no_of_proposal_recd_loan_project_report_other
                          }
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Loan Amount (Lacs)"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.loan_amount_lacs}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Project Report Other Fees"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.project_report_other_fees}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="New Booking Amount"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.new_booking_amount}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Old Balance Recovered"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.old_balance_recovered}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Total Record"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.total_record}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template2)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
            {digitalMarketing.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Digital Marketing Reports</h5>
                  <DataTable
                    value={digitalMarketing}
                    paginator
                    footerColumnGroup={footerGroupDigitalMarketing}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Long Video"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => <span>{rowData.data.long_video}</span>}
                    ></Column>
                    <Column
                      field="name"
                      header="Shorts"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => <span>{rowData.data.shorts}</span>}
                    ></Column>
                    <Column
                      field="name"
                      header="Blog"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => <span>{rowData.data.blog}</span>}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template3)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
            {loanBackOffice.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Loan Back Office Reports</h5>
                  <DataTable
                    value={loanBackOffice}
                    paginator
                    footerColumnGroup={footerGroupLoanBackOffice}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="New File Login"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.new_file_login}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Amount (Lacs)"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.new_file_login_amount_lacs}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="File Sanction"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_sanction}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Amount (Lacs)"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_sanction_amount_lacs}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="File Disburse"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_disburse}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Amount (Lacs)"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_disburse_amount_lacs}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template4)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
            {projectReportSales.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Project Report Sales Reports</h5>
                  <DataTable
                    value={projectReportSales}
                    paginator
                    footerColumnGroup={footerGroupProjectReportSales}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of New Lead"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_new_lead}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of New Project Report Software Booked"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          {
                            rowData.data
                              .no_of_new_project_report_software_booked
                          }
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="New Booking Amount"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.new_booking_amount}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Old Balance Recovered"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.old_balance_recovered}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Total Collection"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.total_collection}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template5)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
            {teleCallingTeamLoan.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Tele Calling Team Loan Reports</h5>
                  <DataTable
                    value={teleCallingTeamLoan}
                    paginator
                    footerColumnGroup={footerGroupTeleCallingTeamLoan}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of New Call"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_new_call}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of Follow Up"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_follow_up}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of File Login"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_file_login}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of File Sanction"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_file_sanction}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of File Disburse"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_file_disburse}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template6)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
            {projectReportBackOffice.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Project Report Back Office Reports
                  </h5>
                  <DataTable
                    value={projectReportBackOffice}
                    paginator
                    footerColumnGroup={footerGroupProjectReportBackOffice}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of Project Report Prepared"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          {rowData.data.no_of_project_report_prepared}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template7)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
            {accountantDepartment.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Accountant Department Reports</h5>
                  <DataTable
                    value={accountantDepartment}
                    paginator
                    footerColumnGroup={footerGroupAccountantDepartment}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="No. Of Bill Raised"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.no_of_bill_raised}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Amount"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => <span>{rowData.data.amount}</span>}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={() =>
                              updateMessage(rowData, templates.template8)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}
