import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getEmployeeRevenue = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v2/employee-revenue",
    data: JSON.stringify(data),
    headers: getHeaders(),
  });
};
