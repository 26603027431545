import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getJobProcess } from "../../services/process";
import "../../assets/css/AccordionDemo.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import logoBig from "../../assets/logo1.png";

export default function PendingTask() {
  const [products, setProducts] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [ongoingCountValue, setOngoingCountValue] = useState(0);
  const [toBeStartedCountValue, setToBeStartedCountValue] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const fetchCustomerJobProcess = (data) => {
    getJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          // setProducts(Res.reverse());
          setProducts(
            Res.sort((a, b) =>
              a.customerFileProcess.customerFile.customer.name
                .trim()
                .localeCompare(
                  b.customerFileProcess.customerFile.customer.name.trim()
                )
            )
          );
          let ongoingCount = 0;
          let toBeStartedCount = 0;

          Res.forEach((item) => {
            const status = item.customerFileProcessJobDTOList[0].status;
            if (status === "ONGOING") {
              ongoingCount++;
            } else if (status === "TO_BE_STARTED") {
              toBeStartedCount++;
            }
          });

          setOngoingCountValue(ongoingCount);
          setToBeStartedCountValue(toBeStartedCount);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: ["ONGOING", "TO_BE_STARTED"],
      users: [],
    };
    fetchCustomerJobProcess(data);
  }, []);

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((item) => {
      const name =
        item.customerFileProcess.customerFile.customer.name.toLowerCase();
      const task =
        item.customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name.toLowerCase();
      const fileName =
        item.customerFileProcess.customerFile.fileName.toLowerCase();
      const fileNumber =
        item.customerFileProcess.customerFile.fileNumber.toLowerCase();
      const status = item.customerFileProcessJobDTOList[0].status.toLowerCase();
      const matchesName = name.includes(searchQueryValue.toLowerCase());
      const matchesTask = task.includes(searchQueryValue.toLowerCase());
      const matchesFileName = fileName.includes(searchQueryValue.toLowerCase());
      const matchesFileNumber = fileNumber.includes(
        searchQueryValue.toLowerCase()
      );
      const matchesStatus = status.includes(searchQueryValue.toLowerCase());
      return (
        matchesName ||
        matchesTask ||
        matchesFileName ||
        matchesFileNumber ||
        matchesStatus
      );
    });
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };


  const downloadPDF = (data,type) => {
    const doc = new jsPDF("landscape");
   
     const logoImg = new Image();
     logoImg.src = logoBig;

     doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
     doc.setFontSize(24);
     doc.text("Pending Task List", 200, 15);

    doc.autoTable({
      head: [["Customer Name", "Mobile No.", "Task", "Status"]],
      body: data.map((item) => [
        item.customerFileProcess.customerFile.customer.name,
        item.customerFileProcess.customerFile.customer.authorizedPersonMobile,
        item.customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name,
        `${
          item.customerFileProcessJobDTOList[0].status === "TO_BE_STARTED"
            ? "PENDING"
            : item.customerFileProcessJobDTOList[0].status
        }`,
      ]),
      startY: 25,
      theme: "grid",
      styles: {
        overflow: "linebreak",
        fontSize: 12,    
      },
    });

   
    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
       doc.save("pending-task.pdf");
    }
  };


// const generatePDF = () => {
//   setIsGeneratingPDF(true);
//   setTimeout(() => {
//     const input = document.getElementById("pdfContent");
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF("p", "mm", "a4");
//       const imgWidth = 200; // A4 width in mm
//       const pageHeight = 295; // A4 height in mm
//       const imgHeight = (canvas.height * imgWidth) / canvas.width;
//       let heightLeft = imgHeight;
//       let position = 0;

//       // Add header with logo and file name
//       const logoImg = new Image();
//       logoImg.src = logoBig; // Replace with your logo path
//       const fileName = "Pending Tasks"; // Replace with your file name
//       const headerHeight = 20; // Height of the header

//       // pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
//       // heightLeft -= pageHeight;

//       // Add logo
//       const logoWidth = 40; // Width of the logo
//       const logoHeight = (logoImg.height * logoWidth) / logoImg.width;
//       // pdf.addImage(logoImg, "PNG", 10, 5, logoWidth, logoHeight);
//       pdf.addImage(logoImg, "PNG", 10, 5, 160, 80);

//       // Add underlined file name
//       pdf.setFontSize(36);
//       pdf.setTextColor(1, 41, 112);
//       pdf.text(fileName, 65, 150);

//       while (heightLeft >= 0) {
//         position = heightLeft - imgHeight;
//         pdf.addPage();
//         pdf.addImage(imgData, "PNG", 5, position + 10, imgWidth, imgHeight);
//         heightLeft -= pageHeight;
//       }

//       pdf.save("pending-tasks.pdf");
//       setIsGeneratingPDF(false);
//     });
//   }, 1000);
// };

  // const generatePDF = () => {
  //   setIsGeneratingPDF(true);
  //   setTimeout(() => {
  //     const input = document.getElementById("pdfContent");
  //     html2canvas(input).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const imgWidth = 200; // A4 width in mm
  //       const pageHeight = 295; // A4 height in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       let heightLeft = imgHeight;

  //       // Define header constants
  //       const headerHeight = 30; // Height of the header
  //       const logoWidth = 40; // Width of the logo

  //       // Add pages to PDF
  //       while (heightLeft >= 0) {
  //         // Add new page
  //         // pdf.addPage();

  //         // Add header
  //         pdf.setFillColor(255, 255, 255);
  //         pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), headerHeight, "F");

  //         // Add logo
  //         const logoImg = new Image();
  //         logoImg.src = logo; // Replace with your logo path
  //         const logoHeight = (logoImg.height * logoWidth) / logoImg.width;
  //         pdf.addImage(logoImg, "PNG", 10, 5, logoWidth, logoHeight);

  //         // // Add file name
  //         pdf.setFontSize(16);
  //         pdf.setTextColor(1, 41, 112);
  //         pdf.text("Pending Tasks", 90, 20);

  //         // Add table content
  //         if (heightLeft >= pageHeight - headerHeight) {
  //           pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, imgHeight);
  //           heightLeft -= pageHeight - headerHeight;
  //         } else {
  //           pdf.addImage(imgData, "PNG", 5, headerHeight, imgWidth, heightLeft);
  //           heightLeft = -1; // Force exit while loop
  //         }
  //       }

  //       pdf.save("pending-tasks.pdf");
  //       setIsGeneratingPDF(false);
  //     });
  //   }, 1000);
  // };


  // const generatePDF = () => {
  //   setIsGeneratingPDF(true);
  //   setTimeout(() => {
  //     const input = document.getElementById("pdfContent");
  //     html2canvas(input).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("p", "mm", "a4");
  //       const imgWidth = 210; // A4 width in mm
  //       const pageHeight = 295; // A4 height in mm
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       let heightLeft = imgHeight;
  //       let position = 0;

  //       // Add header with logo and file name
  //       const logoImg = new Image();
  //       logoImg.src = logo; // Replace with your logo path
  //       const fileName = "Pending Tasks"; // Replace with your file name
  //       const headerHeight = 20; // Height of the header

  //       pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         pdf.addPage();
  //         pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }

  //       // Add header
  //       pdf.setFillColor(255, 255, 255);
  //       pdf.rect(0, 0, pdf.internal.pageSize.getWidth(), headerHeight, "F");

  //       // Add logo
  //       const logoWidth = 40; // Width of the logo
  //       const logoHeight = (logoImg.height * logoWidth) / logoImg.width;
  //       pdf.addImage(logoImg, "PNG", 10, 5, logoWidth, logoHeight);

  //       // Add file name
  //       pdf.setFontSize(12);
  //       pdf.setTextColor(0, 0, 0);
  //       pdf.text(fileName, 60, 15);

  //       pdf.save("pending-tasks.pdf");
  //       setIsGeneratingPDF(false);
  //     });
  //   }, 1000);
  // };


  return (
    <main id="main" className="main">
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              {/* Sales Card */}
              <div className="col-md-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 className="card-title">Pending Task List</h5>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 20,
                        }}
                      >
                        <i
                          onClick={() =>
                            downloadPDF(
                              filteredProducts || products,
                              "download"
                            )
                          }
                          className="pi pi-download"
                          style={{
                            fontSize: "2rem",
                            color: "#1e439b",
                            cursor: "pointer",
                          }}
                        ></i>
                        <i
                          onClick={() =>
                            downloadPDF(filteredProducts || products, "print")
                          }
                          className="pi pi-print"
                          style={{
                            fontSize: "2rem",
                            color: "#ff0101",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div
                      className="col-12"
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <InputText
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />

                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                              color: "blue",
                              textAlign: "right",
                            }}
                          >
                            PENDING : {toBeStartedCountValue}
                          </span>{" "}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                              color: "Orange",
                              textAlign: "right",
                            }}
                          >
                            ONGOING : {ongoingCountValue}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                    <div id="pdfContent">
                      <DataTable
                        value={filteredProducts || products}
                        paginator={!isGeneratingPDF}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        dataKey="id"
                        className="p-datatable-customers client-table-design"
                        resizableColumns
                        columnResizeMode="fit"
                      >
                        <Column
                          field="customerFileProcess.customerFile.customer.name"
                          header="Customer Name"
                          resizeable={false}
                          className="product-category table-design"
                        ></Column>
                        <Column
                          field="customerFileProcess.customerFile.customer.authorizedPersonMobile"
                          header="Mobile No."
                          className="product-category table-design"
                        ></Column>
                        <Column
                          field="customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name"
                          header="Task"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span>
                              {
                                rowData.customerFileProcessJobDTOList[0]
                                  .customerFileJob.mvnJob.name
                              }
                            </span>
                          )}
                        ></Column>
                        <Column
                          field="data.customerFileProcessJobDTOList[0].status"
                          header="Status"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span
                              style={{
                                color:
                                  rowData.customerFileProcessJobDTOList[0]
                                    .status === "ONGOING"
                                    ? "Orange"
                                    : rowData.customerFileProcessJobDTOList[0]
                                        .status === "COMPLETED"
                                    ? "green"
                                    : "blue",
                              }}
                            >
                              {rowData.customerFileProcessJobDTOList[0]
                                .status === "TO_BE_STARTED"
                                ? "PENDING"
                                : rowData.customerFileProcessJobDTOList[0]
                                    .status}
                            </span>
                          )}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
