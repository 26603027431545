import { InputText } from "primereact/inputtext";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

// import { createArea, updateArea } from '../../services/area';
// import { errorMessage, successMessage } from "../../utils/alertMessage";

export default function RoleCreate() {
  const titleRef = useRef();
  const { state } = useLocation();
  const defaultValue = state?.item ? { title: state.item.area } : { title: "" };
  const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const payload = { area: titleRef.current.value };
    //         let result = {};
    //         if (state?.item) {
    //             // result = await updateArea({ ...payload, id: state.item.id });
    //         } else {
    //             // result = await createArea(payload);
    //         }
    //         if (result.data.error === false) {
    //             successMessage();
    //             navigate('/area/list');
    //         } else {
    //             errorMessage();
    //         }
    //     } catch (error) {
    //         errorMessage();
    //     }
  };
  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Area</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="name" className="form-label">
                        Title
                      </label>
                      <InputText
                        ref={titleRef}
                        type="text"
                        style={{ width: "100%" }}
                        id="title"
                        name="title"
                        defaultValue={defaultValue.title}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
