import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getCustomerFileByFileId } from "../../services/customerFile";
import { getCustomerFileJob } from "../../services/customerFileJob";
import { Table } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { getJobProcess } from "../../services/process";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function CustomerFileJobList() {
  const { state } = useLocation();
  let clientId = state?.clientId;
  let fileId = state?.fileId;
  const [products, setProducts] = useState(null);
  const [taskTotalFees, setTaskTotalFees] = useState(null);
  const [clientFileInfo, setClientFileInfo] = useState(null);
  const [layout, setLayout] = useState("list");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navigate = useNavigate();
  const [processCreate, setProcessCreate] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [jobProcessData, setJobProcessData] = useState(null);

  const fetchCustomerFile = (clientId, fileId) => {
    getCustomerFileJob(clientId, fileId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const totalJobCost = Res.customerFileJobs
            .flat() // Flatten the array of arrays into a single array
            .reduce((total, job) => total + job.jobCost, 0); // Sum up the jobCost values
          setTaskTotalFees(totalJobCost);
          setProducts(Res.customerFileJobs);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const fetchClient = (clientId, fileId) => {
    getCustomerFileByFileId(clientId, fileId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setClientFileInfo(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: [],
      users: [],
    };
    getJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setJobProcessData(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    fetchClient(clientId, fileId);
    fetchCustomerFile(clientId, fileId);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((p) =>
      p.mvnJob.name.toLowerCase().includes(searchQueryValue.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  const renderListItem = (data) => {
    let startDate = new Date();
    let initialEndDate = new Date();

    const dateType = data.mvnJob.type;
    const jobDate = data.mvnJob?.jobDate;
    const jobDays = data.mvnJob?.jobDays;

    if (dateType === "compliance_date") {
      // If dateType is "compliance_date", set initialEndDate to a specific date
      initialEndDate = new Date(jobDate);
    } else if (dateType === "no_of_days") {
      initialEndDate.setDate(initialEndDate.getDate() + jobDays - 1);
    }
    return (
      <div className="col-12 ">
        <Table className="backgroundDesign">
          <tr>
            <td
              style={{
                width: "30%",
                fontSize: "13px",
                padding: "0px 5px",
                fontWeight: 500,
              }}
            >
              {data.mvnJob.name}
            </td>
            <td style={{ width: "50%", fontSize: "13px", fontWeight: 500 }}>
              {data.mvnJob.description}
            </td>
            <td style={{ width: "10%", fontSize: "13px", fontWeight: 500 }}>
              ₹ {data.jobCost}
            </td>
            <td style={{ width: "10%" }}>
              {initialEndDate > startDate ? (
                <div className="rowDiv">
                  <div style={{ marginRight: 10 }}>
                    <Button
                      onClick={() =>
                        onClickProcessCreate(data, "displayMessage")
                      }
                      className="move-btn-small move-btn-vu center-text"
                    >
                      <i className="bi bi-box-arrow-in-right"></i>
                    </Button>
                    {/*<Link
                      to={"/process/create"}
                      state={{ data, clientId, fileId }}
                      className="move-btn-small move-btn-vu center-text"
                    >
                      <i className="bi bi-box-arrow-in-right"></i>
              </Link>*/}
                  </div>
                </div>
              ) : (
                <div className="rowDiv">
                  <div style={{ marginRight: 10 }}>
                    <p style={{ color: "red", fontSize: 16, fontWeight: 600 }}>
                      Option Expired
                    </p>
                  </div>
                </div>
              )}
            </td>
          </tr>
        </Table>
      </div>
    );
  };

  const renderGridItem = (data) => {
    let startDate = new Date();
    let initialEndDate = new Date();

    const dateType = data.mvnJob.type;
    const jobDate = data.mvnJob?.jobDate;
    const jobDays = data.mvnJob?.jobDays;

    if (dateType === "compliance_date") {
      // If dateType is "compliance_date", set initialEndDate to a specific date
      initialEndDate = new Date(jobDate);
    } else if (dateType === "no_of_days") {
      initialEndDate.setDate(initialEndDate.getDate() + jobDays - 1);
    }
    return (
      <div className="col-12">
        <div className="col-12 backgroundDesign">
          {initialEndDate > startDate ? (
            <div className="rowDiv">
              <div className="col-8">
                <p className="p-class">
                  <i className="pi pi-box product-category-icon blueColorIcon"></i>
                  {data.mvnJob.name}
                </p>
              </div>
              <div className="col-2">
                <p className="p-class">₹ {data.jobCost}</p>
              </div>
              <div className="col-2">
                <Button
                  onClick={() => onClickProcessCreate(data, "displayMessage")}
                  className="move-btn-small move-btn-vu center-text"
                >
                  <i className="bi bi-box-arrow-in-right"></i>
                </Button>
                {/*<Link
                  to={"/process/create"}
                  state={{ data, clientId, fileId }}
                  className="move-btn-small move-btn-vu center-text"
                >
                  <i className="bi bi-box-arrow-in-right"></i>
          </Link>*/}
              </div>
            </div>
          ) : (
            <div className="rowDiv">
              <div className="col-8">
                <p className="p-class">
                  <i className="pi pi-box product-category-icon blueColorIcon"></i>
                  {data.mvnJob.name}
                </p>
              </div>
              <div className="col-4">
                <p style={{ color: "red", fontSize: 16, fontWeight: 600 }}>
                  Option Expired
                </p>
              </div>
            </div>
          )}

          <div className="rowDiv">
            <div className="col-12">
              <p className="p-class">{data.mvnJob.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isCustomerPresentInJobProcess = (selectedData) => {
    if (!jobProcessData || !jobProcessData.length) return false;
    const customerId = selectedData?.customerFile?.customer?.id;
    const fileId = selectedData?.customerFile?.id;
    const mvnJobId = selectedData?.mvnJob?.id;
    if (!customerId || !fileId || !mvnJobId) return false;

    return jobProcessData.some((process) =>
      process.customerFileProcessJobDTOList.some(
        (job) =>
          job.customerFileJob.customerFile.customer.id === customerId &&
          job.customerFileJob.customerFile.id === fileId &&
          job.customerFileJob.mvnJob.id === mvnJobId
      )
    );
  };

  const onClickProcessCreate = (data) => {
    if (!isCustomerPresentInJobProcess(data)) {
      navigate("/process/create", {
        state: {
          data: data,
          clientId: data.customerFile.customer.id,
          fileId: data.customerFile.id,
        },
      });
    }
    setSelectedData(data);
    setProcessCreate(true);
  };

  const onHideProcessCreate = () => {
    setSelectedData(null);
    setProcessCreate(false);
  };

  const renderDialogMessage = () => {
    return (
      <Dialog
        header="Alert"
        visible={processCreate}
        onHide={() => onHideProcessCreate()}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        <div>
          <h6>
            This Task is Already Running For This Client, If You Still Want To
            Create A Duplicate Process Please Click Below.
          </h6>
          <div className="col-md-12 text-center">
            <Link
              to={"/process/create"}
              state={{
                data: selectedData,
                clientId: selectedData?.customerFile?.customer?.id,
                fileId: selectedData?.customerFile?.id,
              }}
              className="move-btn-small move-btn-vu center-text"
            >
              CONFIRM
            </Link>
          </div>
        </div>
      </Dialog>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    const layout = windowWidth > 460 ? "list" : "grid";
    return (
      <div className="grid grid-nogutter">
        <div className="col-6" style={{ textAlign: "left", margin: "5px" }}>
          <InputText
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="col-6" style={{ display: "none", textAlign: "right" }}>
          <DataViewLayoutOptions
            layout={layout}
            onChange={(e) => setLayout(e.value)}
          />
        </div>
        {layout === "list" ? (
          <div className="col-12">
            <Table className="table-design-heading">
              <tr>
                <th style={{ width: "30%", padding: "5px 5px" }}>Task</th>
                <th style={{ width: "50%", padding: "5px 1px" }}>
                  Description
                </th>
                <th style={{ width: "5%", padding: "5px 1px" }}>Fees</th>
                <th style={{ width: "15%", padding: "5px 1px" }}>
                  Add New Process
                </th>
              </tr>
            </Table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const header = renderHeader();

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Customer Info</h5>

                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">Name:</p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientFileInfo?.customer.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Authorized Person:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientFileInfo?.customer.authorizedPerson}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Auth Person Mobile:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientFileInfo?.customer.authorizedPersonMobile}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Profile:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientFileInfo?.customer.profile}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Address:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading text-break">
                          {clientFileInfo?.customer.address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Email Id:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading text-break">
                          {clientFileInfo?.customer.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <h5 className="card-title">Customer File Info</h5>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          File Name:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientFileInfo?.fileName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          File Number:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientFileInfo?.fileNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          File Amount:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">₹ {taskTotalFees}</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading"></p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Customer File Task List</h5>
                  {/*products?.length > 0 && (
                    <div className="col-md-2" style={{ alignSelf: "center" }}>
                      <Link
                        to="/process/create"
                        className="move-btn-anp move-btn-anp center-text"
                        state={{ clientFileInfo, products }}
                      >
                        Add New Process
                      </Link>
                    </div>
                  )*/}
                  {/*<div className="col-md-2" style={{ alignSelf: "center" }}>
                    <Link
                      to="/customer-file-job/create"
                      className="move-btn move-btn-su center-text"
                      state={{ clientFileInfo, products }}
                    >
                      {products ? "Update" : "Create"}
                    </Link>
                </div>*/}
                </div>
                <div className="dataview-demo">
                  <div className="card">
                    {/*<DataView
                      value={filteredProducts || products}
                      layout={layout}
                      header={header}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
              />*/}
                    <DataTable
                      value={filteredProducts || products}
                      paginator
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="mvnJob.name"
                        header="Task"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="mvnJob.description"
                        header="Description"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="jobCost"
                        header="Fees"
                        className="product-category table-design"
                        body={(rowData) => <span>₹ {rowData.jobCost}</span>}
                      ></Column>
                      <Column
                        field="data.customerFileProcessJobDTOList[0].status"
                        header="Action"
                        className="product-category table-design"
                        body={(rowData) => {
                          let startDate = new Date();
                          let initialEndDate = new Date();

                          const dateType = rowData.mvnJob.type;
                          const jobDate = rowData.mvnJob?.jobDate;
                          const jobDays = rowData.mvnJob?.jobDays;

                          if (dateType === "compliance_date") {
                            // If dateType is "compliance_date", set initialEndDate to a specific date
                            initialEndDate = new Date(jobDate);
                          } else if (dateType === "no_of_days") {
                            initialEndDate.setDate(
                              initialEndDate.getDate() + jobDays - 1
                            );
                          }
                          return (
                            <span>
                              {initialEndDate > startDate ? (
                                <div className="rowDiv">
                                  <div style={{ marginRight: 10 }}>
                                    <Button
                                      onClick={() =>
                                        onClickProcessCreate(
                                          rowData,
                                          "displayMessage"
                                        )
                                      }
                                      className="move-btn-small move-btn-vu center-text"
                                    >
                                      <i className="bi bi-box-arrow-in-right"></i>
                                    </Button>
                                    {/*<Link
                      to={"/process/create"}
                      state={{ data, clientId, fileId }}
                      className="move-btn-small move-btn-vu center-text"
                    >
                      <i className="bi bi-box-arrow-in-right"></i>
              </Link>*/}
                                  </div>
                                </div>
                              ) : (
                                <div className="rowDiv">
                                  <div style={{ marginRight: 10 }}>
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: 16,
                                        fontWeight: 600,
                                      }}
                                    >
                                      Option Expired
                                    </p>
                                  </div>
                                </div>
                              )}
                            </span>
                          );
                        }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{renderDialogMessage()}</div>
      </section>
    </main>
  );
}
