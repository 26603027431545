import React, { useEffect, useState } from "react";
import { addClient, getClientById, updateClient } from "../../services/client";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { getServicesType } from "../../services/ServicesType";
import { getServicesTypeFrequency } from "../../services/FrequencyType";
import { getMVNJob } from "../../services/mvnJob";

function ClientEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();

  let clientId = state;
  const [clientInfo, setClientInfo] = useState([]);
  const [clientData, setClientData] = useState({});

  const [taskData, setTaskData] = useState([]);
  const [serviceData, setServiceData] = useState();
  const [frequencyData, setFrequencyData] = useState();
  // const [duplicateCheck, setDuplicateCheck] = useState();
  const [editTaskValue, setEditTaskValue] = useState({});

  const [input, setInput] = useState({
    name: clientData?.name || "",
    profile: clientData?.profile || "",
    authorizedPerson: clientData?.authorizedPerson || "",
    authorizedPersonMobile: clientData?.authorizedPersonMobile || "",
    address: clientData?.address || "",
    email: clientData?.email || "",
  });
  const [errors, setErrors] = useState({
    name: "",
    profile: "",
    authorizedPerson: "",
    authorizedPersonMobile: "",
    address: "",
  });

  const fetchClientById = (clientId) => {
    getClientById(clientId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const clientData = Res.customerDTO;
          setClientData(clientData);
          // setClientInfoOrignal();
          const customerFileJobData = Res.customerFileJobDTOList.map(
            (item) => ({
              id: item.customerFile.id,
              // fees: item.customerFile.totalCost,
              // fees: item.customerFile.perJobCost,
              fileNumber: item.customerFile.fileNumber,

              frequency: item.mvnJobList[0].mvnJob.frequency,
              service: item.mvnJobList[0].mvnJob.service,
              task: item.mvnJobList.map((job) => job.mvnJob.name).join(" | "),
              taskView: item.mvnJobList.map((job) => job),
              fileCheckStatus: item.customerFile.fileCheckStatus,
              action: "Edit",
              p_id: "",
            })
          );

          setClientInfo(customerFileJobData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const fetchServiceAndFrequency = () => {
    getServicesType()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const service = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setServiceData(service);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    getServicesTypeFrequency()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const frequency = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setFrequencyData(frequency);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchClientById(clientId);
    fetchServiceAndFrequency();
  }, []);

  useEffect(() => {
    if (clientData?.name !== "")
      setInput({
        name: clientData?.name,
        profile: clientData?.profile,
        authorizedPerson: clientData?.authorizedPerson,
        authorizedPersonMobile: clientData?.authorizedPersonMobile,
        address: clientData?.address,
        email: clientData?.email,
      });
  }, [clientData]);
  const [additionalFields, setAdditionalFields] = useState([]);

  const initializeAdditionalFields = (taskView) => {
    return taskView.map((item) => ({
      jobCost: item.jobCost || "",
      status: item.status || false,
    }));
  };

  const handleJobCostChange = (index, value) => {
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      if (index < updatedFields.length) {
        updatedFields[index].jobCost = value;
      }
      return updatedFields;
    });
  };

  const handleStatusChange = (index, checked) => {
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      if (index < updatedFields.length) {
        updatedFields[index].status = checked;
      }
      return updatedFields;
    });
  };

  const handleEdit = (rowData) => {
    if (rowData.action === "Edit") {
      setEditTaskValue(rowData);
      setAdditionalFields(initializeAdditionalFields(rowData.taskView));
      setInputOthers({
        id: rowData.id,
        fileNumber: rowData.fileNumber,
        service: rowData.service,
        frequency: rowData.frequency,
        task: "",
        fees: rowData.fees,
        fileCheckStatus: rowData.fileCheckStatus,
        p_id: 0,
      });
    } else {
      const newClientInfo = clientInfo.filter(
        (item) => item.p_id !== rowData.p_id
      );
      setClientInfo(newClientInfo);
    }
  };

  const handleEditTaskData = () => {
    // working
    // Check if all required fields are filled
    if (
      additionalFields.some((field) => field.jobCost === "")
    ) {
      // Show error message or perform any necessary action
      errorMessage("Please fill in all required fields.");
      return;
    }

    const editFileJobData = {
      customerDTO: editTaskValue.taskView[0].customerFile.customer,
      customerFileJobDTOList: [
        {
          customerFile: editTaskValue.taskView[0].customerFile,
          mvnJobList: editTaskValue.taskView.map((item, idx) => ({
            ...item,
            jobCost: additionalFields[idx]?.jobCost,
            status: additionalFields[idx]?.status,
          })),
        },
      ],
    };

    updateClient(editFileJobData)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            successMessage(response.data.message);
            // navigate("/client/list");
            window.location.reload();
          }
        } else {
          // errorMessage(response.data.errors.errors[0].message);
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const [inputOthers, setInputOthers] = useState({
    id: "",
    fileNumber: "",
    service: "",
    frequency: "",
    task: "",
    fees: "",
    fileCheckStatus: true,
    p_id: 0,
  });

  useEffect(() => {
    const data = {
      service: inputOthers.service,
      frequency: inputOthers.frequency,
    };
    getMVNJob(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setTaskData(Res);
          setInputOthers({
            ...inputOthers,
            task: Res.map((item) => item),
          });
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, [inputOthers.service, inputOthers.frequency]);

  const handleAddNewFileTask = () => {
    if (
      input.name.trim() === "" ||
      input.profile.trim() === "" ||
      input.authorizedPerson.trim() === "" ||
      input.authorizedPersonMobile.trim() === "" ||
      input.address.trim() === "" ||
      input.email.trim() === "" ||
      inputOthers.service === "" ||
      inputOthers.frequency === "" ||
      inputOthers.task.length === 0 ||
      inputOthers.fees === ""
    ) {
      // Show error message or perform any necessary action
      errorMessage("Please fill in all required fields.");
      return;
    }
    let data = {
      customerDTO: {
        id: clientData.id,
        name: input.name,
        authorizedPerson: input.authorizedPerson,
        authorizedPersonMobile: input.authorizedPersonMobile,
        profile: input.profile,
        address: input.address,
        email: input.email,
        status: clientData.status,
      },
      customerFileJobDTOList: [
        {
          customerFile: {
            fileName: inputOthers.service + "_" + inputOthers.frequency,
            fileNumber: "",
          },
          mvnJobList: inputOthers.task.map((item) => ({
            mvnJob: item,
            jobCost: inputOthers.fees,
            status: true,
          })),
        },
      ],
    };

    updateClient(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            successMessage(response.data.message);
            // navigate("/client/edit");
            window.location.reload();
          }
        } else {
          // errorMessage(response.data.errors.errors[0].message);
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const handleSubmit = async (event) => {
    console.log("no need");
  };

  const handleEditTaskCancel = () => {
    window.location.reload();
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Customer Update</h5>

                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="name" className="form-label">
                        Customer Name
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="name"
                        name="name"
                        value={input.name}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            name: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.name}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile" className="form-label">
                        Profile
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="profile"
                        name="profile"
                        value={input.profile}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            profile: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.profile}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="authorizedPerson" className="form-label">
                        Authorized Person
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="authorizedPerson"
                        name="authorizedPerson"
                        value={input.authorizedPerson}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            authorizedPerson: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">
                        {errors.authorizedPerson}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor="authorizedPersonMobile"
                        className="form-label"
                      >
                        Authorized Person Mobile
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="authorizedPersonMobile"
                        name="authorizedPersonMobile"
                        value={input.authorizedPersonMobile}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            authorizedPersonMobile: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">
                        {errors.authorizedPersonMobile}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email Id
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="email"
                        name="email"
                        value={input.email}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            email: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.email}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="address"
                        name="address"
                        value={input.address}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            address: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.address}</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Customer Task Update</h5>
                      {!editTaskValue?.taskView?.length > 0 && (
                        <div>
                          <div className="row mb-3 table-design-heading display-table">
                            {inputOthers.id && (
                              <div>
                                <label htmlFor="users" className="form-label">
                                  File Number
                                </label>
                              </div>
                            )}
                            <div className="col-md-2">
                              <label htmlFor="users" className="form-label">
                                Services
                              </label>
                            </div>
                            <div className="col-md-2">
                              <label htmlFor="users" className="form-label">
                                Frequency
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="task" className="form-label">
                                Task
                              </label>
                            </div>
                            <div className="col-md-2">
                              <label htmlFor="fees" className="form-label">
                                Fees
                              </label>
                            </div>
                          </div>
                          <div className="row mb-3">
                            {inputOthers.id && (
                              <div>
                                <label
                                  htmlFor="fileNumber"
                                  className="form-label hide-class"
                                >
                                  File Number
                                </label>
                                <InputText
                                  type="text"
                                  value={inputOthers.fileNumber}
                                  onChange={(e) =>
                                    setInputOthers({
                                      ...inputOthers,
                                      fileNumber: e.target.value,
                                    })
                                  }
                                  style={{ width: "100%" }}
                                  // required
                                />
                              </div>
                            )}
                            <div className="col-md-2">
                              <label
                                htmlFor="users"
                                className="form-label hide-class"
                              >
                                Services
                              </label>
                              <Dropdown
                                value={inputOthers.service}
                                options={serviceData}
                                onChange={(e) =>
                                  setInputOthers({
                                    ...inputOthers,
                                    service: e.target.value,
                                  })
                                }
                                style={{ width: "100%" }}
                                placeholder="Select a Service"
                              />
                            </div>
                            <div className="col-md-2">
                              <label
                                htmlFor="users"
                                className="form-label hide-class"
                              >
                                Frequency
                              </label>
                              <Dropdown
                                value={inputOthers.frequency}
                                options={frequencyData}
                                onChange={(e) =>
                                  setInputOthers({
                                    ...inputOthers,
                                    frequency: e.target.value,
                                  })
                                }
                                style={{ width: "100%" }}
                                placeholder="Select a Time Period"
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="task"
                                className="form-label hide-class"
                              >
                                Task
                              </label>
                              <MultiSelect
                                display="chip"
                                style={{ width: "100%" }}
                                options={taskData}
                                optionLabel="name"
                                value={inputOthers.task}
                                onChange={(e) => {
                                  setInputOthers({
                                    ...inputOthers,
                                    task: e.target.value,
                                  });
                                }}
                                // disabled
                              />
                            </div>
                            <div className="col-md-2">
                              <label
                                htmlFor="fees"
                                className="form-label hide-class"
                              >
                                Fees
                              </label>
                              <InputText
                                type="number"
                                value={inputOthers.fees}
                                onChange={(e) =>
                                  setInputOthers({
                                    ...inputOthers,
                                    fees: e.target.value,
                                  })
                                }
                                style={{ width: "100%" }}
                                // required
                              />
                            </div>
                          </div>
                          <div className="col-md-12 text-center mb-3 mt-3">
                            <button
                              type="button"
                              className="move-btn move-btn-su"
                              onClick={() => handleAddNewFileTask()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      )}

                      {editTaskValue?.taskView?.length > 0 && (
                        <div>
                          <DataTable
                            value={editTaskValue.taskView}
                            scrollable
                            scrollHeight="flex"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            dataKey="id"
                            className="p-datatable-customers client-table-design"
                            showGridlines
                            resizableColumns
                            columnResizeMode="fit"
                          >
                            <Column
                              field="services"
                              header="Services"
                              body={(rowData) => (
                                <span>{rowData.mvnJob.service}</span>
                              )}
                              className="product-category table-design"
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="services"
                              header="Frequency"
                              body={(rowData) => (
                                <span>{rowData.mvnJob.frequency}</span>
                              )}
                              className="product-category table-design"
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="services"
                              header="Task"
                              body={(rowData) => (
                                <span>{rowData.mvnJob.name}</span>
                              )}
                              className="product-category table-design"
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="services"
                              header="Fees"
                              body={(rowData, { rowIndex }) => (
                                <InputText
                                  type="number"
                                  id={`jobCost_${rowIndex}`} // Unique ID for each checkbox
                                  name={`jobCost_${rowIndex}`}
                                  value={
                                    additionalFields[rowIndex]?.jobCost || ""
                                  }
                                  onChange={(e) =>
                                    handleJobCostChange(
                                      rowIndex,
                                      e.target.value
                                    )
                                  }
                                  style={{ width: "100%" }}
                                />
                              )}
                              className="product-category table-design"
                              style={{ width: "10%" }}
                            ></Column>
                            <Column
                              field="services"
                              header="Status"
                              body={(rowData, { rowIndex }) => (
                                <input
                                  type="checkbox"
                                  id={`fileCheckStatus_${rowIndex}`} // Unique ID for each checkbox
                                  name={`fileCheckStatus_${rowIndex}`}
                                  checked={
                                    additionalFields[rowIndex]?.status || false
                                  }
                                  onChange={(e) =>
                                    handleStatusChange(
                                      rowIndex,
                                      e.target.checked
                                    )
                                  }
                                  disabled={rowData.disabled} // Ensure the input is not disabled
                                />
                              )}
                              className="product-category table-design"
                              style={{ width: "10%" }}
                            ></Column>
                          </DataTable>
                          <div className="col-md-12 text-center mb-3 mt-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                textAlign: "center",
                              }}
                            >
                              <button
                                type="button"
                                className="move-btn move-btn-su"
                                onClick={handleEditTaskData}
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                className="move-btn move-btn-su"
                                onClick={handleEditTaskCancel}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      <DataTable
                        value={clientInfo}
                        paginator
                        scrollable
                        scrollHeight="flex"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        dataKey="id"
                        className="p-datatable-customers client-table-design"
                        showGridlines
                        resizableColumns
                        columnResizeMode="fit"
                      >
                        <Column
                          field="services"
                          header="Services"
                          body={(rowData) => <span>{rowData.service}</span>}
                          className="product-category table-design"
                          style={{ width: "10%" }}
                        ></Column>
                        <Column
                          field="frequency"
                          header="Frequency"
                          className="product-category table-design"
                          body={(rowData) => <span>{rowData.frequency}</span>}
                          style={{ width: "10%" }}
                        ></Column>
                        <Column
                          field="task"
                          header="Task"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {rowData.task}
                            </span>
                          )}
                          style={{ width: "65%" }}
                        />
                        <Column
                          field="action"
                          header="Action"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleEdit(rowData)}
                              >
                                {rowData.action}
                              </button>
                            </span>
                          )}
                          style={{ width: "8%" }}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ClientEdit;
