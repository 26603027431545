import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link } from "react-router-dom";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { getRole } from "../../services/getRole";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";

export default function JobRoleList() {

  const [products, setProducts] = useState(null);
  const [layout, setLayout] = useState("list");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

const fetchRole = () => {
  getRole()
    .then((response) => {
      if (!response.data.error) {
        const Res = response.data;
        setProducts(Res);
      }
    })
    .catch((err) => {
      console.error("Error:", err);
    });
};

useEffect(() => {
  fetchRole();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

   const handleSearchInputChange = (event) => {
     const searchQueryValue = event.target.value;
     const filteredProducts = products.filter((p) =>
       p.role.toLowerCase().includes(searchQueryValue.toLowerCase())
     );
     setFilteredProducts(filteredProducts);
     setSearchQuery(searchQueryValue);
   };

  const renderListItem = (data) => {
    return (
      <div className="col-12 backgroundDesign">
        <div className="product-list-item">
          <div className="product-list-detail">
            <div className="product-name">{data.role}</div>
          </div>
          <div className="product-list-action">
            <Link
              to={"/role/create"}
              state={data}
              className="move-btn move-btn-re center-text"
            >
              <i className="bi bi-pencil-square"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderGridItem = (data) => {
    return (
      <div className="col-12 md:col-4 backgroundDesign">
        <div className="product-grid-item">
          <div className="product-grid-item-content">
            <div className="product-name">{data.role}</div>
          </div>
          <div className="product-grid-item-bottom">
            <span className="product-price"></span>
            <Link
              to={"/role/create"}
              state={data}
              className="move-btn move-btn-re center-text"
            >
              Edit <i className="bi bi-pencil-square"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    return (
      <div className="grid grid-nogutter">
        <div className="col-6" style={{ textAlign: "left" }}>
          <InputText
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="col-6" style={{ display: "none", textAlign: "right" }}>
          <DataViewLayoutOptions
            layout={layout}
            onChange={(e) => setLayout(e.value)}
          />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Role List</h5>
                  <div className="col-md-2" style={{ alignSelf: "center" }}>
                    <Link
                      to="/role/create"
                      className="move-btn move-btn-su center-text"
                    >
                      Create
                    </Link>
                  </div>
                </div>
                <div className="dataview-demo">
                  <div className="card">
                    <DataView
                      value={filteredProducts || products}
                      layout={layout}
                      header={header}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
