import React, {useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { addRole, updateRole } from "../../services/getRole";
import { InputText } from "primereact/inputtext";

export default function JobRoleCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [input, setInput] = useState({
    role: state?.role || ""
  });
  const [errors, setErrors] = useState({
    role: ""
  });

  

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Example: Validate email format
    // const emailRegex = /^\S+@\S+\.\S+$/;
    if (input.role === "") {
      newErrors.role = "Please enter role";
      isValid = false;
    } else {
      newErrors.role = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (state) {
        let data = {
          id: state.id,
          role: input.role
        };
        updateRole(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                successMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/role/list");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      } else {
        let data = {
          role: input.role
        };
        addRole(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                successMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/role/list");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Role {state ? "Update" : "Create"}
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="score" className="form-label">
                        Role
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="role"
                        name="role"
                        value={input.role}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            role: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.role}</div>
                    </div>
                  </div>

                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
