import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { getClient } from "../services/client";
import { getUser } from "../services/userMasterUser";
import { getJobProcess } from "../services/process";
import { Chart } from "primereact/chart";

const DashboardAdmin = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const [customer, setCustomer] = useState(null);
  const [user, setUser] = useState(null);
  const [revenue, setRevenue] = useState(null);
    const [ongoingCountValue, setOngoingCountValue] = useState(0);
    const [toBeStartedCountValue, setToBeStartedCountValue] = useState(0);
    const [pendingFees, setPendingFees] = useState(0);
    const [pendingFeesCount, setPendingFeesCount] = useState(0);

      const [chartData, setChartData] = useState({});
      const [chartOptions, setChartOptions] = useState({});

  const fetchClient = () => {
    getClient()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setCustomer(Res.length);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    getUser()
      .then((response) => {
        if (!response.data.error) {
          setUser(response.data.length);
        }
      })
      .catch((err) => {
      });
  };

  const revenueList = (data) => {
    getJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const filteredData = Res.filter(
            (item) =>
              item.customerFileProcessJobDTOList[0].status === "COMPLETED" 
          );
          const filteredPendingData = Res.filter(
            (item) =>
              item.customerFileProcessJobDTOList[0].status === "COMPLETED" &&
              item.customerFileProcessJobDTOList[0].invoice === null
          );
            setPendingFeesCount(filteredPendingData.length);
           const totalRevenue = filteredData.reduce((sum, item) => {
             return sum + item.customerFileProcessJobDTOList[0].revenue;
           }, 0);
           const totalPendingFees = filteredPendingData.reduce((sum, item) => {
             return sum + item.customerFileProcessJobDTOList[0].revenue;
           }, 0);
          setRevenue(totalRevenue);
          setPendingFees(totalPendingFees);
           let ongoingCount = 0;
           let toBeStartedCount = 0;

           Res.forEach((item) => {
             const status = item.customerFileProcessJobDTOList[0].status;
             if (status === "ONGOING") {
               ongoingCount++;
             } else if (status === "TO_BE_STARTED") {
               toBeStartedCount++;
             }
           });

           setOngoingCountValue(ongoingCount);
           setToBeStartedCountValue(toBeStartedCount);


           const documentStyle = getComputedStyle(document.documentElement);
           const data = {
             labels: ["PENDING", "ONGOING", "COMPLETED"],
             datasets: [
               {
                 data: [toBeStartedCount, ongoingCount, filteredData.length],
                 backgroundColor: [
                   documentStyle.getPropertyValue("--blue-500"),
                   documentStyle.getPropertyValue("--yellow-500"),
                   documentStyle.getPropertyValue("--green-500"),
                 ],
                 hoverBackgroundColor: [
                   documentStyle.getPropertyValue("--blue-400"),
                   documentStyle.getPropertyValue("--yellow-400"),
                   documentStyle.getPropertyValue("--green-400"),
                 ],
               },
             ],
           };
           const options = {
             cutout: "60%",
           };

           setChartData(data);
           setChartOptions(options);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };





  useEffect(() => {
    fetchClient();
     const data = {
      //  startDate: "",
      //  endDate: "",
       types: [],
       status: [],
       users: [],
     };
     revenueList(data);
  }, []);

  return (
    <main id="main" className="main">
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              {/* Sales Card */}
              <div className=" col-md-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 className="card-title">
                        Admin Dashboard <span>| MVN</span>
                      </h5>
                      <i
                        className="pi pi-filter-fill"
                        onClick={toggleFilter}
                        style={{ color: "#1e439b" }}
                      ></i>
                    </div>
                    <div className="row">
                      <div className=" col-md-4">
                        <div className="card">
                          <Card title="Customers" style={{ color: "#1e439b" }}>
                            <h3 className="m-0">
                              <span style={{ color: "#ff5501" }}>
                                {customer}
                              </span>
                            </h3>
                          </Card>
                        </div>
                      </div>
                      <div className=" col-md-4">
                        <div className="card">
                          <Card title="Users" style={{ color: "#1e439b" }}>
                            <h3 className="m-0">
                              <span style={{ color: "#ff5501" }}>{user}</span>
                            </h3>
                          </Card>
                        </div>
                      </div>

                      <div className=" col-md-4">
                        <div className="card">
                          <Card title="Revenue" style={{ color: "#1e439b" }}>
                            <h3 className="m-0">
                              <span style={{ color: "#ff5501" }}>
                                ₹ {revenue}
                              </span>
                            </h3>
                          </Card>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-md-4">
                        <div className="card">
                          <Card
                            title="Pending Task"
                            style={{ color: "#1e439b" }}
                          >
                            <div className="rowDiv">
                              <p style={{ alignSelf: "center" }}>
                                PENDING:{" "}
                              </p>
                              <p className="m-0">
                                <span
                                  style={{
                                    color: "#ff5501",
                                    fontSize: 16,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {toBeStartedCountValue}
                                </span>
                              </p>
                            </div>
                            <div className="rowDiv">
                              <p style={{ alignSelf: "center" }}>ONGOING: </p>
                              <p className="m-0">
                                <span
                                  style={{
                                    color: "#ff5501",
                                    fontSize: 16,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {ongoingCountValue}
                                </span>
                              </p>
                            </div>
                          </Card>
                        </div>
                      </div>
                      <div className=" col-md-4">
                        <div className="card">
                          <Card
                            title="Pending Fees"
                            style={{ color: "#1e439b" }}
                          >
                            <div className="rowDiv">
                              <p style={{ alignSelf: "center" }}>Count: </p>
                              <p className="m-0">
                                <span
                                  style={{
                                    color: "#ff5501",
                                    fontSize: 16,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {pendingFeesCount}
                                </span>
                              </p>
                            </div>
                            <div className="rowDiv">
                              <p style={{ alignSelf: "center" }}></p>
                              <p className="m-0">
                                <span
                                  style={{
                                    color: "#ff5501",
                                    fontSize: 16,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  ₹ {pendingFees}
                                </span>
                              </p>
                            </div>
                          </Card>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <Chart
                          type="doughnut"
                          data={chartData}
                          options={chartOptions}
                          className="w-full md:w-20rem"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DashboardAdmin;
