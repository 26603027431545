import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getEmployeeRevenue } from "../../services/EmployeeRevenue";
import { DataView } from "primereact/dataview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logoBig from "../../assets/logo1.png";

export default function MonthlyRevenue() {
  let startDate = new Date();
  let initialEndDate = new Date();

  const [employeeRevenueData, setEmployeeRevenueData] = useState([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (startDate, endDate) => {
    let data = {
      // users: input.users,
      startDate: startDate,
      endDate: endDate,
      status: ["COMPLETED"],
      dateFilterType: "completedOn",
    };

    getEmployeeRevenue(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            successMessage(response.data.message);
            // setEmployeeRevenueData(response.data);
            setEmployeeRevenueData(response.data.sort((a, b) =>
              a.customerFileProcessJob.customerFileJob.customerFile.customer.name
                .trim()
                .localeCompare(
                  b.customerFileProcessJob.customerFileJob.customerFile.customer.name.trim()
                )
            ));
            
          }
        } else {
          // errorMessage(response.data.errors.errors[0].message);
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  startDate.setDate(startDate.getDate() - 7);

  const fetchData = () => {
    let data = {
      // users: usersData.map((item) => item.value),
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(initialEndDate).toISOString(),
      status: ["COMPLETED"],
      dateFilterType: "completedOn",
    };

    getEmployeeRevenue(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            setEmployeeRevenueData(response.data);
          }
        } else {
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const calculateTotalRevenue = () => {
    return employeeRevenueData.reduce((total, employee) => {
      return (
        total +
        (employee.jobs?.reduce(
          (jobTotal, job) => jobTotal + job.workRevenue,
          0
        ) || 0)
      );
    }, 0);
  };
  const footerGroupBusinessDevelopment = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={calculateTotalRevenue()} />
      </Row>
    </ColumnGroup>
  );

  const downloadPDF = (data, type) => {
    const doc = new jsPDF("landscape");
    const logoImg = new Image();
    logoImg.src = logoBig;

    doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
    doc.setFontSize(24);
    doc.text("Monthly Revenue List", 200, 15);

    const tableData = data.flatMap((employee) =>
      employee.jobs.map((job) => [
        job.customerFileProcessJob.customerFileJob.customerFile.customer.name,
        job.customerFileProcessJob.customerFileJob.mvnJob.name,
        job.workRevenue,
        job.customerFileProcessJob.invoiceNo,
        `${job.customerFileProcessJob.invoice ? "YES" : "NO"}`,
      ])
    );

    doc.autoTable({
      head: [
        ["Customer Name", "Task", "Revenue", "Invoice No.", "Invoice Uploaded"],
      ],
      body: tableData,
      startY: 25,
      theme: "grid",

      styles: {
        overflow: "linebreak",
        fontSize: 12,
      },
    });

    const totalRevenue = calculateTotalRevenue();
    doc.setFontSize(16);
    doc.text(
      `Total Revenue: ${totalRevenue}`,
      15,
      doc.previousAutoTable.finalY + 10
    );

    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      doc.save("monthly-revenue-list.pdf");
    }
  };

  const renderListItem = (data, indexing) => {
    return (
      <div className="col-12 ">
        <DataTable
          value={data.jobs}
          paginator={!isGeneratingPDF}
          scrollable
          scrollHeight="flex"
          footerColumnGroup={footerGroupBusinessDevelopment}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          dataKey="id"
          className="p-datatable-customers"
          showGridlines
          resizableColumns
          columnResizeMode="fit"
        >
          <Column
            field="customerFileProcessJob.customerFileJob.customerFile.customer.name"
            header="Customer Name"
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcessJob.customerFileJob.mvnJob.name"
            header="Task"
            className="product-category table-design"
          ></Column>
          {/*<Column
            field="customerFileProcessJob.customerFileJob.customerFile.fileName"
            header="File"
            body={(rowData) => (
              <span>
                {
                  rowData.customerFileProcessJob.customerFileJob.customerFile
                    .fileName
                }{" "}
                |{" "}
                {
                  rowData.customerFileProcessJob.customerFileJob.customerFile
                    .fileNumber
                }
              </span>
            )}
            className="product-category table-design"
          ></Column>*/}
          <Column
            field="workRevenue"
            header="Revenue"
            className="product-category table-design"
            body={(rowData) => <span>₹ {rowData.workRevenue}</span>}
          ></Column>
          <Column
            field="customerFileProcessJob.invoiceNo"
            header="Invoice No."
            body={(rowData) => (
              <span>{rowData.customerFileProcessJob.invoiceNo}</span>
            )}
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcessJob.invoiceNo"
            header="Invoice Uploaded"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJob.invoice ? "YES" : "NO"}
              </span>
            )}
            className="product-category table-design"
          ></Column>
        </DataTable>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }

    return renderListItem(product);
  };

  const handleMonthButtonClick = (month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, month, 0).getDate(); // Get the number of days in the specified month

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const startDate = `${currentYear}-${formattedMonth}-01T00:00:00.000Z`;
    const endDate = `${currentYear}-${formattedMonth}-${daysInMonth}T23:59:59.999Z`;

    handleSubmit(startDate, endDate);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Monthly Revenue List</h5>
                  {employeeRevenueData.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                        marginTop: 10,
                      }}
                    >
                      <i
                        onClick={() =>
                          downloadPDF(employeeRevenueData, "download")
                        }
                        className="pi pi-download"
                        style={{
                          fontSize: "2rem",
                          color: "#1e439b",
                          cursor: "pointer",
                        }}
                      ></i>
                      <i
                        onClick={() =>
                          downloadPDF(employeeRevenueData, "print")
                        }
                        className="pi pi-print"
                        style={{
                          fontSize: "2rem",
                          color: "#ff0101",
                          cursor: "pointer",
                        }}
                      ></i>
                    </div>
                  )}
                </div>
                <form>
                  <div className="row mb-5">
                    {/* Buttons for each month */}
                    {[...Array(12).keys()].map((month) => (
                      <div style={{ flex: 1, marginBottom: "5px" }} key={month}>
                        <button
                          type="button"
                          className="move-btn move-btn-month"
                          onClick={() => handleMonthButtonClick(month + 1)}
                        >
                          {new Date(2000, month).toLocaleString("default", {
                            month: "short",
                          })}
                        </button>
                      </div>
                    ))}
                  </div>
                </form>
                <div className="dataview-demo">
                  <div className="card" id="pdfContent">
                    <DataView
                      value={employeeRevenueData}
                      itemTemplate={itemTemplate}
                      // paginator
                      // rows={9}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
