import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getCustomerFileJob = (customerId, fileId) => {
  return axios({
    method: "get",
    url:
      BASE_URL +
      "api/secure/v2/customer/" +
      customerId +
      "/file/" +
      fileId +
      "/job",
    headers: getHeaders(),
  });
};

export const addCustomerFileJob = (customerId, fileId, data) => {
  return axios({
    method: "post",
    url:
      BASE_URL +
      "api/secure/v2/customer/" +
      customerId +
      "/file/" +
      fileId +
      "/job",
    data: JSON.stringify(data),
    headers: getHeaders(),
  });
};

export const updateCustomerFileJob = (customerId, fileId, data) => {
  return axios({
    method: "put",
    url:
      BASE_URL +
      "api/secure/v2/customer/" +
      customerId +
      "/file/" +
      fileId +
      "/job",
    data: JSON.stringify(data),
    headers: getHeaders(),
  });
};
