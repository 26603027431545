import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { useLocation, useNavigate } from "react-router-dom";

import { errorMessage, successMessage } from "../../utils/alertMessage";
import {
  addEmployeeReportTemplate,
  getEmployeeReportTypeTemplate,
  updateEmployeeReportTemplate,
} from "../../services/TemplateConfig";
import { Dropdown } from "primereact/dropdown";
import CopyToClipboard from "react-copy-to-clipboard";

const TemplateConfig = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [textToCopy, setTextToCopy] = useState(""); // The text you want to copy
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };

  useEffect(() => {
    if (state !== null) {
      setInput({
        reportType: state.reportType || "",
        template: state.template || "",
        templateCampaignName: state.templateCampaignName || "",
        templateAttributeSequence: state.templateAttributeSequence || "",
      });
    }
  }, [state]);

  const [reportTypeDropdown, setReportTypeDropdown] = useState();
  const fetchEmployeeReportRole = () => {
    getEmployeeReportTypeTemplate()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const roleData = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setReportTypeDropdown(roleData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchEmployeeReportRole();
  }, []);

  const [input, setInput] = useState({
    createdDate: new Date(),
    reportType: "",
    template: "",
    templateCampaignName: "",
    templateAttributeSequence: "",
  });

  const [errors, setErrors] = useState({});

  const [templateString, setTemplateString] = useState("");
  const templates = {
    Course_Department:
      "userName,reportDate,no_of_new_lead,no_of_call,no_of_sale",
    Business_Development:
      "userName,reportDate,no_of_old_broker_meet,no_of_new_broker_meet,no_of_proposal_recd_loan_project_report_other,loan_amount_lacs,project_report_other_fees,new_booking_amount,old_balance_recovered,total_record",
    Digital_Marketing: "userName,reportDate,long_video,shorts,blog",
    Loan_Back_Office:
      "userName,reportDate,new_file_login,new_file_login_amount_lacs,file_sanction,file_sanction_amount_lacs,file_disburse,file_disburse_amount_lacs",
    Project_Report_Sales:
      "userName,reportDate,no_of_new_lead,no_of_new_project_report_software_booked,new_booking_amount,old_balance_recovered,total_collection",
    Tele_calling_Team_Loan:
      "userName,reportDate,no_of_new_call,no_of_follow_up,no_of_file_login,no_of_file_sanction,no_of_file_disburse",
    Project_Report_Back_Office:
      "userName,reportDate,no_of_project_report_prepared",
    Account_Department: "userName,reportDate,no_of_bill_raised,amount",
    // Add more templates as needed
  };

  const getTemplateForReportType = (reportType) => {
    return templates[reportType] || "";
  };

  const getTemplateForReportTypeValue = getTemplateForReportType();
  console.log("getTemplateForReportType", getTemplateForReportTypeValue);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let response;
      if (state !== null) {
        const updateData = {
          id: state?.id,
          reportType: input.reportType,
          template: input.template,
          templateCampaignName: input.templateCampaignName,
          templateAttributeSequence: input.templateAttributeSequence,
        };

        response = await updateEmployeeReportTemplate(updateData); // Update the API call for editing
      } else {
        const data = {
          createdDate: new Date(input.createdDate).toISOString(),
          reportType: input.reportType,
          template: input.template,
          templateCampaignName: input.templateCampaignName,
          templateAttributeSequence: input.templateAttributeSequence,
        };
        response = await addEmployeeReportTemplate(data);
      }
      if (!response.data.error) {
        if (response.data.success === false) {
          errorMessage(response.data.message);
        } else {
          successMessage(response.data.message);
        }
        setInput({
          reportType: "",
          template: "",
          templateCampaignName: "",
          templateAttributeSequence: "",
        });
        navigate("/template-config-view");
      } else {
        errorMessage(response.data.errors.errors[0].message);
      }
    } catch (err) {
      errorMessage();
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {state !== null ? "Edit" : "Create"} Template
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3 mb-1">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Report Type</label>
                        <Dropdown
                          value={input.reportType}
                          options={reportTypeDropdown}
                          optionLabel="label"
                          onChange={(e) => {
                            setInput({
                              ...input,
                              reportType: e.value,
                            });
                            const template = getTemplateForReportType(e.value);
                            setTemplateString(template);
                            setTextToCopy(template);
                          }}
                          placeholder="Select a Report Type"
                          style={{ width: "100%" }}
                        />
                        {errors.reportType && (
                          <div className="text-danger">{errors.reportType}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Template</label>
                        <input
                          type="text"
                          name="template "
                          value={input.template}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              template: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.template && (
                          <div className="text-danger">{errors.template}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-1">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Template Campaign Name</label>
                        <input
                          type="text"
                          name="templateCampaignName"
                          value={input.templateCampaignName}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              templateCampaignName: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.templateCampaignName && (
                          <div className="text-danger">
                            {errors.templateCampaignName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Template Attribute Sequence</label>
                        <input
                          type="text"
                          name="templateAttributeSequence "
                          value={input.templateAttributeSequence}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              templateAttributeSequence: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.templateAttributeSequence && (
                          <div className="text-danger">
                            {errors.templateAttributeSequence}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 text-center">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success ms-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                {templateString !== "" && (
                  <div className="row">
                    <div className="col-md-12">
                      <p style={{ color: "red",  }}>
                        Please Copy This Required Fields For Template Attribute
                        Sequence
                      </p>

                      <span style={{ color: "#000", fontSize:16, }}>{templateString}</span>
                      <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
                        <button className="btn btn-danger ms-2">
                          {copyStatus ? (
                            <i class="bi bi-check-lg"></i>
                          ) : (
                            <i class="bi bi-clipboard"></i>
                          )}
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TemplateConfig;
