import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { MultiSelect } from "primereact/multiselect";
import { getJobs } from "../../services/JobMasterJobs";
import { addCustomerFileJob, updateCustomerFileJob } from "../../services/customerFileJob";
import { InputText } from "primereact/inputtext";

export default function CustomerFileJobCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();


  const mvnJobId = state?.products?.map((item) => item.mvnJob.id);

  let clientId = state.clientFileInfo.customer.id;
  let customerFileId = state.clientFileInfo.id;

  const [input, setInput] = useState({
    customerFileId: customerFileId,
    mvnJobId: mvnJobId || "",
  });
  const [errors, setErrors] = useState({
    customerFileId: "",
    mvnJobId: "",
  });

  const [jobsData, setJobsData] = useState([]);

  const fetchJobUserData = () => {
    getJobs()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const filteredData = response.data.map((jobs) => ({
            label: jobs.name,
            value: jobs.id,
          }));
          setJobsData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  useEffect(() => {
    fetchJobUserData();
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Example: Validate email format
    // const emailRegex = /^\S+@\S+\.\S+$/;
    if (input.mvnJobId.length === 0) {
      newErrors.mvnJobId = "Please Select Jobs";
      isValid = false;
    } else {
      newErrors.mvnJobId = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (state.products) {
        let data = {
          fileId: input.customerFileId,
          mvnJobs: input.mvnJobId,
          status: true,
        };
        updateCustomerFileJob(clientId,customerFileId, data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/customer-file-job/list", {
                  state: {
                    clientId: clientId,
                    fileId: customerFileId,
                  },
                });
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      } else {
        let data = {
          fileId: input.customerFileId,
          mvnJobs: input.mvnJobId,
          status: true,
        };
        addCustomerFileJob(clientId, customerFileId, data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/customer-file-job/list", {
                  state: {
                    clientId: clientId,
                    fileId: customerFileId,
                  },
                });
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Client File Job {state.data ? "Update" : "Create"}
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="customerFileId" className="form-label">
                        File Name
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="customerFileId"
                        name="customerFileId"
                        value={state.clientFileInfo.fileName}
                        readOnly
                      />
                      <div className="text-danger">{errors.customerFileId}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="mvnJobId" className="form-label">
                        Jobs
                      </label>

                      <MultiSelect
                        display="chip"
                        value={input.mvnJobId}
                        style={{ width: "100%" }}
                        options={jobsData}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            mvnJobId: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="text-danger mt-1">{errors.mvnJobId}</div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
