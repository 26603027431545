import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const addJobs = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/mvn-job",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getJobs = () => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/mvn-job",
    headers: getHeaders(),
  });
};

export const updateJobs = (data) => {
  return axios({
    method: "put",
    url: BASE_URL + "api/secure/v1/mvn-job",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const getJobsById = (id) => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/mvn-job/" + id,
    headers: getHeaders(),
  });
};


