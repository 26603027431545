import React, { useEffect, useState } from "react";
import { getClientById } from "../../services/client";
import { Link, useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function ClientListView() {
  const { state } = useLocation();
  let clientId = state;
  const [clientInfo, setClientInfo] = useState([]);
  const [clientData, setClientData] = useState({});

  const fetchClientById = (clientId) => {
    getClientById(clientId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const clientData = Res.customerDTO;
          setClientData(clientData);
          const newData = Res.customerFileJobDTOList.map((item) => ({
            id: item.customerFile.id,
            //  fees: item.customerFile.totalCost,
            fees: item.customerFile.perJobCost,
            frequency: item.mvnJobList[0].mvnJob.frequency,
            service: item.mvnJobList[0].mvnJob.service,
            task: item.mvnJobList.map((job) => job.mvnJob.name).join(" | "),
            //  taskView: item.mvnJobList.map((job) => job.jobCost),
            taskView: item.mvnJobList.reduce(
              (total, job) => total + job.jobCost,
              0
            ),
          }));

          setClientInfo(newData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchClientById(clientId);
  }, []);

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Customer Info</h5>
                  <div className="col-md-2" style={{ alignSelf: "center" }}>
                    <Link
                      to={"/client/edit"}
                      state={clientData.id}
                      className="move-btn move-btn-su center-text"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">Name:</p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">{clientData.name}</p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Authorized Person:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientData.authorizedPerson}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-right">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Auth Person Mobile:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientData.authorizedPersonMobile}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-right">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Profile:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientData.profile}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Address:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading text-break">
                          {clientData?.address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Email Id:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading text-break">
                          {clientData?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Task Info</h5>
                <DataTable
                  value={clientInfo}
                  paginator
                  scrollable
                  scrollHeight="flex"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  dataKey="id"
                  className="p-datatable-customers"
                  showGridlines
                  resizableColumns
                  columnResizeMode="fit"
                >
                  <Column
                    field="services"
                    header="Services"
                    body={(rowData) => <span>{rowData.service}</span>}
                    className="product-category table-design"
                    style={{ width: "10%" }}
                  ></Column>
                  <Column
                    field="frequency"
                    header="Frequency"
                    className="product-category table-design"
                    body={(rowData) => <span>{rowData.frequency}</span>}
                    style={{ width: "10%" }}
                  ></Column>
                  <Column
                    field="task"
                    header="Task"
                    className="product-category table-design"
                    body={(rowData) => (
                      <span style={{ whiteSpace: "pre-wrap" }}>
                        {rowData.task}
                      </span>
                    )}
                    style={{ width: "70%" }}
                  />
                  <Column
                    field="fees"
                    header="Fees"
                    className="product-category table-design"
                    body={(rowData) => <span>₹ {rowData.taskView}</span>}
                    style={{ width: "10%" }}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ClientListView;
