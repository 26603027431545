import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { updateJobProcess } from "../../services/process";
import { getJobsById } from "../../services/JobMasterJobs";
import { MultiSelect } from "primereact/multiselect";
import { costCalculation } from "../../services/costCalculation";
import { InputNumber } from "primereact/inputnumber";

export default function ProcessEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [jobData, setJobData] = useState(
    state.customerFileProcessJobDTOList[0].customerFileJob
  );

  const customerFileId = jobData.customerFile.id;
  const customerFileJobId = jobData.id;
  const mvnJobId = jobData.mvnJob.id;

  const [usersData, setUsersData] = useState([]);
  const [estimatedCostData, setEstimatedCostData] = useState();
  const [userIdData, setUserIdData] = useState([]);

  const fetchUserData = (id) => {
    getJobsById(id)
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const filteredData = response.data.map((item) => ({
            label:
              item.user.firstName +
              " " +
              item.user.lastName +
              " (" +
              item.user.dayCost.toFixed(0) +
              " / day)",
            value: item.id,
            userId: item.user.id,
          }));
          setUsersData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const fetchCostCalculation = (data) => {
    costCalculation(data)
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const Res = response.data;
          setEstimatedCostData(Res);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const jobU =
    state?.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
      (item) => item.jobUser.id
    );
  //  const jobUId =
  //    state?.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
  //      (item) => item.jobUser.user.id
  //    );

  useEffect(() => {
    // fetchStatus("CUSTOMER_FILE_PROCESS");
    mvnJobId != null && fetchUserData(mvnJobId);
    //   if(jobUId) {
    //     const costData = {
    //     endDate: input.endDate,
    //     startDate: input.startDate,
    //     userIds: jobUId,
    //   };
    //   fetchCostCalculation(costData);
    // }
  }, []);

  const [input, setInput] = useState({
    jobUser: jobU || "",
    startDate: new Date(state?.customerFileProcess.startOn),
    endDate: new Date(state?.customerFileProcess.endOn),
    remark: state?.customerFileProcess.remarks || "",
    status: state?.customerFileProcessJobDTOList[0].status,
    revenue: state?.customerFileProcessJobDTOList[0].revenue || "",
  });

  const [errors, setErrors] = useState({
    jobUser: "",
    startDate: "",
    endDate: "",
    remark: "",
    status: "",
    revenue: "",
  });

  useEffect(() => {
    if (input.jobUser !== "") {
      const costData = {
        endDate: input.endDate,
        startDate: input.startDate,
        userIds: userIdData,
      };
      fetchCostCalculation(costData);
    }
  }, [input]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (input.jobUser.length === 0) {
      setErrors({ jobUser: "Please Select Any User" });
    }
    // else if (input.status === "") {
    // setErrors({ status: "Please Select Any Status" });
    // }
    else {
      setErrors({
        jobUser: "",
        startDate: "",
        endDate: "",
        remark: "",
        status: "",
        revenue: "",
      });
      let submitData = {
        customerFileProcess: {
          id: state?.customerFileProcess.id,
          customerFile: {
            id: customerFileId,
          },
          endOn: new Date(input.endDate).toISOString(),
          remarks: input.remark,
          startOn: new Date(input.startDate).toISOString(),
          status: input.status,
        },
        customerFileProcessJobList: [
          {
            id: state?.customerFileProcessJobDTOList[0].id,
            customerFileJob: {
              id: customerFileJobId,
            },
            endOn: new Date(input.endDate).toISOString(),
            customerFileProcessJobUsers: input.jobUser.map((item) => ({
              jobUser: { id: item },
            })),
            remarks: input.remark,
            startOn: new Date(input.startDate).toISOString(),
            status: input.status,
            revenue: input.revenue,
          },
        ],
      };

      updateJobProcess(submitData)
        .then((response) => {
          if (!response.data.error) {
            if (response.data.success === false) {
              errorMessage(response.data.message);
            } else {
              successMessage(response.data.message);
              navigate("/process/running/list");
            }
          } else {
            // errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Update Process</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="startDate" className="form-label">
                        Start Date
                      </label>
                      <Calendar
                        id="startDate"
                        name="startDate"
                        value={input.startDate}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            startDate: e.target.value,
                          });
                        }}
                        showIcon
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="endDate" className="form-label">
                        End Date
                      </label>
                      <Calendar
                        id="endDate"
                        name="endDate"
                        value={input.endDate}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            endDate: e.target.value,
                          });
                        }}
                        showIcon
                        disabled
                      />
                    </div>
                  </div>
                  {/* <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="type" className="form-label">
                        Type
                      </label>
                      <InputText
                        type="text"
                        id="type"
                        name="type"
                        style={{ width: "100%", textTransform: "uppercase" }}
                        value={jobData.mvnJob.type.replace(/_/g, " ")}
                        readOnly
                      />
                    </div>
                      </div>*/}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="remark" className="form-label">
                        Remark
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="remark"
                        name="remark"
                        value={input.remark}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            remark: e.target.value,
                          });
                        }}
                        // required
                      />
                      <div className="text-danger">{errors.remark}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="status"
                        name="status"
                        value={state.customerFileProcessJobDTOList[0].status}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            status: e.target.value,
                          });
                        }}
                        readOnly
                      />
                      {/*<Dropdown
                        value={input.status}
                        options={statusData}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            status: e.target.value,
                          });
                        }}
                        placeholder="Select a Status"
                      />*/}
                      <div className="text-danger">{errors.status}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="revenue" className="form-label">
                        Revenue
                      </label>
                      <InputNumber
                        inputId="horizontal"
                        value={input.revenue}
                        id="revenue"
                        name="revenue"
                        style={{ width: "100%" }}
                        onValueChange={(e) =>
                          setInput({
                            ...input,
                            revenue: e.target.value,
                          })
                        }
                        showButtons
                        buttonLayout="horizontal"
                        step={1}
                        decrementButtonClassName="p-button-danger"
                        incrementButtonClassName="p-button-success"
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                        mode="currency"
                        currency="INR"
                        minFractionDigits={0}
                        min={0}
                        required
                      />
                      <div className="text-danger">{errors.revenue}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="jobUser" className="form-label">
                        Job User
                      </label>
                      <MultiSelect
                        display="chip"
                        value={input.jobUser} // Assuming input.jobUser is an array of selected values
                        style={{ width: "100%" }}
                        options={usersData}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            jobUser: e.value, // Set selected job users
                          });
                          // setUserIdData(e.target.value.userId);
                          const userIds = e.value.map(
                            (selectedOption) =>
                              usersData.find(
                                (user) => user.value === selectedOption
                              ).userId
                          );
                          setUserIdData(userIds);
                        }}
                      />
                      <div className="text-danger mt-1">{errors.jobUser}</div>
                    </div>
                  </div>
                  {/*estimatedCostData && (
                    <div className="col-md-6">
                      <label htmlFor="estimatedCost" className="form-label">
                        Estimated Cost As Per Selected Users:{" "}
                        {estimatedCostData.estimatedCost <= 0 &&
                        input.jobUser.length !== 0
                          ? state.customerFileProcessJobDTOList[0].totalCost
                          : estimatedCostData.estimatedCost.toFixed(0)}
                      </label>
                    </div>
                  )*/}
                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
