import React, { useEffect, useState } from "react";
import { getEmployeeReportList } from "../../services/dailyReporting";
import { errorMessage } from "../../utils/alertMessage";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../constants/FormateDate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { getUserProfile } from "../../services/userProfile";

const DailyReports = () => {
  const { state } = useLocation();
  let userId = state?.userId;

  const [courseDepartment, setCourseDepartment] = useState([]);
  const [businessDevelopment, setBusinessDevelopment] = useState([]);
  const [digitalMarketing, setDigitalMarketing] = useState([]);
  const [loanBackOffice, setLoanBackOffice] = useState([]);
  const [projectReportSales, setProjectReportSales] = useState([]);
  const [teleCallingTeamLoan, setTeleCallingTeamLoan] = useState([]);
  const [projectReportBackOffice, setProjectReportBackOffice] = useState([]);
  const [accountantDepartment, setAccountantDepartment] = useState([]);

  const [profile, setProfile] = useState({});

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setProfile(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
    const fetchData = async () => {
      try {
        const response = await getEmployeeReportList({ employeeId: userId });
        if (!response.data.error) {
          const Res = response.data;

          // Filter Course_Department reports
          const courseDepartmentReports = Res.filter(
            (report) => report.reportType === "Course_Department"
          );
          const businessDevelopmentReports = Res.filter(
            (report) => report.reportType === "Business_Development"
          );
          const digitalMarketingReports = Res.filter(
            (report) => report.reportType === "Digital_Marketing"
          );
          const loanBackOfficeReports = Res.filter(
            (report) => report.reportType === "Loan_Back_Office"
          );
          const projectReportSalesReports = Res.filter(
            (report) => report.reportType === "Project_Report_Sales"
          );

          const teleCallingTeamLoanReports = Res.filter(
            (report) => report.reportType === "Tele_calling_Team_Loan"
          );
          const projectReportBackOfficeReports = Res.filter(
            (report) => report.reportType === "Project_Report_Back_Office"
          );
          const accountantDepartmentReportsReports = Res.filter(
            (report) => report.reportType === "Account_Department"
          );

          // Set state
          setCourseDepartment(courseDepartmentReports);
          setBusinessDevelopment(businessDevelopmentReports);
          setDigitalMarketing(digitalMarketingReports);
          setLoanBackOffice(loanBackOfficeReports);
          setProjectReportSales(projectReportSalesReports);
          setTeleCallingTeamLoan(teleCallingTeamLoanReports);
          setProjectReportBackOffice(projectReportBackOfficeReports);
          setAccountantDepartment(accountantDepartmentReportsReports);
        }
      } catch (err) {
        errorMessage();
      }
    };
    fetchData();
  }, []);

  //  const message =
  //   `*${
  //     rowData.user.firstName + rowData.user.lastName
  //   } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //   `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //   `No. of New Leads: *${rowData.data.no_of_new_lead}*\n` +
  //   `No. of Calls: *${rowData.data.no_of_call}*\n` +
  //   `No. of Sales: *${rowData.data.no_of_sale}*`;
  // const constructMessage = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `No. of New Leads: *${rowData.data.no_of_new_lead}*\n` +
  //     `No. of Calls: *${rowData.data.no_of_call}*\n` +
  //     `No. of Sales: *${rowData.data.no_of_sale}*`
  //   );
  // };
  // const constructMessage2 = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `No. Of Old Broker Meet: *${rowData.data.no_of_old_broker_meet}*\n` +
  //     `No. Of New Broker Meet: *${rowData.data.no_of_new_broker_meet}*\n ` +
  //     `No. Of Proposal Recd Loan Project Report Other: *${rowData.data.no_of_proposal_recd_loan_project_report_other}*\n` +
  //     `Loan Amount Lacs: *${rowData.data.loan_amount_lacs}*\n` +
  //     `Project Report Other Fees: *${rowData.data.project_report_other_fees}*\n` +
  //     `New Booking Amount: *${rowData.data.new_booking_amount}*\n` +
  //     `Old Balance Recovered: *${rowData.data.old_balance_recovered}*\n` +
  //     `Total Record: *${rowData.data.total_record}*\n`
  //   );
  // };

  // const constructMessage3 = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `Long Video: *${rowData.data.long_video}*\n` +
  //     `Shorts: *${rowData.data.shorts}*\n ` +
  //     `Blog: *${rowData.data.blog}*\n`
  //   );
  // };

  // const constructMessage4 = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `New File Login: *${rowData.data.new_file_login}*\n` +
  //     `Amount (Lacs): *${rowData.data.new_file_login_amount_lacs}*\n ` +
  //     `File Sanction: *${rowData.data.file_sanction}*\n` +
  //     `Amount (Lacs): *${rowData.data.file_sanction_amount_lacs}*\n` +
  //     `File Disburse: *${rowData.data.file_disburse}*\n ` +
  //     `Amount (Lacs): *${rowData.data.file_disburse_amount_lacs}*\n`
  //   );
  // };

  // const constructMessage5 = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `No. Of New Lead: *${rowData.data.no_of_new_lead}*\n` +
  //     `No. Of New Project Report Software Booked: *${rowData.data.no_of_new_project_report_software_booked}*\n ` +
  //     `New Booking Amount: *${rowData.data.new_booking_amount}*\n` +
  //     `Old Balance Recovered: *${rowData.data.old_balance_recovered}*\n` +
  //     `Total Collection: *${rowData.data.total_collection}*\n`
  //   );
  // };

  // const constructMessage6 = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `No. Of New Call: *${rowData.data.no_of_new_call}*\n` +
  //     `No. Of Follow Up: *${rowData.data.no_of_follow_up}*\n ` +
  //     `No. Of File Login: *${rowData.data.no_of_file_login}*\n` +
  //     `No. Of File Sanction: *${rowData.data.no_of_file_sanction}*\n` +
  //     `No. Of File Disburse: *${rowData.data.no_of_file_disburse}*\n`
  //   );
  // };
  // const constructMessage7 = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `No. Of Project Report Prepared: *${rowData.data.no_of_project_report_prepared}*\n`
  //   );
  // };
  // const constructMessage8 = (rowData) => {
  //   return (
  //     `*${
  //       rowData.user.firstName + rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `No. Of Bill Raised: *${rowData.data.no_of_bill_raised}*\n` +
  //     `Amount: *${rowData.data.amount}*\n`
  //   );
  // };

  // const constructMessage = (rowData, template) => {
  //   return (
  //     `*${rowData.user.firstName} ${
  //       rowData.user.lastName
  //     } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
  //     `Created Date: *${formatDate(rowData.createdDate)}*\n` +
  //     `*Today's Commitment :* \n` +
  //     `${rowData.data.todayCommitment} \n` +
  //     `*Today's Achievement :* \n` +
  //     template(rowData.data)
  //   );
  // };

  const constructMessage = (rowData, template) => {
    let message =
      `*${rowData.user.firstName} ${
        rowData.user.lastName
      } - ${rowData.reportType.replace(/_/g, " ")}*\n` +
      `Created Date: *${formatDate(rowData.createdDate)}*\n`;

    if (rowData.data.todayCommitment) {
      message += `*Today's Commitment:* \n${rowData.data.todayCommitment} \n`;
    }

    // Check if rowData.data has any keys other than todayCommitment
    const dataKeys = Object.keys(rowData.data).filter(
      (key) =>
        key !== "todayCommitment" &&
        rowData.data[key] !== null &&
        rowData.data[key] !== undefined &&
        rowData.data[key] !== ""
    );
    if (dataKeys.length > 0) {
      message += `*Today's Achievement:* \n` + template(rowData.data);
    }

    return message;
  };

  const templates = {
    template1: (data) => {
      return (
        `No. Of New Leads: *${data.no_of_new_lead}*\n` +
        `No. Of Calls: *${data.no_of_call}*\n` +
        `No. Of Sales: *${data.no_of_sale}*`
      );
    },
    template2: (data) => {
      return (
        `No. Of Old Broker Meet: *${data.no_of_old_broker_meet}*\n` +
        `No. Of New Broker Meet: *${data.no_of_new_broker_meet}*\n` +
        `No. Of Proposal Recd Loan Project Report Other: *${data.no_of_proposal_recd_loan_project_report_other}*\n` +
        `Loan Amount Lacs: *${data.loan_amount_lacs}*\n` +
        `Project Report Other Fees: *${data.project_report_other_fees}*\n` +
        `New Booking Amount: *${data.new_booking_amount}*\n` +
        `Old Balance Recovered: *${data.old_balance_recovered}*\n` +
        `Total Record: *${data.total_record}*\n`
      );
    },
    template3: (data) => {
      return (
        `Long Video: *${data.long_video}*\n` +
        `Shorts: *${data.shorts}*\n` +
        `Blog: *${data.blog}*\n`
      );
    },
    template4: (data) => {
      return (
        `New File Login: *${data.new_file_login}*\n` +
        `Amount (Lacs): *${data.new_file_login_amount_lacs}*\n` +
        `File Sanction: *${data.file_sanction}*\n` +
        `Amount (Lacs): *${data.file_sanction_amount_lacs}*\n` +
        `File Disburse: *${data.file_disburse}*\n ` +
        `Amount (Lacs): *${data.file_disburse_amount_lacs}*\n`
      );
    },
    template5: (data) => {
      return (
        `No. Of New Lead: *${data.no_of_new_lead}*\n` +
        `No. Of New Project Report Software Booked: *${data.no_of_new_project_report_software_booked}*\n` +
        `New Booking Amount: *${data.new_booking_amount}*\n` +
        `Old Balance Recovered: *${data.old_balance_recovered}*\n` +
        `Total Collection: *${data.total_collection}*\n`
      );
    },
    template6: (data) => {
      return (
        `No. Of New Call: *${data.no_of_new_call}*\n` +
        `No. Of Follow Up: *${data.no_of_follow_up}*\n` +
        `No. Of File Login: *${data.no_of_file_login}*\n` +
        `No. Of File Sanction: *${data.no_of_file_sanction}*\n` +
        `No. Of File Disburse: *${data.no_of_file_disburse}*\n`
      );
    },
    template7: (data) => {
      return `No. Of Project Report Prepared: *${data.no_of_project_report_prepared}*\n`;
    },
    template8: (data) => {
      return (
        `No. Of Bill Raised: *${data.no_of_bill_raised}*\n` +
        `Amount: *${data.amount}*\n`
      );
    },
    // Add more templates as needed
  };

  const updateMessage = (rowData, template) => {
    const constructedMessage = constructMessage(rowData, template);
    sendMessage(constructedMessage);
  };

  const sendMessage = (message) => {
    const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const courseDepartmentTotal = (field) => {
    let total = 0;
    for (let item of courseDepartment) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };
  const businessDevelopmentTotal = (field) => {
    let total = 0;
    for (let item of businessDevelopment) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };

  const digitalMarketingTotal = (field) => {
    let total = 0;
    for (let item of digitalMarketing) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };

  const loanBackOfficeTotal = (field) => {
    let total = 0;
    for (let item of loanBackOffice) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };

  const projectReportSalesTotal = (field) => {
    let total = 0;
    for (let item of projectReportSales) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };

  const teleCallingTeamLoanTotal = (field) => {
    let total = 0;
    for (let item of teleCallingTeamLoan) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };

  const projectReportBackOfficeTotal = (field) => {
    let total = 0;
    for (let item of projectReportBackOffice) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };

  const accountantDepartmentTotal = (field) => {
    let total = 0;
    for (let item of accountantDepartment) {
      if (field !== "todayCommitment") {
        let value = parseInt(item.data[field], 10);
        if (isNaN(value)) {
          value = 0;
        }
        total += value;
      }
    }
    return total;
  };

  const footerGroupCourseDepartment = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={courseDepartmentTotal("no_of_new_lead")} />
        <Column footer={courseDepartmentTotal("no_of_call")} />
        <Column footer={courseDepartmentTotal("no_of_sale")} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupBusinessDevelopment = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={businessDevelopmentTotal("no_of_new_broker_meet")} />
        <Column footer={businessDevelopmentTotal("no_of_old_broker_meet")} />
        <Column
          footer={businessDevelopmentTotal(
            "no_of_proposal_recd_loan_project_report_other"
          )}
        />
        <Column footer={businessDevelopmentTotal("loan_amount_lacs")} />
        <Column
          footer={businessDevelopmentTotal("project_report_other_fees")}
        />
        <Column footer={businessDevelopmentTotal("new_booking_amount")} />
        <Column footer={businessDevelopmentTotal("old_balance_recovered")} />
        <Column footer={businessDevelopmentTotal("total_record")} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupDigitalMarketing = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={digitalMarketingTotal("long_video")} />
        <Column footer={digitalMarketingTotal("shorts")} />
        <Column footer={digitalMarketingTotal("blog")} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupLoanBackOffice = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={loanBackOfficeTotal("new_file_login")} />
        <Column footer={loanBackOfficeTotal("new_file_login_amount_lacs")} />
        <Column footer={loanBackOfficeTotal("file_sanction")} />
        <Column footer={loanBackOfficeTotal("file_sanction_amount_lacs")} />
        <Column footer={loanBackOfficeTotal("file_disburse")} />
        <Column footer={loanBackOfficeTotal("file_disburse_amount_lacs")} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupProjectReportSales = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={projectReportSalesTotal("no_of_new_lead")} />
        <Column
          footer={projectReportSalesTotal(
            "no_of_new_project_report_software_booked"
          )}
        />
        <Column footer={projectReportSalesTotal("new_booking_amount")} />
        <Column footer={projectReportSalesTotal("old_balance_recovered")} />
        <Column footer={projectReportSalesTotal("total_collection")} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupTeleCallingTeamLoan = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={teleCallingTeamLoanTotal("no_of_new_call")} />
        <Column footer={teleCallingTeamLoanTotal("no_of_follow_up")} />
        <Column footer={teleCallingTeamLoanTotal("no_of_file_login")} />
        <Column footer={teleCallingTeamLoanTotal("no_of_file_sanction")} />
        <Column footer={teleCallingTeamLoanTotal("no_of_file_disburse")} />
      </Row>
    </ColumnGroup>
  );

  const footerGroupProjectReportBackOffice = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column
          footer={projectReportBackOfficeTotal("no_of_project_report_prepared")}
        />
      </Row>
    </ColumnGroup>
  );

  const footerGroupAccountantDepartment = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" colSpan={2} />
        <Column footer={accountantDepartmentTotal("no_of_bill_raised")} />
        <Column footer={accountantDepartmentTotal("amount")} />
      </Row>
    </ColumnGroup>
  );

  // const handleSubmit = async (event, id) => {
  //   event.preventDefault();

  //   sendEmployeeReport(id)
  //     .then((response) => {
  //       if (!response.data.error) {
  //         if (response.data.success === false) {
  //           errorMessage(response.data.message);
  //         } else {
  //           successMessage(response.data.message);
  //           //  navigate("/customer-file/list", {
  //           //    state: state.clientInfo.id,
  //           //  });
  //         }
  //       } else {
  //         // errorMessage(response.data.errors.errors[0].message);
  //       }
  //     })
  //     .catch((err) => {
  //       errorMessage();
  //     });
  // };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            {profile?.role === "ROLE EMPLOYEE" &&
              courseDepartment.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Course Department Reports</h5>
                    <DataTable
                      value={courseDepartment}
                      paginator
                      footerColumnGroup={footerGroupCourseDepartment}
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Created Date"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.createdDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Today Commitment"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span className="word-wrap">
                            {rowData.data.todayCommitment}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. of New Leads"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_new_lead}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. of Calls"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_call}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. of Sales"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_sale}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Send Report"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            <button
                              onClick={(e) =>
                                updateMessage(rowData, templates.template1)
                                // handleSubmit(e, rowData.id)
                              }
                              className="btn"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: 15, color: "green" }}
                              ></i>
                            </button>
                            <Link
                              className="btn"
                              to={"/daily-reporting"}
                              // state={item}
                              state={{
                                item: "Course_Department",
                                userId,
                                edit: rowData,
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{ fontSize: 15, color: "blue" }}
                              ></i>
                            </Link>
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              )}
            {profile?.role === "ROLE EMPLOYEE" &&
              businessDevelopment.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Business Development Reports</h5>
                    <DataTable
                      value={businessDevelopment}
                      paginator
                      footerColumnGroup={footerGroupBusinessDevelopment}
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Created Date"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.createdDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Today Commitment"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span className="word-wrap">
                            {rowData.data.todayCommitment}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of New Broker Meet"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_new_broker_meet}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of Old Broker Meet"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_old_broker_meet}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of Proposal Recd Loan Project Report Other"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            {
                              rowData.data
                                .no_of_proposal_recd_loan_project_report_other
                            }
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Loan Amount (Lacs)"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.loan_amount_lacs}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Project Report Other Fees"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.project_report_other_fees}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="New Booking Amount"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.new_booking_amount}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Old Balance Recovered"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.old_balance_recovered}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Total Record"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.total_record}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Send Report"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            <button
                              onClick={(e) =>
                                updateMessage(rowData, templates.template2)
                                // handleSubmit(e, rowData.id)
                              }
                              className="btn"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: 15, color: "green" }}
                              ></i>
                            </button>
                            <Link
                              className="btn"
                              to={"/daily-reporting"}
                              // state={item}
                              state={{
                                item: "Business_Development",
                                userId,
                                edit: rowData,
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{ fontSize: 15, color: "blue" }}
                              ></i>
                            </Link>
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              )}
            {profile?.role === "ROLE EMPLOYEE" &&
              digitalMarketing.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Digital Marketing Reports</h5>
                    <DataTable
                      value={digitalMarketing}
                      paginator
                      footerColumnGroup={footerGroupDigitalMarketing}
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Created Date"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.createdDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Today Commitment"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span className="word-wrap">
                            {rowData.data.todayCommitment}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Long Video"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.long_video}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Shorts"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => <span>{rowData.data.shorts}</span>}
                      ></Column>
                      <Column
                        field="name"
                        header="Blog"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => <span>{rowData.data.blog}</span>}
                      ></Column>
                      <Column
                        field="name"
                        header="Send Report"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            <button
                              onClick={(e) =>
                                updateMessage(rowData, templates.template3)
                                // handleSubmit(e, rowData.id)
                              }
                              className="btn"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: 15, color: "green" }}
                              ></i>
                            </button>
                            <Link
                              className="btn"
                              to={"/daily-reporting"}
                              // state={item}
                              state={{
                                item: "Digital_Marketing",
                                userId,
                                edit: rowData,
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{ fontSize: 15, color: "blue" }}
                              ></i>
                            </Link>
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              )}
            {profile?.role === "ROLE EMPLOYEE" && loanBackOffice.length > 0 && (
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Loan Back Office Reports</h5>
                  <DataTable
                    value={loanBackOffice}
                    paginator
                    footerColumnGroup={footerGroupLoanBackOffice}
                    paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    rowsPerPageOptions={[10, 20, 50]}
                    dataKey="id"
                    className="p-datatable-customers client-table-design"
                    resizableColumns
                    columnResizeMode="fit"
                  >
                    <Column
                      field="name"
                      header="Created Date"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{formatDate(rowData.createdDate)}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Today Commitment"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span className="word-wrap">
                          {rowData.data.todayCommitment}
                        </span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="New File Login"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.new_file_login}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Amount (Lacs)"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.new_file_login_amount_lacs}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="File Sanction"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_sanction}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Amount (Lacs)"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_sanction_amount_lacs}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="File Disburse"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_disburse}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Amount (Lacs)"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>{rowData.data.file_disburse_amount_lacs}</span>
                      )}
                    ></Column>
                    <Column
                      field="name"
                      header="Send Report"
                      resizeable={false}
                      className="product-category table-design"
                      body={(rowData) => (
                        <span>
                          <button
                            onClick={(e) =>
                              updateMessage(rowData, templates.template4)
                              // handleSubmit(e, rowData.id)
                            }
                            className="btn"
                          >
                            <i
                              className="bi bi-whatsapp"
                              style={{ fontSize: 15, color: "green" }}
                            ></i>
                          </button>
                          <Link
                            className="btn"
                            to={"/daily-reporting"}
                            // state={item}
                            state={{
                              item: "Loan_Back_Office",
                              userId,
                              edit: rowData,
                            }}
                          >
                            <i
                              className="bi bi-pencil"
                              style={{ fontSize: 15, color: "blue" }}
                            ></i>
                          </Link>
                        </span>
                      )}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            )}
            {profile?.role === "ROLE EMPLOYEE" &&
              projectReportSales.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Project Report Sales Reports</h5>
                    <DataTable
                      value={projectReportSales}
                      paginator
                      footerColumnGroup={footerGroupProjectReportSales}
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Created Date"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.createdDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Today Commitment"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span className="word-wrap">
                            {rowData.data.todayCommitment}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of New Lead"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_new_lead}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of New Project Report Software Booked"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            {
                              rowData.data
                                .no_of_new_project_report_software_booked
                            }
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="New Booking Amount"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.new_booking_amount}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Old Balance Recovered"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.old_balance_recovered}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Total Collection"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.total_collection}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Send Report"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            <button
                              onClick={(e) =>
                                updateMessage(rowData, templates.template5)
                                // handleSubmit(e, rowData.id)
                              }
                              className="btn"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: 15, color: "green" }}
                              ></i>
                            </button>
                            <Link
                              className="btn"
                              to={"/daily-reporting"}
                              // state={item}
                              state={{
                                item: "Project_Report_Sales",
                                userId,
                                edit: rowData,
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{ fontSize: 15, color: "blue" }}
                              ></i>
                            </Link>
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              )}
            {profile?.role === "ROLE EMPLOYEE" &&
              teleCallingTeamLoan.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Tele Calling Team Loan Reports
                    </h5>
                    <DataTable
                      value={teleCallingTeamLoan}
                      paginator
                      footerColumnGroup={footerGroupTeleCallingTeamLoan}
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Created Date"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.createdDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Today Commitment"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span className="word-wrap">
                            {rowData.data.todayCommitment}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of New Call"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_new_call}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of Follow Up"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_follow_up}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of File Login"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_file_login}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of File Sanction"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_file_sanction}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of File Disburse"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_file_disburse}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Send Report"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            <button
                              onClick={(e) =>
                                updateMessage(rowData, templates.template6)
                                // handleSubmit(e, rowData.id)
                              }
                              className="btn"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: 15, color: "green" }}
                              ></i>
                            </button>
                            <Link
                              className="btn"
                              to={"/daily-reporting"}
                              // state={item}
                              state={{
                                item: "Tele_calling_Team_Loan",
                                userId,
                                edit: rowData,
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{ fontSize: 15, color: "blue" }}
                              ></i>
                            </Link>
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              )}

            {profile?.role === "ROLE EMPLOYEE" &&
              projectReportBackOffice.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Project Report Back Office Reports
                    </h5>
                    <DataTable
                      value={projectReportBackOffice}
                      paginator
                      footerColumnGroup={footerGroupProjectReportBackOffice}
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Created Date"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.createdDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Today Commitment"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span className="word-wrap">
                            {rowData.data.todayCommitment}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of Project Report Prepared"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            {rowData.data.no_of_project_report_prepared}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Send Report"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            <button
                              onClick={(e) =>
                                updateMessage(rowData, templates.template7)
                                // handleSubmit(e, rowData.id)
                              }
                              className="btn"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: 15, color: "green" }}
                              ></i>
                            </button>
                            <Link
                              className="btn"
                              to={"/daily-reporting"}
                              // state={item}
                              state={{
                                item: "Project_Report_Back_Office",
                                userId,
                                edit: rowData,
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{ fontSize: 15, color: "blue" }}
                              ></i>
                            </Link>
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              )}
            {profile?.role === "ROLE_ACCOUNTANT" &&
              accountantDepartment.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Accountant Department Reports
                    </h5>
                    <DataTable
                      value={accountantDepartment}
                      paginator
                      footerColumnGroup={footerGroupAccountantDepartment}
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="name"
                        header="Created Date"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.createdDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Today Commitment"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span className="word-wrap">
                            {rowData.data.todayCommitment}
                          </span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="No. Of Bill Raised"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{rowData.data.no_of_bill_raised}</span>
                        )}
                      ></Column>
                      <Column
                        field="name"
                        header="Amount"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => <span>{rowData.data.amount}</span>}
                      ></Column>
                      <Column
                        field="name"
                        header="Send Report"
                        resizeable={false}
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            <button
                              onClick={(e) =>
                                updateMessage(rowData, templates.template8)
                                // handleSubmit(e, rowData.id)
                              }
                              className="btn"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: 15, color: "green" }}
                              ></i>
                            </button>
                            <Link
                              className="btn"
                              to={"/daily-reporting"}
                              // state={item}
                              state={{
                                item: "Account_Department",
                                userId,
                                edit: rowData,
                              }}
                            >
                              <i
                                className="bi bi-pencil"
                                style={{ fontSize: 15, color: "blue" }}
                              ></i>
                            </Link>
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default DailyReports;
