import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import {
  addCustomerFile,
  updateCustomerFile,
} from "../../services/customerFile";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { addNewLead, updateNewLead } from "../../services/newLead";

export default function NewLeadCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();

  let userId = state?.userDataInfo;
  let newLeadData = state?.rowData;
 
  const [input, setInput] = useState({
    dateOfLead: new Date(newLeadData?.dateOfLead) || new Date(),
    clientName: newLeadData?.clientName || "",
    clientMobile: newLeadData?.clientMobile || "",
    typeOfWork: newLeadData?.typeOfWork || "",
    followUpDate: new Date(newLeadData?.followUpDate) || new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    remarks: newLeadData?.remarks || "",
  });
  const [errors, setErrors] = useState({
    dateOfLead: "",
    clientName: "",
    clientMobile: "",
    typeOfWork: "",
    followUpDate: "",
    remarks: "",
  });

   const validateForm = () => {
     let isValid = true;
     const newErrors = { ...errors };

     if (input.clientName.trim() === "") {
       newErrors.clientName = "Please enter the client's name.";
       isValid = false;
     } else {
       newErrors.clientName = "";
     }

     if (input.clientMobile.trim() === "") {
       newErrors.clientMobile = "Please enter the client's mobile number.";
       isValid = false;
     } else {
       newErrors.clientMobile = "";
     }

     if (input.typeOfWork.trim() === "") {
       newErrors.typeOfWork = "Please enter the type of work.";
       isValid = false;
     } else {
       newErrors.typeOfWork = "";
     }

     if (input.remarks.trim() === "") {
       newErrors.remarks = "Please enter remarks.";
       isValid = false;
     } else {
       newErrors.remarks = "";
     }

     if (input.followUpDate <= input.dateOfLead) {
       newErrors.followUpDate =
         "Follow-up date must be later than the date of lead.";
       isValid = false;
     } else {
       newErrors.followUpDate = "";
     }

     setErrors(newErrors);
     return isValid;
   };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (newLeadData?.id) {
        let data = {
          id: newLeadData?.id,
          dateOfLead: new Date(input.dateOfLead).toISOString(),
          clientName: input.clientName,
          clientMobile: input.clientMobile,
          typeOfWork: input.typeOfWork,
          followUpDate: new Date(input.followUpDate).toISOString(),
          remarks: input.remarks,
          status: "Open",
          followUpBy: {
            id: userId,
          },
        };
        updateNewLead(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/new-lead");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      } else {
        let data = {
          dateOfLead: new Date(input.dateOfLead).toISOString(),
          clientName: input.clientName,
          clientMobile: input.clientMobile,
          typeOfWork: input.typeOfWork,
          followUpDate: new Date(input.followUpDate).toISOString(),
          remarks: input.remarks,
          status: "Open",
          followUpBy: {
            id: userId,
          },
        };
        addNewLead(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/new-lead");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  New Lead {newLeadData?.id ? "Update" : "Create"}
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="clientName" className="form-label">
                        Name Of Customer
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="clientName"
                        name="clientName"
                        value={input.clientName}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            clientName: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.clientName}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="clientMobile" className="form-label">
                        Mobile No.
                      </label>
                      <InputText
                        type="number"
                        value={input.clientMobile}
                        id="clientMobile"
                        name="clientMobile"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            clientMobile: e.target.value,
                          })
                        }
                        required
                      />
                      <div className="text-danger">{errors.clientMobile}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="dateOfLead" className="form-label">
                        Date Of Lead
                      </label>
                      <Calendar
                        id="dateOfLead"
                        name="dateOfLead"
                        value={input.dateOfLead}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            dateOfLead: e.target.value,
                          });
                        }}
                        showIcon
                        
                        disabled={newLeadData?.id ? true :false }
                        required={newLeadData?.id ? false: true }
                      />
                      <div className="text-danger">{errors.dateOfLead}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="followUpDate" className="form-label">
                        Follow Up Date
                      </label>
                      <Calendar
                        id="followUpDate"
                        name="followUpDate"
                        value={input.followUpDate}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            followUpDate: e.target.value,
                          });
                        }}
                        minDate={
                          new Date(
                            input.dateOfLead.getTime() + 24 * 60 * 60 * 1000
                          )
                        }
                        showIcon
                        required
                      />
                      <div className="text-danger">{errors.followUpDate}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="typeOfWork" className="form-label">
                        Type Of Work
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="typeOfWork"
                        name="typeOfWork"
                        value={input.typeOfWork}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            typeOfWork: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.typeOfWork}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="remarks" className="form-label">
                        Remarks
                      </label>
                      <InputTextarea
                        type="text"
                        style={{ width: "100%" }}
                        id="remarks"
                        name="remarks"
                        value={input.remarks}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            remarks: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.remarks}</div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
