import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getEmployeeReportTypeTemplate = () => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/employee-report-type",
    headers: getHeaders(),
  });
};

export const getEmployeeReportTemplate = () => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/employee-report-template",
    headers: getHeaders(),
  });
};

export const addEmployeeReportTemplate = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/employee-report-template",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateEmployeeReportTemplate = (data) => {
  return axios({
    method: "put",
    url: BASE_URL + "api/secure/v1/employee-report-template",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const sendEmployeeReport = (reportId) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/send-employee-report/" + reportId,
    headers: getHeaders(),
  });
};