import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getServicesTypeFrequency = () => {
  return axios({
    method: "get",
    url:
      BASE_URL +
      "api/secure/v1/mvn-job-service-type-frequency",
    headers: getHeaders(),
  });
};