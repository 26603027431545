import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link, useLocation } from "react-router-dom";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getClientById } from "../../services/client";
import { getCustomerFile } from "../../services/customerFile";
import { Table } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function CustomerFileList() {
  const { state } = useLocation();
  let clientId = state;
  const [products, setProducts] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [layout, setLayout] = useState("list");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const fetchCustomerFile = (clientId) => {
    getCustomerFile(clientId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setProducts(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const fetchClient = (clientId) => {
    getClientById(clientId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setClientInfo(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchClient(clientId);
    fetchCustomerFile(clientId);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((p) =>
      p.fileName.toLowerCase().includes(searchQueryValue.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  const renderListItem = (data) => {
    return (
      // <div className="col-12 backgroundDesign">
      //   <div className="product-list-item">
      //     <div className="product-list-detail">
      //       <div className="product-name">{data.fileName}</div>
      //       <span className="product-name">
      //         {data.totalCost ? "₹ " + data.totalCost : ""}
      //       </span>
      //     </div>
      //     <div className="product-list-detail">
      //       <span className="product-name">
      //         <i className="pi pi-file product-category-icon blueColorIcon"></i>
      //         {data.fileNumber}
      //       </span>
      //     </div>
      //     <div className="product-list-action">
      //       <span className="product-price"></span>
      //       <div className="mb-2">
      //         <Link
      //           to={"/customer-file-job/list"}
      //           state={{ clientId, fileId: data.id }}
      //           className="move-btn move-btn-vu center-text"
      //         >
      //           Jobs <i className="bi bi-box-arrow-in-right"></i>
      //         </Link>
      //       </div>
      //       <Link
      //         to={"/customer-file/edit"}
      //         state={{ data, clientInfo }}
      //         className="move-btn move-btn-re center-text"
      //         style={{ width: "100%" }}
      //       >
      //         Edit <i className="bi bi-pencil-square"></i>
      //       </Link>
      //     </div>
      //   </div>
      // </div>
      <div className="col-12 ">
        <Table className="backgroundDesign">
          <tr>
            <td
              style={{
                width: "50%",
                fontSize: "13px",
                padding: "0px 5px",
                fontWeight: 500,
              }}
            >
              {data.fileName}
            </td>
            <td style={{ width: "40%", fontSize: "13px", fontWeight: 500 }}>
              {data.fileNumber}
            </td>
            <td style={{ width: "10%" }}>
              <div className="rowDiv">
                <div style={{ marginRight: 10 }}>
                  <Link
                    to={"/customer-file-job/list"}
                    state={{ clientId, fileId: data.id }}
                    className="move-btn-small move-btn-vu center-text"
                  >
                    <i className="bi bi-box-arrow-in-right"></i>
                  </Link>
                </div>
                {/*<div style={{ marginRight: 10 }}>
                  <Link
                    to={"/customer-file/edit"}
                    state={data.id}
                    className="move-btn-small move-btn-re center-text"
                  >
                    <i className="bi bi-pencil-square"></i>
                  </Link>
                </div>*/}
              </div>
            </td>
          </tr>
        </Table>
      </div>
    );
  };

  const renderGridItem = (data) => {
    return (
      // <div className="col-12 md:col-4 backgroundDesign">
      //   <div className="product-grid-item">
      //     <div>
      //       <div className="product-name">{data.fileName}</div>
      //       <i className="pi pi-file product-category-icon blueColorIcon"></i>
      //       <span className="product-category">{data.fileNumber}</span>
      //     </div>
      //     <div>
      //       <span className="product-category">
      //         {data.totalCost ? "₹ " + data.totalCost : ""}
      //       </span>
      //     </div>

      //     <div className="product-grid-item-bottom mt-2">
      //       <span className="product-price"></span>
      //       <Link
      //         to={"/customer-file-job/list"}
      //         state={{ clientId, fileId: data.id }}
      //         className="move-btn move-btn-vu center-text"
      //       >
      //         Jobs <i className="bi bi-box-arrow-in-right"></i>
      //       </Link>

      //       <Link
      //         to={"/customer-file/edit"}
      //         state={data}
      //         className="move-btn move-btn-re center-text"
      //       >
      //         Edit <i className="bi bi-pencil-square"></i>
      //       </Link>
      //     </div>
      //   </div>
      // </div>
      <div className="col-12">
        <div className="col-12 backgroundDesign">
          <div className="rowDiv">
            <div className="col-10">
              <p className="p-class">
                <i className="pi pi-file product-category-icon blueColorIcon"></i>
                {data.fileName}
              </p>
            </div>

            <div className="col-2">
              <Link
                to={"/customer-file-job/list"}
                state={{ clientId, fileId: data.id }}
                className="move-btn-small move-btn-vu center-text"
              >
                <i className="bi bi-box-arrow-in-right"></i>
              </Link>
            </div>
          </div>

          <div className="rowDiv">
            <div className="col-6">
              <p className="p-class">
                <i className="pi pi-file-word product-category-icon blueColorIcon"></i>
                {data.fileNumber}
              </p>
            </div>
            <div className="col-4">
              <p className="p-class">
                {data.perJobCost ? "₹ " + data.perJobCost : ""}
                {/**  {data.totalCost ? "₹ " + data.totalCost : ""} */}
              </p>
            </div>
            {/*<div className="col-2">
              <Link
                to={"/customer-file/edit"}
                state={data.id}
                className="move-btn-small move-btn-re center-text"
              >
                <i className="bi bi-pencil-square"></i>
              </Link>
            </div>*/}
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    const layout = windowWidth > 460 ? "list" : "grid";
    return (
      <div className="grid grid-nogutter">
        <div className="col-6" style={{ textAlign: "left", margin: "5px" }}>
          <InputText
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="col-6" style={{ display: "none", textAlign: "right" }}>
          <DataViewLayoutOptions
            layout={layout}
            onChange={(e) => setLayout(e.value)}
          />
        </div>
        {layout === "list" ? (
          <div className="col-12">
            <Table className="table-design-heading">
              <tr>
                <th style={{ width: "50%", padding: "5px 5px" }}>File Name</th>
                <th style={{ width: "40%", padding: "5px 1px" }}>
                  File Number
                </th>
                <th style={{ width: "10%", padding: "5px 1px" }}>Action</th>
              </tr>
            </Table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const header = renderHeader();

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Customer Info</h5>

                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">Name:</p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientInfo?.customerDTO.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Authorized Person:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientInfo?.customerDTO.authorizedPerson}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Auth Person Mobile:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientInfo?.customerDTO.authorizedPersonMobile}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Profile:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading">
                          {clientInfo?.customerDTO.profile}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-container">
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Address:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading text-break">
                          {clientInfo?.customerDTO.address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex-item-left">
                    <div class="flex-container-2">
                      <div class="flex-item-left">
                        <p className="info-label info-label-heading">
                          Email Id:
                        </p>
                      </div>
                      <div class="flex-item-right">
                        <p className="info info-heading text-break">
                          {clientInfo?.customerDTO.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Customer File List</h5>
                  {/*<div className="col-md-2" style={{ alignSelf: "center" }}>
                    <Link
                      to="/customer-file/create"
                      className="move-btn move-btn-su center-text"
                      state={{ clientInfo }}
                    >
                      Create
                    </Link>
                </div>*/}
                </div>
                <div className="dataview-demo">
                  <div className="card">
                    {/*<DataView
                      value={filteredProducts || products}
                      layout={layout}
                      header={header}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
              />*/}
                    <DataTable
                      value={filteredProducts || products}
                      paginator
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="fileName"
                        header="File Name"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="fileNumber"
                        header="File Number"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="data.customerFileProcessJobDTOList[0].status"
                        header="Action"
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>
                            {
                              <div className="rowDiv">
                                <Link
                                  to={"/customer-file-job/list"}
                                  state={{ clientId, fileId: rowData.id }}
                                  className="move-btn-small move-btn-vu center-text"
                                >
                                  <i className="bi bi-box-arrow-in-right"></i>
                                </Link>
                                <div style={{ marginLeft: 10 }}>
                                  <Link
                                    to={"/customer-file-edit"}
                                    state={{ clientId, rowData }}
                                    className="move-btn-small move-btn-re center-text"
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </Link>
                                </div>
                              </div>
                            }
                          </span>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
