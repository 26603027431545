import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link, useLocation } from "react-router-dom";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getClientById } from "../../services/client";
import { getCustomerFile } from "../../services/customerFile";
import { Table } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../constants/FormateDate";
import { getUserProfile } from "../../services/userProfile";
import { getNewLead } from "../../services/newLead";
import logoBig from "../../assets/logo1.png";
import "jspdf-autotable";
import jsPDF from "jspdf";

export default function NewLeadList() {

  const [newLeadData, setNewLeadData] = useState([]);
  const [userDataInfo, setUserDataInfo] = useState([]);

  const fetchUserData = () => {
   getUserProfile()
     .then((response) => {
       if (!response.data.error) {
         const Res = response.data;
         setUserDataInfo(Res.id)
         const data = {
           endDate: "",
           startDate: "",
           recordType: "",
           followUpBy: null,
           status: null,
         };

         fetchClient(data);
       }
     })
     .catch((err) => {
       console.error("Error:", err);
     })
    };

  const fetchClient = (data) => {
    getNewLead(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setNewLeadData(
            Res.sort((a, b) =>
              a.clientName.trim().localeCompare(b.clientName.trim())
            )
          );
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    
    fetchUserData();
  }, []);

  //   const handleSearchInputChange = (event) => {
  //     const searchQueryValue = event.target.value;
  //     const filteredProducts = products.filter((p) =>
  //       p.fileName.toLowerCase().includes(searchQueryValue.toLowerCase())
  //     );
  //     setFilteredProducts(filteredProducts);
  //     setSearchQuery(searchQueryValue);
  //   };

  const downloadPDF = (data, type) => {
    const doc = new jsPDF("landScape");
    const logoImg = new Image();
    logoImg.src = logoBig;

    doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
    doc.setFontSize(20);
    doc.text("New Lead", 220, 15);

    doc.autoTable({
      head: [
        [
          "Date Of Lead",
          "Name Of Customer",
          "Mobile No.",
          "Type Of Work",
          "Follow Up Date",
          "Remark",
        ],
      ],
      body: data.map((item) => [
        formatDate(item.dateOfLead),
        item.clientName,
        item.clientMobile,
        item.typeOfWork,
        formatDate(item.followUpDate),
        item.remarks,
      ]),
      startY: 25,
      theme: "grid",
      headStyles: {},
      styles: {
        overflow: "linebreak",
        fontSize: 10,
      },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        2: { cellWidth: 30 },
        3: { cellWidth: 40 },
        4: { cellWidth: 30 },
      },
    });

    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      doc.save("New-Lead.pdf");
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">New Lead List</h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 10,
                      // justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <div className="col-md-2" style={{ alignSelf: "center" }}>
                      <Link
                        to="/new-lead-create"
                        className="move-btn move-btn-su center-text"
                        state={{ userDataInfo }}
                      >
                        Create
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 20,
                          marginTop: 10,
                        }}
                      >
                        <i
                          onClick={() => downloadPDF(newLeadData, "download")}
                          className="pi pi-download"
                          style={{
                            fontSize: "2rem",
                            color: "#1e439b",
                            cursor: "pointer",
                          }}
                        ></i>
                        <i
                          onClick={() => downloadPDF(newLeadData, "print")}
                          className="pi pi-print"
                          style={{
                            fontSize: "2rem",
                            color: "#ff0101",
                            cursor: "pointer",
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dataview-demo">
                  <div className="card">
                    <DataTable
                      value={newLeadData}
                      paginator
                      paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      rowsPerPageOptions={[10, 20, 50]}
                      dataKey="id"
                      className="p-datatable-customers client-table-design"
                      resizableColumns
                      columnResizeMode="fit"
                    >
                      <Column
                        field="dateOfLead"
                        header="Date Of Lead"
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.dateOfLead)}</span>
                        )}
                      ></Column>
                      <Column
                        field="clientName"
                        header="Name Of Customer"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="clientMobile"
                        header="Mobile No."
                        className="product-category table-design"
                        // body={(rowData) => (
                        //   <span>
                        //     {
                        //       <Link
                        //         to={"/customer-file-job/list"}
                        //         state={{ clientId, fileId: rowData.id }}
                        //         className="move-btn-small move-btn-vu center-text"
                        //       >
                        //         <i className="bi bi-box-arrow-in-right"></i>
                        //       </Link>
                        //     }
                        //   </span>
                        // )}
                      ></Column>
                      <Column
                        field="typeOfWork"
                        header="Type Of Work"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="followUpDate"
                        header="Follow Up Date"
                        className="product-category table-design"
                        body={(rowData) => (
                          <span>{formatDate(rowData.followUpDate)}</span>
                        )}
                      ></Column>
                      <Column
                        field="remarks"
                        header="Remark"
                        className="product-category table-design"
                      ></Column>
                      <Column
                        field="action"
                        header="Action"
                        className="product-category table-design"
                        body={(rowData) => (
                          <div className="rowDiv">
                            <div style={{ marginRight: 10 }}>
                              <Link
                                to="/client/create"
                                className="move-btn-small move-btn-vu center-text"
                                state={{ rowData }}
                              >
                                <i className="bi bi-box-arrow-in-right"></i>
                              </Link>
                            </div>
                            <div style={{ marginRight: 10 }}>
                              <Link
                                to="/new-lead-create"
                                className="move-btn-small move-btn-re center-text"
                                state={{ userDataInfo, rowData }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </Link>
                            </div>
                          </div>
                        )}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
