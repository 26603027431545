import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { errorMessage, successMessage } from "../../../utils/alertMessage";
import { addJobs, updateJobs } from "../../../services/JobMasterJobs";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { getServicesType } from "../../../services/ServicesType";
import { getServicesTypeFrequency } from "../../../services/FrequencyType";

export default function JobCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [input, setInput] = useState({
    name: state?.name || "",
    description: state?.description || "",
    // jobMaxCharge: state?.jobMaxCharge || "",
    // jobMinCharge: state?.jobMinCharge || "",
    jobDate: new Date(state?.jobDate) || "",
    jobDays: state?.jobDays || "",
    type: state?.type || "",
    service: state?.service || "",
    frequency: state?.frequency || "",
  });
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    // jobMaxCharge: "",
    // jobMinCharge: "",
    jobDate: "",
    jobDays: "",
    type: "",
    service:"",
    frequency:""
  });

  const [jobsData, setJobsData] = useState([
    { label: "Compliance Date", value: "compliance_date" },
    { label: "No. of days", value: "no_of_days" },
  ]);

  const [serviceData, setServiceData] = useState();

  const [frequencyData, setFrequencyData] = useState();

  const fetchServiceAndFrequency = () => {
    getServicesType()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const services = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setServiceData(services);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    getServicesTypeFrequency()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const frequency = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setFrequencyData(frequency);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchServiceAndFrequency();
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Example: Validate jobMaxCharge format
    // const emailRegex = /^\S+@\S+\.\S+$/;
    if (input.name === "") {
      newErrors.name = "Invalid name format";
      isValid = false;
    } else {
      newErrors.name = "";
    }
    // if (input.jobMaxCharge === "") {
    //   newErrors.jobMaxCharge = "Invalid jobMaxCharge format";
    //   isValid = false;
    // } else {
    //   newErrors.jobMaxCharge = "";
    // }
    if (input.description === "") {
      newErrors.description = "Invalid description format";
      isValid = false;
    } else {
      newErrors.description = "";
    }
    if (input.type === "") {
      newErrors.type = "Invalid type format";
      isValid = false;
    } else {
      newErrors.type = "";
    }
    if (input.service === "") {
      newErrors.service = "Please Choose Service";
      isValid = false;
    } else {
      newErrors.service = "";
    }
    if (input.frequency === "") {
      newErrors.frequency = "Please Choose frequency";
      isValid = false;
    } else {
      newErrors.frequency = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (state) {
        let data = {
          id: state.id,
          name: input.name,
          description: input.description,
          // jobMaxCharge: input.jobMaxCharge,
          // jobMinCharge: input.jobMinCharge,
          jobDate:
            input.type === "compliance_date"
              ? new Date(input.jobDate).toISOString()
              : "",
          jobDays: input.type !== "compliance_date" ? input.jobDays : "",
          type: input.type,
          service: input.service,
          frequency: input.frequency,
        };
        updateJobs(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/job-master/jobs/list");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      } else {
        let data = {
          name: input.name,
          description: input.description,
          // jobMaxCharge: input.jobMaxCharge,
          // jobMinCharge: input.jobMinCharge,
          jobDate:
            input.type === "compliance_date"
              ? new Date(input.jobDate).toISOString()
              : "",
          jobDays: input.jobDays,
          type: input.type,
          service: input.service,
          frequency: input.frequency,
        };

        addJobs(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/job-master/jobs/list");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Jobs {state ? "Update" : "Create"}
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="score" className="form-label">
                        Job Title
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="name"
                        name="name"
                        value={input.name}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            name: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.name}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="score" className="form-label">
                        Job Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description"
                        name="description"
                        value={input.description}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            description: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.description}</div>
                    </div>
                  </div>
                  {/*<div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Job Max Charge
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="jobMaxCharge"
                        name="jobMaxCharge"
                        value={input.jobMaxCharge}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            jobMaxCharge: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.jobMaxCharge}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Job Min Charge
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="jobMinCharge"
                        name="jobMinCharge"
                        value={input.jobMinCharge}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            jobMinCharge: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                      </div>*/}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="score" className="form-label">
                        Job Time Type
                      </label>
                      <Dropdown
                        value={input.type}
                        options={jobsData}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            type: e.target.value,
                          });
                        }}
                        placeholder="Select a time type"
                      />
                      <div className="text-danger mt-1">{errors.type}</div>
                    </div>

                    {input.type === "no_of_days" && (
                      <div className="col-md-6">
                        <label htmlFor="score" className="form-label">
                          Job Days
                        </label>
                        <InputNumber
                          inputId="horizontal"
                          value={input.jobDays}
                          id="jobDays"
                          name="jobDays"
                          style={{ width: "100%" }}
                          onValueChange={(e) =>
                            setInput({
                              ...input,
                              jobDays: e.target.value,
                            })
                          }
                          showButtons
                          buttonLayout="horizontal"
                          step={1}
                          decrementButtonClassName="p-button-danger"
                          incrementButtonClassName="p-button-success"
                          incrementButtonIcon="pi pi-plus"
                          decrementButtonIcon="pi pi-minus"
                          mode="decimal"
                          minFractionDigits={0}
                          min={0}
                          required
                        />
                      </div>
                    )}

                    {input.type === "compliance_date" && (
                      <div className="col-md-6">
                        <label htmlFor="jobDate" className="form-label">
                          Job Date
                        </label>
                        <Calendar
                          id="jobDate"
                          name="jobDate"
                          value={input.jobDate}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              jobDate: e.target.value,
                            });
                          }}
                          minDate={new Date()}
                          showIcon
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="users" className="form-label">
                        Services
                      </label>

                      <Dropdown
                        value={input.service}
                        style={{ width: "100%" }}
                        options={serviceData}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            service: e.target.value,
                          });
                        }}
                        placeholder="Select a Service"
                      />
                      <div className="text-danger mt-1">{errors.service}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="users" className="form-label">
                        Frequency
                      </label>

                      <Dropdown
                        display="chip"
                        value={input.frequency}
                        style={{ width: "100%" }}
                        options={frequencyData}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            frequency: e.target.value,
                          });
                        }}
                        placeholder="Select a Time Period"
                      />
                      <div className="text-danger mt-1">{errors.frequency}</div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
