import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { addClient } from "../../services/client";
import { InputText } from "primereact/inputtext";
import { validatePhone } from "../../components/Regex";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { getServicesType } from "../../services/ServicesType";
import { getServicesTypeFrequency } from "../../services/FrequencyType";
import { getMVNJob } from "../../services/mvnJob";

export default function ClientCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();

  let newLeadData = state?.rowData;

  const [input, setInput] = useState({
    name: newLeadData?.clientName || "",
    profile: newLeadData?.typeOfWork || "",
    authorizedPerson: newLeadData?.clientName || "",
    authorizedPersonMobile: newLeadData?.clientMobile || "",
    address: state?.address || "",
    email: state?.email || "",
  });
  const [errors, setErrors] = useState({
    name: "",
    profile: "",
    authorizedPerson: "",
    authorizedPersonMobile: "",
    address: "",
    email: "",
  });
  const [serviceError, setServiceError] = useState("");
  const [frequencyError, setFrequencyError] = useState("");
  const [taskError, setTaskError] = useState("");

  const [taskDataForFields, setTaskDataForFields] = useState([]);
  const [serviceData, setServiceData] = useState();
  const [frequencyData, setFrequencyData] = useState();

  const fetchTaskDataForField = (service, frequency, index) => {
    if (service && frequency) {
      getMVNJob({ service, frequency })
        .then((response) => {
          if (!response.data.error) {
            const Res = response.data;
            const updatedTaskDataForFields = [...taskDataForFields];
            updatedTaskDataForFields[index] = Res;
            setTaskDataForFields(updatedTaskDataForFields);

            // Update the additionalFields state with the new task values
            setAdditionalFields((prevFields) => {
              const updatedFields = [...prevFields];
              updatedFields[index].task = Res.map((item) => item);
              return updatedFields;
            });
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    }
  };

  const fetchServiceAndFrequency = () => {
    getServicesType()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const service = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setServiceData(service);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    getServicesTypeFrequency()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const frequency = Res.map((item) => ({
            label: item,
            value: item,
          }));
          setFrequencyData(frequency);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchServiceAndFrequency();
  }, []);

  const [additionalFields, setAdditionalFields] = useState([
    { service: "", frequency: "", task: "", fees: "", status: false },
  ]);

  const handleAddField = () => {
    setAdditionalFields([
      ...additionalFields,
      { service: "", frequency: "", task: "", fees: "", status: false },
    ]);

    setTaskDataForFields([...taskDataForFields, []]);
  };

  const handleDeleteField = (index) => {
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);

    const updatedTaskDataForFields = [...taskDataForFields];
    updatedTaskDataForFields.splice(index, 1);
    setTaskDataForFields(updatedTaskDataForFields);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Example: Validate email format
    // const emailRegex = /^\S+@\S+\.\S+$/;
    if (input.name === "") {
      newErrors.name = "Please Enter Name";
      isValid = false;
    } else {
      newErrors.name = "";
    }

    if (!validatePhone.test(input.authorizedPersonMobile)) {
      newErrors.authorizedPersonMobile = "Please Enter 10 Digit Mobile Number";
      isValid = false;
    } else {
      newErrors.authorizedPersonMobile = "";
    }
    // Add more validation logic for other fields as needed
    additionalFields.map((item) =>
      item.service === ""
        ? setServiceError("Please Select Services")
        : setServiceError("")
    );
    additionalFields.map((item) =>
      item.frequency === ""
        ? setFrequencyError("Please Select Frequency")
        : setFrequencyError("")
    );
    additionalFields.map((item) =>
      item.task === "" ? setTaskError("Please Select task") : setTaskError("")
    );

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      let data = {
        customerDTO: {
          name: input.name,
          authorizedPerson: input.authorizedPerson,
          authorizedPersonMobile: input.authorizedPersonMobile,
          profile: input.profile,
          address: input.address,
          email: input.email,
        },
        customerFileJobDTOList: [
          ...additionalFields.map((field) => ({
            customerFile: {
              fileName: field.service + "_" + field.frequency,
              fileNumber: "",
            },
            mvnJobList: field.task.map((item) => ({
              mvnJob: item,
              jobCost: field.fees,
              status: true,
            })),
          })),
        ],
      };

      addClient(data)
        .then((response) => {
          if (!response.data.error) {
            if (response.data.success === false) {
              errorMessage(response.data.message);
            } else {
              successMessage(response.data.message);
              navigate("/client/list");
            }
          } else {
            // errorMessage(response.data.errors.errors[0].message);
          }
        })
        .catch((err) => {
          errorMessage();
        });
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Customer {state ? "Update" : "Create"}
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="name" className="form-label">
                        Customer Name
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="name"
                        name="name"
                        value={input.name}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            name: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.name}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="profile" className="form-label">
                        Profile
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="profile"
                        name="profile"
                        value={input.profile}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            profile: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.profile}</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="authorizedPerson" className="form-label">
                        Authorized Person
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="authorizedPerson"
                        name="authorizedPerson"
                        value={input.authorizedPerson}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            authorizedPerson: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">
                        {errors.authorizedPerson}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label
                        htmlFor="authorizedPersonMobile"
                        className="form-label"
                      >
                        Authorized Person Mobile
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="authorizedPersonMobile"
                        name="authorizedPersonMobile"
                        value={input.authorizedPersonMobile}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            authorizedPersonMobile: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">
                        {errors.authorizedPersonMobile}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email Id
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="email"
                        name="email"
                        value={input.email}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            email: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.email}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="address"
                        name="address"
                        value={input.address}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            address: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.address}</div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Task Create</h5>
                      <div className="row mb-3 table-design-heading display-table">
                        <div className="col-md-3">
                          <label htmlFor="users" className="form-label">
                            Services
                          </label>
                        </div>
                        <div className="col-md-2">
                          <label htmlFor="users" className="form-label">
                            Frequency
                          </label>
                        </div>
                        <div className="col-md-5">
                          <label htmlFor="task" className="form-label">
                            Task
                          </label>
                        </div>
                        <div className="col-md-1">
                          <label htmlFor="fees" className="form-label">
                            Fees
                          </label>
                        </div>
                        <div className="col-md-1 add-btn">
                          <div className="column-flex">
                            <label
                              htmlFor="fees"
                              className="form-label hide-add"
                            >
                              Action
                            </label>
                          </div>
                        </div>
                      </div>
                      {additionalFields.map((field, index) => (
                        <div>
                          <div key={index} className="row mb-3">
                            <div className="col-md-3">
                              <label
                                htmlFor={`service-${index}`}
                                className="form-label hide-class"
                              >
                                Services
                              </label>
                              <Dropdown
                                value={field.service}
                                options={serviceData}
                                onChange={(e) => {
                                  const updatedFields = [...additionalFields];
                                  updatedFields[index].service = e.target.value;
                                  setAdditionalFields(updatedFields);
                                }}
                                style={{ width: "100%" }}
                                placeholder="Select a Service"
                                disabled={
                                  additionalFields[index].status === true
                                }
                              />
                            </div>
                            <div className="col-md-2">
                              <label
                                htmlFor={`frequency-${index}`}
                                className="form-label hide-class"
                              >
                                Frequency
                              </label>
                              <Dropdown
                                value={field.frequency}
                                options={frequencyData}
                                onChange={(e) => {
                                  const updatedFields = [...additionalFields];
                                  updatedFields[index].frequency =
                                    e.target.value;
                                  setAdditionalFields(updatedFields);
                                  fetchTaskDataForField(
                                    additionalFields[index].service,
                                    e.target.value,
                                    index
                                  );
                                }}
                                style={{ width: "100%" }}
                                placeholder="Select a Time Period"
                                disabled={
                                  additionalFields[index].status === true
                                }
                              />
                            </div>
                            <div className="col-md-5">
                              <label
                                htmlFor={`task-${index}`}
                                className="form-label hide-class"
                              >
                                Task
                              </label>
                              <MultiSelect
                                display="chip"
                                style={{ width: "100%" }}
                                optionLabel="name"
                                options={taskDataForFields[index] || []}
                                value={field.task}
                                onChange={(e) => {
                                  const updatedFields = [...additionalFields];
                                  updatedFields[index].task = e.target.value;
                                  setAdditionalFields(updatedFields);
                                }}
                                disabled={
                                  additionalFields[index].status === true
                                }
                              />
                            </div>
                            <div className="col-md-1">
                              <label
                                htmlFor={`fees-${index}`}
                                className="form-label hide-class"
                              >
                                Fees
                              </label>
                              <InputText
                                type="number"
                                value={field.fees}
                                onChange={(e) => {
                                  const updatedFields = [...additionalFields];
                                  updatedFields[index].fees = e.target.value;
                                  setAdditionalFields(updatedFields);
                                  if (updatedFields[index].fees !== "") {
                                    updatedFields[index].status = true;
                                    setAdditionalFields(updatedFields);
                                  }
                                }}
                                style={{ width: "100%" }}
                                required
                              />
                            </div>
                            <div className="col-md-1 add-btn rowDiv">
                              {additionalFields[index].status === true && (
                                <div className="delete-btn">
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteField(index)}
                                  >
                                    <i className="bi bi-trash"></i>
                                  </button>
                                </div>
                              )}
                              {index === additionalFields.length - 1 &&
                                additionalFields.length > 0 &&
                                additionalFields[additionalFields.length - 1]
                                  .status && (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleAddField}
                                  >
                                    <i className="bi bi-plus"></i>
                                  </button>
                                )}
                            </div>
                          </div>

                          <div key={index} className="row">
                            {serviceError && (
                              <div className="col-md-3">
                                <label
                                  htmlFor={`service-${index}`}
                                  className="form-label"
                                >
                                  {serviceError}
                                </label>
                              </div>
                            )}

                            {frequencyError && (
                              <div className="col-md-2">
                                <label
                                  htmlFor={`service-${index}`}
                                  className="form-label"
                                >
                                  {frequencyError}
                                </label>
                              </div>
                            )}

                            {taskError && (
                              <div className="col-md-3">
                                <label
                                  htmlFor={`service-${index}`}
                                  className="form-label"
                                >
                                  {taskError}
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-md-12 text-center">
                    {/*additionalFields[additionalFields.length - 1].status && (
                      <button
                        type="button"
                        className="move-btn move-btn-su"
                        onClick={handleAddField}
                      >
                        Add <i className="bi bi-plus"></i>
                      </button>
                    )*/}
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
