import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
// import {
//   deleteRankPredictor,
// } from "../../services/rankPredictor";
import { errorMessage } from "../../../utils/alertMessage";
import styled from "styled-components";
import { getUser } from "../../../services/userMasterUser";
import { replaceUnderscoresWithSpaces } from "../../../components/Regex";

const CustomTitle = ({ title }) => (
  <div>
    <div>
      <div
        data-tag="allowRowEvents"
        style={{
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses",
        }}
      >
        {title}
      </div>
    </div>
  </div>
);

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <React.Fragment>
    <TextField
      id="search"
      type="text"
      placeholder="Search Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </React.Fragment>
);

export default function RoleList() {
  const [data, setData] = useState([]);
  const [pending, setPending] = useState(true);
  const columns = useMemo(
    () => [
      // {
      //   name: "Id",
      //   width: "70px",
      //   sortable: true,
      //   cell: (row) => <CustomTitle title={row.id} />,
      //   selector: (row) => row.id,
      // },
      {
        name: "Full Name",
        width: "120px",
        sortable: true,
        cell: (row) => (
          <CustomTitle title={row.firstName + " " + row.lastName} />
        ),
        selector: (row) => row.firstName + " " + row.lastName,
      },
      {
        name: "Email",
        width: "140px",
        sortable: true,
        cell: (row) => <CustomTitle title={row.email} />,
        selector: (row) => row.email,
      },
      {
        name: "Mobile Number",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.mobile} />,
        selector: (row) => row.mobile,
      },
      {
        name: "Role",
        sortable: true,
        width: "120px",
        cell: (row) => (
          <CustomTitle title={replaceUnderscoresWithSpaces(row.role)} />
        ),
        selector: (row) => row.role,
      },
      {
        name: "User Login Id",
        sortable: true,
        width: "150px",
        cell: (row) => <CustomTitle title={row.userLoginId} />,
        selector: (row) => row.userLoginId,
      },

      {
        name: "Edit",
        selector: (row) => (
          <Link
            to={"/rankPredictor/edit"}
            state={row}
            className="move-btn move-btn-re center-text"
          >
            Edit <i className="bi bi-pencil-square"></i>
          </Link>
        ),
      },
      {
        name: "Delete",
        selector: (row) => (
          <Link
            type="button"
            // onClick={() => handleRemove(row.id)}
            onClick={() => console.log("delete", row.id)}
            className="btn btn-danger"
          >
            <i className="bi bi-trash"></i>
          </Link>
        ),
      },
    ],
    []
  );

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = data.filter(
    (item) =>
      item.firstName &&
      item.firstName.toString().includes(filterText.toString())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);


   const fetchData = () => {
     getUser()
       .then((response) => {
         if (!response.data.error) {
           setData(response.data);
         } else {
           errorMessage("Invalid Credentials");
         }
       })
       .catch((err) => {
         errorMessage();
       })
       .finally(() => setPending(false));
   };

  useEffect(() => fetchData(), []);

  // const handleRemove = (id) => {
  //   deleteRankPredictor(id).then((result) => {
  //     if (result.data.error === false) {
  //       successMessage();
  //       fetchData();
  //     } else {
  //       errorMessage();
  //     }
  //   });
  // };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">User Role</h5>
                <DataTable
                  columns={columns}
                  data={filteredItems}
                  progressPending={pending}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
