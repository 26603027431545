// import menu from '../constants/menu.json';
import adminMenu from "../constants/adminMenu.json";
import taskReport from "../constants/taskReport.json";
import SubMenu from "../components/SubMenu";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/authSlice";
import React, { useEffect, useState } from "react";
import { getEmployeeReportRole } from "../services/dailyReporting";

const SideBar = ({ userProfileData }) => {
  const dispatch = useDispatch();
  const handleToggleSidebar = () => dispatch(toggleSidebar());
  const role = userProfileData?.role;
  const userId = userProfileData?.id;

  const [reportingRole, setReportingRole] = useState([]);

  const fetchEmployeeReportRole = (role) => {
    getEmployeeReportRole(role)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setReportingRole(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchEmployeeReportRole(role);
  }, [role]);

  const MenuData = role === "ROLE_ADMIN" && adminMenu;
  const TaskReport = taskReport;

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {role === "ROLE_ADMIN" && (
          <>
            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link className={`nav-link collapsed`} to={"client/list"}>
                <i className={"bi bi-person"} />
                <span>{"Customer"}</span>
              </Link>
            </li>

            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"process/running/list"}
              >
                <i className={"bi bi-buildings"} />
                <span>{"Task Status"}</span>
              </Link>
            </li>

            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link className={`nav-link collapsed`} to={"employee-time-sheet"}>
                <i className={"bi bi-person-vcard"} />
                <span>{"Employee TimeSheet"}</span>
              </Link>
            </li>

            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"job-master/jobs/list"}
              >
                <i className={"bi bi-clipboard-data-fill"} />
                <span>{"Task Master"}</span>
              </Link>
            </li>
            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link className={`nav-link collapsed`} to={"task-allocation"}>
                <i className={"bi bi-clipboard-data-fill"} />
                <span>{"Resource Allocation"}</span>
              </Link>
            </li>

            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"user-master/user/list"}
              >
                <i className={"bi bi-person-check-fill"} />
                <span>{"User"}</span>
              </Link>
            </li>

            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"task-profitability-statement"}
              >
                <i className={"bi bi-person-check-fill"} />
                <span>{"Task Profitability Statement"}</span>
              </Link>
            </li>
          </>
        )}
        <li
          className="nav-item"
          sx={{ color: "#012970" }}
          onClick={handleToggleSidebar}
        >
          <Link className={`nav-link collapsed`} to={"profile"}>
            <i className={"bi bi-person-vcard"} />
            <span>{"Profile"}</span>
          </Link>
        </li>
        {(role === "ROLE EMPLOYEE" || role === "ROLE_ACCOUNTANT") &&
          reportingRole &&
          reportingRole.map((item, index) => (
            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"daily-reporting"}
                // state={item}
                state={{ item, userId }}
              >
                <i className={"bi bi-person-vcard"} />
                <span>{item.replace(/_/g, " ")}</span>
              </Link>
            </li>
          ))}
        {(role === "ROLE EMPLOYEE" || role === "ROLE_ACCOUNTANT") && (
          <li
            className="nav-item"
            sx={{ color: "#012970" }}
            onClick={handleToggleSidebar}
          >
            <Link
              className={`nav-link collapsed`}
              to={"daily-reports"}
              state={{ userId }}
            >
              <i className={"bi bi-person-vcard"} />
              <span>{"Daily Reports"}</span>
            </Link>
          </li>
        )}
        {role === "ROLE_ADMIN" && (
          <React.Fragment>
            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"admin-daily-reports"}
                state={{ userId }}
              >
                <i className={"bi bi-person-vcard"} />
                <span>{"Daily Reports"}</span>
              </Link>
            </li>
            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"template-config-view"}
                state={null}
              >
                <i className={"bi bi-person-vcard"} />
                <span>{"Template Config"}</span>
              </Link>
            </li>
            <li
              className="nav-item"
              sx={{ color: "#012970" }}
              onClick={handleToggleSidebar}
            >
              <Link
                className={`nav-link collapsed`}
                to={"new-lead"}
                state={{ userId }}
              >
                <i className={"bi bi-person-vcard"} />
                <span>{"New Lead"}</span>
              </Link>
            </li>
          </React.Fragment>
        )}
        {role === "ROLE_ADMIN" &&
          TaskReport.map((item, index) => (
            <SubMenu key={index} item={item} index={index} />
          ))}
        {/*role === "ROLE_ADMIN" &&
          MenuData.map((item, index) => (
            <SubMenu key={index} item={item} index={index} />
          ))*/}
      </ul>
    </aside>
  );
};
export default SideBar;
