import React from "react";
import { useLocation } from "react-router-dom";
import ProjectReportBackOffice from "./ProjectReportBackOffice";
import AccountDepartment from "./AccountDepartment";
import TeleCallingTeamLoan from "./TeleCallingTeamLoan";
import ProjectReportSales from "./ProjectReportSales";
import LoanBackOffice from "./LoanBackOffice";
import BusinessDevelopment from "./BusinessDevelopment";
import DigitalMarketing from "./DigitalMarketing";
import CourseDepartment from "./CourseDepartment";

const componentsMap = {
  Account_Department: AccountDepartment,
  Tele_calling_Team_Loan: TeleCallingTeamLoan,
  Project_Report_Sales: ProjectReportSales,
  Project_Report_Back_Office: ProjectReportBackOffice,
  Loan_Back_Office: LoanBackOffice,
  Business_Development: BusinessDevelopment,
  Digital_Marketing: DigitalMarketing,
  Course_Department: CourseDepartment,
};

const DailyReporting = () => {
  const { state } = useLocation();
  let item = state?.item;
  let userId = state?.userId;
  let edit = state?.edit;
  const ComponentToRender =
    componentsMap[item] || (() => <div>No matching component found</div>);

  return <ComponentToRender state={{ item, userId, edit }} />;
};

export default DailyReporting;
