import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getJobProcessList } from "../../services/process";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { getStatus } from "../../services/getStatus";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import "../../assets/css/AccordionDemo.css";

import { getDocument, uploadDocument } from "../../services/document";
import { formatDate } from "../../constants/FormateDate";
import { postJobProcess } from "../../services/jobProcess";
import { Calendar } from "primereact/calendar";
import { uploadInvoice } from "../../services/invoice";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function JobProcessList({ userProfileData, isFilterVisible }) {
  const [products, setProducts] = useState([]);
  const [layout, setLayout] = useState("list");
  const [statusData, setStatusData] = useState();
  const [uploadedData, setUploadedData] = useState([]);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [displayInvoiceNo, setDisplayInvoiceNo] = useState(false);
  const [displayFileUpload, setDisplayFileUpload] = useState(false);
  const [displayFileDownload, setDisplayFileDownload] = useState(false);
  const [displayRemarks, setDisplayRemarks] = useState(false);
  const [displayInvoiceUpload, setDisplayInvoiceUpload] = useState(false);
  const [showData, setShowData] = useState({});
  const [remarksData, setRemarksData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [fileName, setFileName] = useState("");
  const [processId, setProcessId] = useState(null);
  const [processJobId, setProcessJobId] = useState(null);
  const [invoiceUploadError, setInvoiceUploadError] = useState(null);

  const userProfile = userProfileData;

  const fetchCustomerJobProcess = (data, userProfile) => {
    getJobProcessList(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data.reverse();
          const userLoginId = userProfile.userLoginId;
          const userDataFiltered = Res.filter(
            (item) => item.jobUser.user.userLoginId === userLoginId
          );
          const productsWithIndex = userDataFiltered.map((product, index) => ({
            ...product,
            serialNumber: index + 1,
          }));
          setProducts(
            productsWithIndex.sort((a, b) =>
              a.customerFileProcessJob.customerFileProcess.customerFile.customer.name
                .trim()
                .localeCompare(
                  b.customerFileProcessJob.customerFileProcess.customerFile.customer.name.trim()
                )
            )
          );
          
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const fetchStatus = (data) => {
    getStatus(data)
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const Res = response.data;
          const transformedStatusData = Res.map((item) => ({
            label: item === "TO_BE_STARTED" ? "PENDING" : item, // Change label for status with id 2
            value: item,
          }));
          setStatusData(transformedStatusData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const fetchUploadDocument = (processId, processJobId) => {
    getDocument(processId, processJobId)
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const Res = response.data;
          setUploadedData(Res);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  useEffect(() => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: [],
      users: [],
    };
    fetchCustomerJobProcess(data, userProfile);
    fetchStatus("CUSTOMER_FILE_PROCESS");
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    processId !== null && fetchUploadDocument(processId, processJobId);
  }, [processId, processJobId]);

  const onClick = (data) => {
    setShowData(data);
    setProcessId(data?.customerFileProcessJob?.customerFileProcess?.id); // customerFileProcess?.id form admin dashboard api
    setProcessJobId(data?.customerFileProcessJob?.id); // customerFileProcessJobDTOList[0]?.id
    setDisplayResponsive(true);
  };

  const onHide = () => {
    setDisplayResponsive(false);
  };
  const onClickInvoiceNo = (data) => {
    setShowData(data);
    setProcessId(data?.customerFileProcessJob?.customerFileProcess?.id);
    setProcessJobId(data?.customerFileProcessJob?.id);
    setDisplayInvoiceNo(true);
  };

  const onHideInvoiceNo = () => {
    setDisplayInvoiceNo(false);
  };
  const onClickFileUpload = (data) => {
    // setFileUploadData(data);
    setProcessId(data?.customerFileProcessJob?.customerFileProcess?.id);
    setProcessJobId(data?.customerFileProcessJob?.id);
    setDisplayFileUpload(true);
  };
  const onHideFileUpload = () => {
    setFileName("");
    setDisplayFileUpload(false);
  };

  const onClickInvoiceUpload = (data) => {
    // setFileUploadData(data);
    setProcessId(data?.customerFileProcessJob?.customerFileProcess?.id);
    setProcessJobId(data?.customerFileProcessJob?.id);
    setDisplayInvoiceUpload(true);
  };

  const onHideInvoiceUpload = () => {
    setFileName("");
    setInvoiceUploadError("");
    setDisplayInvoiceUpload(false);
  };
  const onClickFileDownload = (data) => {
    setProcessId(data?.customerFileProcessJob?.customerFileProcess?.id);
    setProcessJobId(data?.customerFileProcessJob?.id);
    setDisplayFileDownload(true);
  };

  const onHideFileDownload = () => {
    setDisplayFileDownload(false);
  };

  const onClickRemarks = (data) => {
    setRemarksData(data);
    setDisplayRemarks(true);
  };
  const onHideRemarks = () => {
    setDisplayRemarks(false);
  };

  useEffect(() => {
    setInput({
      remark: "",
      status: showData?.customerFileProcessJob?.status || "",
    });
  }, [showData]);

  const [input, setInput] = useState({});

  const [inputData, setInputData] = useState({
    startDate: new Date(),
    endDate: new Date(),
    types: [],
    status: [],
    users: [],
    invoiceNo: "",
    workingHour: "",
  });

  useEffect(() => {
    const data = inputData;
    fetchCustomerJobProcess(data, userProfile);
  }, [inputData, userProfile]);

  const [jobsData, setJobsData] = useState([
    { label: "Compliance Date", value: "compliance_date" },
    { label: "No. of days", value: "no_of_days" },
  ]);

  const resetFilters = () => {
    setInputData({
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: [],
      users: [],
    });
    setSearchQuery("");
  };

  const [errors, setErrors] = useState({
    remark: "",
    status: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    let data = showData;

    data.customerFileProcessJob.status = input.status;
    data.taskStatus = input.status; // user status change
    data.customerFileProcessJob.remarks = input.remark;
    data.customerFileProcessJob.invoiceNo = input.invoiceNo;
    data.workHour = input.workingHour;

    postJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            setDisplayResponsive(false);
            errorMessage(response.data.message);
          } else {
            setDisplayResponsive(false);
            successMessage(response.data.message);
            const data = {
              startDate: new Date(),
              endDate: new Date(),
              types: [],
              status: [],
              users: [],
            };
            fetchCustomerJobProcess(data, userProfile);
          }
        }
      })
      .catch((err) => {
        setDisplayResponsive(false);
        errorMessage();
      });
  };

  const handleSubmitInvoiceNo = async (event) => {
    event.preventDefault();

    let data = showData;

    //  data.customerFileProcessJob.status = input.status;
    //  data.taskStatus = input.status; // user status change
    //  data.customerFileProcessJob.remarks = input.remark;
    data.customerFileProcessJob.invoiceNo = input.invoiceNo;
    //  data.workHour = input.workingHour;

    postJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            setDisplayInvoiceNo(false);
            errorMessage(response.data.message);
          } else {
            setDisplayInvoiceNo(false);
            successMessage(response.data.message);
            const data = {
              startDate: new Date(),
              endDate: new Date(),
              types: [],
              status: [],
              users: [],
            };
            fetchCustomerJobProcess(data, userProfile);
          }
        }
      })
      .catch((err) => {
        setDisplayInvoiceNo(false);
        errorMessage();
      });
  };

  const handleFileUpload = (event) => {
    event.preventDefault();
    const fileInput = event.target.querySelector('input[type="file"]');
    const fileData = fileInput.files[0];

    if (!fileData) {
      console.error("No file selected");
      return;
    }

    uploadDocument(processId, processJobId, fileData)
      .then((response) => {
        // handle the response
        onHideFileUpload();
        successMessage("File Upload Successfully");

        fetchUploadDocument(processId, processJobId);
      })
      .catch((error) => {
        // handle errors
        errorMessage("File Upload Failed");
      });
  };

  const handleInvoiceUpload = (event) => {
    event.preventDefault();
    const fileInput = event.target.querySelector('input[type="file"]');
    const invoice = fileInput.files[0];

    if (!invoice) {
      console.error("No file selected");
      setInvoiceUploadError("Please Upload Invoice File");
      return;
    }

    uploadInvoice(processId, processJobId, invoice)
      .then((response) => {
        // handle the response
        onHideInvoiceUpload();
        setSearchQuery("");
        successMessage("Invoice Upload Successfully");
        const data = {
          startDate: new Date(),
          endDate: new Date(),
          types: [],
          status: [],
          users: [],
        };
        fetchCustomerJobProcess(data, userProfile);

        // fetchUploadDocument(processId, processJobId);
      })
      .catch((error) => {
        // handle errors
        errorMessage("Invoice Upload Failed");
      });
  };

  const handleFileDownload = (fileName) => {
    fetch(
      "https://mvn.financeseva.com/revenue-management/api/secure/v1/customer-job-process/" +
        processId +
        "/" +
        processJobId +
        "/document/" +
        fileName,
      {
        method: "get",
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const handleInvoiceDownload = (data) => {
    const processId = data?.customerFileProcessJob?.customerFileProcess?.id;
    const processJobId = data?.customerFileProcessJob?.id;

    fetch(
      "https://mvn.financeseva.com/revenue-management/api/secure/v1/customer-job-process/" +
        processId +
        "/" +
        processJobId +
        "/invoice",
      {
        method: "GET",
        responseType: "arraybuffer", // Set response type to arraybuffer
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.arrayBuffer().then((buffer) => ({
          buffer,
          contentType: response.headers.get("content-type"),
        }));
      })
      .then(({ buffer, contentType }) => {
        // Create blob from array buffer
        const blob = new Blob([buffer], { type: contentType });
        // Create blob link to download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading invoice:", error);
      });
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected
  };

  const renderDialogInvoiceNo = () => (
    <Dialog
      header="Employee"
      visible={displayInvoiceNo}
      onHide={() => onHideInvoiceNo()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleSubmitInvoiceNo}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="invoiceNo" className="form-label">
                Invoice Number
              </label>
              <InputText
                type="number"
                style={{ width: "100%" }}
                id="invoiceNo"
                name="remark"
                value={input.invoiceNo}
                onChange={(e) => {
                  setInput({
                    ...input,
                    invoiceNo: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button type="submit" className="move-btn move-btn-su">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );

  const renderDialog = () => (
    <Dialog
      header="Employee"
      visible={displayResponsive}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="remark" className="form-label">
                Remark
              </label>
              <InputText
                type="text"
                style={{ width: "100%" }}
                id="remark"
                name="remark"
                value={input.remark}
                onChange={(e) => {
                  setInput({
                    ...input,
                    remark: e.target.value,
                  });
                }}
                // required
              />
              <div className="text-danger">{errors.remark}</div>
            </div>
            <div className="col-md-6">
              <label htmlFor="status" className="form-label">
                Status
              </label>
              <Dropdown
                value={input.status}
                options={statusData}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setInput({
                    ...input,
                    status: e.target.value,
                  });
                }}
                placeholder="Select a Status"
              />
              <div className="text-danger">{errors.status}</div>
            </div>
          </div>
          {input.status === "COMPLETED" && (
            <div className="row mb-3">
              {/*<div className="col-md-6">
                <label htmlFor="invoiceNo" className="form-label">
                  Invoice Number
                </label>
                <InputText
                  type="number"
                  style={{ width: "100%" }}
                  id="invoiceNo"
                  name="remark"
                  value={input.invoiceNo}
                  onChange={(e) => {
                    setInput({
                      ...input,
                      invoiceNo: e.target.value,
                    });
                  }}
                />
                </div>*/}
              <div className="col-md-6">
                <label htmlFor="workingHour" className="form-label">
                  Total Working Hour
                </label>
                <InputText
                  type="number"
                  style={{ width: "100%" }}
                  id="workingHour"
                  name="workingHour"
                  value={input.workingHour}
                  onChange={(e) => {
                    setInput({
                      ...input,
                      workingHour: e.target.value,
                    });
                  }}
                />
              </div>
              {/*<div>
                <p style={{ marginTop: "5px", color: "red" }}>
                  {invoiceUploadError}
                </p>
                </div>*/}
            </div>
          )}
          <div className="col-md-12 text-center">
            <button type="submit" className="move-btn move-btn-su">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );

  const renderDialogFileUpload = () => (
    <Dialog
      header="File Upload"
      visible={displayFileUpload}
      onHide={() => onHideFileUpload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleFileUpload}>
          <div className="row mb-3">
            <div className="col-md-12" style={{ display: "flex" }}>
              <label for="file-upload" className="custom-file-upload">
                <i className="pi pi-upload"></i> Choose File
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {fileName && (
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: 14,
                    fontWeight: 600,
                    margin: 10,
                  }}
                >
                  {fileName}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button type="submit" className="move-btn move-btn-su">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );

  const renderDialogInvoiceUpload = () => (
    <Dialog
      header="Invoice Upload"
      visible={displayInvoiceUpload}
      onHide={() => onHideInvoiceUpload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleInvoiceUpload}>
          <div className="row mb-3">
            <div className="col-md-12" style={{ display: "flex" }}>
              <label for="file-upload" className="custom-file-upload">
                <i className="pi pi-upload"></i> Choose Invoice
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {fileName && (
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: 14,
                    fontWeight: 600,
                    margin: 10,
                  }}
                >
                  {fileName}
                </p>
              )}
            </div>
            <div>
              <p style={{ marginTop: "5px", color: "red" }}>
                {invoiceUploadError}
              </p>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button type="submit" className="move-btn move-btn-su">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );

  const renderDialogFileDownload = () => (
    <Dialog
      header="Download Your File"
      visible={displayFileDownload}
      onHide={() => onHideFileDownload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <span style={{ fontSize: 14, color: "#000", fontWeight: 600 }}>
                  File Name
                </span>
              </div>
              <div className="col-md-3">
                <span style={{ fontSize: 14, color: "#000", fontWeight: 600 }}>
                  Upload By
                </span>
              </div>
              <div className="col-md-3">
                <span style={{ fontSize: 14, color: "#000", fontWeight: 600 }}>
                  Upload On
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            {uploadedData.map((item, index) => (
              <div key={index}>
                <div className="row" style={{ margin: "5px 0px" }}>
                  <div className="col-md-1">
                    <div>
                      <Button
                        onClick={() => handleFileDownload(item.documentPath)}
                        download={item.documentPath}
                        target="_blank"
                        className="btn btn-success"
                        rel="noreferrer"
                      >
                        <i className="pi pi-download"></i>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-5" style={{ alignSelf: "center" }}>
                    <span
                      style={{
                        fontSize: 14,
                        color: "#000",
                        wordWrap: "break-word", // or overflowWrap: "break-word"
                        display: "inline-block", // Ensures the span behaves like a block element
                        maxWidth: "100%", // Restricts the width of the span to prevent overflow
                      }}
                    >
                      {item.documentPath}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ alignSelf: "center" }}>
                    <span
                      style={{
                        fontSize: 14,
                        color: "#000",
                      }}
                    >
                      {item.user.firstName + item.user.lastName}
                    </span>
                  </div>
                  <div className="col-md-3" style={{ alignSelf: "center" }}>
                    <span
                      style={{
                        fontSize: 14,
                        color: "#000",
                      }}
                    >
                      {formatDate(item.createdDate)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  );

  const renderDialogRemarks = () => (
    <Dialog
      header="Remarks"
      visible={displayRemarks}
      onHide={() => onHideRemarks()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <span style={{ fontSize: 14, color: "#000", fontWeight: 600 }}>
                  Created On
                </span>
              </div>
              <div className="col-md-6">
                <span style={{ fontSize: 14, color: "#000", fontWeight: 600 }}>
                  Remark
                </span>
              </div>
              <div className="col-md-3">
                <span style={{ fontSize: 14, color: "#000", fontWeight: 600 }}>
                  Created By
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                {remarksData?.customerFileProcessJob?.userRemarks
                  .slice(
                    0,
                    showMore
                      ? remarksData?.customerFileProcessJob.userRemarks.length
                      : 2
                  )
                  .map((item, index) => (
                    <div key={index}>
                      <span
                        style={{ fontSize: 14, color: "#000", fontWeight: 400 }}
                      >
                        {formatDate(item.createdDate)}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="col-md-6">
                {remarksData?.customerFileProcessJob?.userRemarks
                  .slice(
                    0,
                    showMore
                      ? remarksData?.customerFileProcessJob.userRemarks.length
                      : 2
                  )
                  .map((item, index) => (
                    <div key={index}>
                      <span
                        style={{ fontSize: 14, color: "#000", fontWeight: 400 }}
                      >
                        {item.remark}
                      </span>
                    </div>
                  ))}
              </div>
              <div className="col-md-3">
                {remarksData?.customerFileProcessJob?.userRemarks
                  .slice(
                    0,
                    showMore
                      ? remarksData?.customerFileProcessJob.userRemarks.length
                      : 2
                  )
                  .map((item, index) => (
                    <div key={index}>
                      <span
                        style={{ fontSize: 14, color: "#000", fontWeight: 400 }}
                      >
                        {item.userDTO.firstName + item.userDTO.lastName}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="product-list-item">
          {!showMore &&
            remarksData?.customerFileProcessJob?.userRemarks.length > 2 && (
              <button className="btn btn-primary" onClick={toggleShowMore}>
                Show All
              </button>
            )}
        </div>
      </div>
    </Dialog>
  );

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((p) =>
      p.customerFileProcessJob.customerFileProcess.customerFile.customer.name
        .toLowerCase()
        .includes(searchQueryValue.toLowerCase())
    );
    setProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  const renderListItem = (data) => {
    return (
      <div className="col-12 backgroundDesign">
        <div className="product-list-item">
          <div className="product-list-detail">
            <div className="rowDiv">
              <i className="pi pi-user product-category-icon blueColorIcon"></i>
              Customer Name:{" "}
            </div>
            <div className="rowDiv">
              <i className="pi pi-file product-category-icon orangeColorIcon"></i>
              Customer File Name:{" "}
            </div>
            <div className="rowDiv">
              <i className="pi pi-user product-category-icon blueColorIcon"></i>
              Authorized Person:{" "}
            </div>
            <div className="rowDiv">
              <i className="pi pi-phone product-category-icon orangeColorIcon"></i>
              Authorized Person Mobile:{" "}
            </div>
            {data.customerFileProcessJob.status === "COMPLETED" &&
              (data.customerFileProcessJob.invoiceNo !== null ? (
                <div className="rowDiv">
                  <i className="pi pi-file product-category-icon blueColorIcon"></i>
                  Invoice Number:{" "}
                </div>
              ) : (
                ""
              ))}
          </div>
          <div className="product-list-detail">
            <div className="rowDiv processListFont">
              {
                data.customerFileProcessJob.customerFileProcess.customerFile
                  .customer.name
              }
            </div>
            <div className="rowDiv processListFont">
              {
                data.customerFileProcessJob.customerFileProcess.customerFile
                  .fileName
              }
            </div>
            <div className="rowDiv processListFont">
              {
                data.customerFileProcessJob.customerFileProcess.customerFile
                  .customer.authorizedPerson
              }
            </div>
            <div className="rowDiv processListFont">
              {
                data.customerFileProcessJob.customerFileProcess.customerFile
                  .customer.authorizedPersonMobile
              }
            </div>
            {data.customerFileProcessJob.status === "COMPLETED" &&
              (data.customerFileProcessJob.invoiceNo !== null ? (
                <div className="rowDiv processListFont">
                  {data.customerFileProcessJob.invoiceNo}
                </div>
              ) : (
                ""
              ))}
          </div>
          <div className="product-list-detail">
            <div className="rowDiv">
              <i className="pi pi-file product-category-icon blueColorIcon"></i>
              File Job:{" "}
            </div>
            <div className="rowDiv">
              <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>
              Start On:{" "}
            </div>
            <div className="rowDiv">
              <i className="pi pi-calendar product-category-icon blueColorIcon"></i>
              End On:{" "}
            </div>
            <div className="rowDiv">Status: </div>
          </div>

          <div className="product-list-detail">
            <div className="rowDiv processListFont">
              {data.customerFileProcessJob.customerFileJob.mvnJob.name}
            </div>
            <div className="rowDiv processListFont">
              {formatDate(
                data.customerFileProcessJob.customerFileProcess.startOn
              )}
            </div>
            <div className="rowDiv processListFont">
              {formatDate(
                data.customerFileProcessJob.customerFileProcess.endOn
              )}
            </div>
            <div className="rowDiv processListFont">
              {data.customerFileProcessJob.status}
            </div>
          </div>
        </div>
        <div className="product-list-item" style={{ gap: "10px" }}>
          {data.customerFileProcessJob.status !== "COMPLETED" && (
            <Button
              className="btn btn-warning"
              onClick={() => onClick(data, "displayResponsive")}
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
                color: "#fff",
              }}
            >
              Update Status{" "}
              <i
                className="pi pi-thumbs-up-fill product-category-icon"
                style={{ marginLeft: "10px" }}
              ></i>
            </Button>
          )}
          <Button
            className="btn btn-success"
            onClick={() => onClickFileUpload(data, "displayFileUpload")}
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            File Upload{" "}
            <i
              className="pi pi-upload product-category-icon"
              style={{ marginLeft: "10px" }}
            ></i>
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => onClickFileDownload(data, "displayFileDownload")}
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            File Download{" "}
            <i
              className="pi pi-download product-category-icon"
              style={{ marginLeft: "10px" }}
            ></i>
          </Button>
          <Button
            className="btn btn-info"
            onClick={() => onClickRemarks(data, "displayRemarks")}
            style={{
              width: "100%",
              textAlign: "left",
              display: "flex",
              justifyContent: "space-between",
              color: "#fff",
            }}
          >
            Remarks{" "}
            <i
              className="pi pi-star-fill product-category-icon"
              style={{ marginLeft: "10px", color: "#fff" }}
            ></i>
          </Button>
          {/*data.customerFileProcessJob.status === "COMPLETED" && (
            <Button
              className="btn btn-success"
              onClick={() => onClickInvoiceNo(data, "displayInvoiceNo")}
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              Invoice Info{" "}
              <i
                className="pi pi-upload product-category-icon"
                style={{ marginLeft: "10px" }}
              ></i>
            </Button>
          )*/}
          {/*data.customerFileProcessJob.status === "COMPLETED" &&
            (data.customerFileProcessJob.invoice === null ? (
              <Button
                className="btn btn-success"
                onClick={() =>
                  onClickInvoiceUpload(data, "displayInvoiceUpload")
                }
                style={{
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Invoice Upload{" "}
                <i
                  className="pi pi-upload product-category-icon"
                  style={{ marginLeft: "10px" }}
                ></i>
              </Button>
            ) : (
              <Button
                className="btn btn-danger"
                onClick={() => handleInvoiceDownload(data)}
                style={{
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Invoice Download{" "}
                <i
                  className="pi pi-download product-category-icon"
                  style={{ marginLeft: "10px" }}
                ></i>
              </Button>
            ))*/}
        </div>
      </div>
    );
  };

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderGridItem = (data) => {
    return (
      <div className="col-12 md:col-4 backgroundDesign">
        <div className="product-grid-item">
          <div className="rowDiv">
            <div>
              <i className="pi pi-user product-category-icon blueColorIcon"></i>
            </div>
            <div>
              <div>Customer Name:</div>
              <div className="product-category">
                {
                  data.customerFileProcessJob.customerFileProcess.customerFile
                    .customer.name
                }
              </div>
            </div>
          </div>

          <div className="rowDiv">
            <div>
              <i className="pi pi-file product-category-icon orangeColorIcon"></i>
            </div>
            <div>
              <div>Customer File Name:</div>
              <div className="product-category">
                {
                  data.customerFileProcessJob.customerFileProcess.customerFile
                    .fileName
                }
              </div>
            </div>
          </div>

          <div className="rowDiv">
            <div>
              <i className="pi pi-user product-category-icon blueColorIcon"></i>
            </div>
            <div>
              <div>Authorized Person: </div>
              <div className="product-category">
                {
                  data.customerFileProcessJob.customerFileProcess.customerFile
                    .customer.authorizedPerson
                }
              </div>
            </div>
          </div>

          <div className="rowDiv">
            <div>
              <i className="pi pi-phone product-category-icon orangeColorIcon"></i>
            </div>
            <div>
              <div>Authorized Person Mobile: </div>
              <div className="product-category">
                {
                  data.customerFileProcessJob.customerFileProcess.customerFile
                    .customer.authorizedPersonMobile
                }
              </div>
            </div>
          </div>

          <div className="rowDiv">
            <div>
              <i className="pi pi-file product-category-icon blueColorIcon"></i>
            </div>
            <div>
              <div>File Job: </div>
              <div className="product-category">
                {data.customerFileProcessJob.customerFileJob.mvnJob.name}
              </div>
            </div>
          </div>

          <div className="mb-2">
            <div>
              <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>
              <span className="product-category">
                {formatDate(
                  data.customerFileProcessJob.customerFileProcess.startOn
                )}{" "}
                -{" "}
                {formatDate(
                  data.customerFileProcessJob.customerFileProcess.endOn
                )}
              </span>
            </div>
            <div>
              Status :
              <span className="product-category">
                {data.customerFileProcessJob.status}
              </span>
            </div>
            {data.customerFileProcessJob.status === "COMPLETED" &&
              (data.customerFileProcessJob.invoiceNo !== null ? (
                <div>
                  Invoice Number :
                  <span className="product-category">
                    {data.customerFileProcessJob.invoiceNo}
                  </span>
                </div>
              ) : (
                ""
              ))}
          </div>
          <div className="product-list-action">
            {data.customerFileProcessJob.status !== "COMPLETED" && (
              <Button
                className="btn btn-warning"
                onClick={() => onClick(data, "displayResponsive")}
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "#fff",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Update Status
                <i className="pi pi-thumbs-up-fill product-category-icon"></i>
              </Button>
            )}
            <Button
              className="btn btn-success"
              onClick={() => onClickFileUpload(data, "displayFileUpload")}
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              File Upload
              <i className="pi pi-upload product-category-icon"></i>
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => onClickFileDownload(data, "displayFileDownload")}
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              File Download
              <i className="pi pi-download product-category-icon"></i>
            </Button>
            <Button
              className="btn btn-info"
              onClick={() => onClickRemarks(data, "displayRemarks")}
              style={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                color: "#fff",
              }}
            >
              Remarks{" "}
              <i
                className="pi pi-star-fill product-category-icon"
                style={{ marginLeft: "10px", color: "#fff" }}
              ></i>
            </Button>
            {/*data.customerFileProcessJob.status === "COMPLETED" && (
              <Button
                className="btn btn-success"
                onClick={() => onClickInvoiceNo(data, "displayInvoiceNo")}
                style={{
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Invoice Info{" "}
                <i
                  className="pi pi-upload product-category-icon"
                  style={{ marginLeft: "10px" }}
                ></i>
              </Button>
            )*/}
            {/*data.customerFileProcessJob.status === "COMPLETED" &&
              (data.customerFileProcessJob.invoice === null ? (
                <Button
                  className="btn btn-success"
                  onClick={() =>
                    onClickInvoiceUpload(data, "displayInvoiceUpload")
                  }
                  style={{
                    width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Invoice Upload{" "}
                  <i
                    className="pi pi-upload product-category-icon"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </Button>
              ) : (
                <Button
                  className="btn btn-danger"
                  onClick={() => handleInvoiceDownload(data)}
                  style={{
                    width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Invoice Download{" "}
                  <i
                    className="pi pi-download product-category-icon"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </Button>
              ))*/}
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    return (
      <div>
        <div className="row">
          <div
            className="col-md-4 col-sm-12 mb-2"
            style={{ textAlign: "left" }}
          >
            <InputText
              type="text"
              placeholder="Search..."
              value={searchQuery}
              style={{ width: "100%" }}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="col-md-4 col-sm-12 mb-2">
            <Calendar
              id="startDate"
              name="startDate"
              value={inputData.startDate}
              style={{ width: "100%" }}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  startDate: e.target.value,
                });
              }}
              showIcon
              required
            />
          </div>
          <div
            className="col-md-4 col-sm-12 mb-2"
            style={{ textAlign: "right" }}
          >
            <Calendar
              id="startDate"
              name="startDate"
              value={inputData.endDate}
              style={{ width: "100%" }}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  endDate: e.target.value,
                });
              }}
              showIcon
              required
            />
          </div>
          <div
            className="col-md-6  mb-2"
            style={{ display: "none", textAlign: "right" }}
          >
            <DataViewLayoutOptions
              layout={layout}
              onChange={(e) => setLayout(e.value)}
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-4  col-sm-12 mb-2"
            style={{ textAlign: "left" }}
          >
            <Dropdown
              value={inputData.types[0]}
              options={jobsData}
              style={{ width: "100%" }}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  types: [e.value], // Ensure it's always an array
                });
              }}
              placeholder="Select a type"
            />
          </div>
          <div
            className="col-md-4  col-sm-12 mb-2"
            style={{ textAlign: "left" }}
          >
            <Dropdown
              value={inputData.status[0]}
              options={statusData}
              style={{ width: "100%" }}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  status: [e.value], // Ensure it's always an array
                });
              }}
              placeholder="Select a status"
            />
          </div>
          <div
            className="col-md-4  col-sm-12 mb-2"
            style={{ textAlign: "right" }}
          >
            <Button
              type="button"
              onClick={resetFilters}
              className="p-button-danger"
            >
              <i className="pi pi-sync"></i>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const header = isFilterVisible ? renderHeader() : null;

  return (
    <div className="dataview-demo">
      <div className="card">
        {/*<DataView
          value={products}
          layout={layout}
          header={header}
          itemTemplate={itemTemplate}
          paginator
          rows={9}
  />*/}
        {isFilterVisible && (
          <div>
            <div className="row">
              <div
                className="col-md-4 col-sm-12 mb-2"
                style={{ textAlign: "left" }}
              >
                <InputText
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  style={{ width: "100%" }}
                  onChange={handleSearchInputChange}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-2">
                <Calendar
                  id="startDate"
                  name="startDate"
                  value={inputData.startDate}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      startDate: e.target.value,
                    });
                  }}
                  showIcon
                  required
                />
              </div>
              <div
                className="col-md-4 col-sm-12 mb-2"
                style={{ textAlign: "right" }}
              >
                <Calendar
                  id="startDate"
                  name="startDate"
                  value={inputData.endDate}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      endDate: e.target.value,
                    });
                  }}
                  showIcon
                  required
                />
              </div>
              <div
                className="col-md-6  mb-2"
                style={{ display: "none", textAlign: "right" }}
              >
                <DataViewLayoutOptions
                  layout={layout}
                  onChange={(e) => setLayout(e.value)}
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-4  col-sm-12 mb-2"
                style={{ textAlign: "left" }}
              >
                <Dropdown
                  value={inputData.types[0]}
                  options={jobsData}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      types: [e.value], // Ensure it's always an array
                    });
                  }}
                  placeholder="Select a type"
                />
              </div>
              <div
                className="col-md-4  col-sm-12 mb-2"
                style={{ textAlign: "left" }}
              >
                <Dropdown
                  value={inputData.status[0]}
                  options={statusData}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      status: [e.value], // Ensure it's always an array
                    });
                  }}
                  placeholder="Select a status"
                />
              </div>
              <div
                className="col-md-4  col-sm-12 mb-2"
                style={{ textAlign: "right" }}
              >
                <Button
                  type="button"
                  onClick={resetFilters}
                  className="p-button-danger"
                >
                  <i className="pi pi-sync"></i>
                </Button>
              </div>
            </div>
          </div>
        )}
        <DataTable
          value={products}
          paginator
          paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          dataKey="id"
          className="p-datatable-customers client-table-design"
          resizableColumns
          columnResizeMode="fit"
        >
          <Column
            field="serialNumber"
            header="S No."
            resizable={false}
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcessJob.customerFileProcess.customerFile.customer.name"
            header="Customer Name"
            resizeable={false}
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcessJob.customerFileJob.mvnJob.name"
            header="Nature of Work"
            className="product-category table-design"
          ></Column>
          <Column
            field="service"
            header="Start Date"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {formatDate(
                  rowData.customerFileProcessJob.customerFileProcess.startOn
                )}
              </span>
            )}
          ></Column>
          <Column
            field="service"
            header="End Date"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {formatDate(
                  rowData.customerFileProcessJob.customerFileProcess.endOn
                )}
              </span>
            )}
          ></Column>
          <Column
            field="customerFileProcessJob.status"
            header="Status"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJob.status === "TO_BE_STARTED"
                  ? "PENDING"
                  : rowData.customerFileProcessJob.status}
              </span>
            )}
          ></Column>
          <Column
            field="service"
            header="Invoice No."
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJob.status === "COMPLETED" &&
                rowData.customerFileProcessJob.invoiceNo !== null
                  ? rowData.customerFileProcessJob.invoiceNo
                  : ""}
              </span>
            )}
          ></Column>
          <Column
            field="service"
            header="Update Status"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJob.status !== "COMPLETED" && (
                  <Button
                    className="btn btn-warning"
                    onClick={() => onClick(rowData, "displayResponsive")}
                    style={{
                      // width: "100%",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "#fff",
                    }}
                  >
                    <i
                      className="pi pi-thumbs-up-fill product-category-icon"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  </Button>
                )}
              </span>
            )}
          ></Column>
          <Column
            field="service"
            header="File Upload"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                <Button
                  className="btn btn-success"
                  onClick={() =>
                    onClickFileUpload(rowData, "displayFileUpload")
                  }
                  style={{
                    // width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <i
                    className="pi pi-upload product-category-icon"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </Button>
              </span>
            )}
          ></Column>
          <Column
            field="service"
            header="File Download"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                <Button
                  className="btn btn-primary"
                  onClick={() =>
                    onClickFileDownload(rowData, "displayFileDownload")
                  }
                  style={{
                    // width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <i
                    className="pi pi-download product-category-icon"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </Button>
              </span>
            )}
          ></Column>
          <Column
            field="service"
            header="Remarks"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                <Button
                  className="btn btn-info"
                  onClick={() => onClickRemarks(rowData, "displayRemarks")}
                  style={{
                    // width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                    color: "#fff",
                  }}
                >
                  <i
                    className="pi pi-star-fill product-category-icon"
                    style={{ marginLeft: "10px", color: "#fff" }}
                  ></i>
                </Button>
              </span>
            )}
          ></Column>
        </DataTable>
      </div>
      <div>{renderDialog()}</div>
      <div>{renderDialogInvoiceNo()}</div>
      <div>{renderDialogFileUpload()}</div>
      <div>{renderDialogFileDownload()}</div>
      <div>{renderDialogRemarks()}</div>
      <div>{renderDialogInvoiceUpload()}</div>
    </div>
  );
}
