import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const uploadInvoice = (processId, processJobId, invoice, invoiceNo) => {
  const formData = new FormData();
  formData.append("invoice", invoice); // Assuming 'file' is the file object you want to upload
  formData.append("invoiceNo", invoiceNo); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url:
      BASE_URL +
      "api/secure/v1/customer-job-process/" +
      processId +
      "/" +
      processJobId +
      "/invoice",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};

export const postInvoiceNumber = (processId, processJobId, invoiceNo) => {
  return axios({
    method: "post",
    url: `${BASE_URL}api/secure/v2/customer-job-process/${processId}/${processJobId}/invoice?invoiceNo=${invoiceNo}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    // data: JSON.stringify({ invoiceNo: invoiceNo }),
  });
};

