import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link } from "react-router-dom";
import { DataView } from "primereact/dataview";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getJobProcess } from "../../services/process";
import { formatDate, formatDateWithTime } from "../../constants/FormateDate";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import "../../assets/css/AccordionDemo.css";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { getUser } from "../../services/userMasterUser";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { getStatus } from "../../services/getStatus";
import Table from "react-bootstrap/Table";
import { Dialog } from "primereact/dialog";
import { postInvoiceNumber, uploadInvoice } from "../../services/invoice";
import { getUserProfile } from "../../services/userProfile";
import {
  getProcessJobPayment,
  postProcessJobPayment,
} from "../../services/processJobPayment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function AccountantJobProcessList({ isFilterVisible }) {
  const [products, setProducts] = useState(null);
  const [productUsers, setProductUsers] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [layout, setLayout] = useState("list");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [input, setInput] = useState({});
  const [inputAmount, setInputAmount] = useState(null);
  const [inputAmountError, setInputAmountError] = useState(false);
  const [profile, setProfile] = useState({});
  const [paymentRecordView, setPaymentRecordView] = useState(false);
  const [paymentRecordData, setPaymentRecordData] = useState([]);

  const fetchCustomerJobProcess = (data) => {
    getJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          
          setProducts(
            Res.sort((a, b) =>
              a.customerFileProcess.customerFile.customer.name
                .trim()
                .localeCompare(
                  b.customerFileProcess.customerFile.customer.name.trim()
                )
            )
          );
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  const fetchUserData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
           const removeAccountantUser = response.data.filter(
             (item) => item?.role !== "ROLE_ACCOUNTANT"
           );
           const filteredData = removeAccountantUser.map((users) => ({
             label: users.firstName + " " + users.lastName,
             value: users.id,
           }));
          setProductUsers(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  const fetchStatus = (data) => {
    getStatus(data)
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const Res = response.data;
           const transformedStatusData = Res.map((item) => ({
             label: item === "TO_BE_STARTED" ? "PENDING" : item, // Change label for status with id 2
             value: item,
           }));
          setStatusData(transformedStatusData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const fetchProcessJobPayment = async (id) => {
    try {
      const response = await getProcessJobPayment(id);
      if (!response.data.error) {
        return response.data;
      } else {
        throw new Error("Invalid Credentials");
      }
    } catch (error) {
      throw new Error("Failed to fetch payment record data");
    }
  };

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setProfile(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: ["COMPLETED"],
      users: [],
    };
    fetchCustomerJobProcess(data);
    fetchUserData();
    fetchStatus("CUSTOMER_FILE_PROCESS");
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [inputData, setInputData] = useState({
    startDate: new Date(),
    endDate: new Date(),
    types: [],
    status: [],
    users: [],
  });

  useEffect(() => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: ["COMPLETED"],
      users: inputData.users,
    };
    fetchCustomerJobProcess(data);
  }, [inputData]);

  const [jobsData, setJobsData] = useState([
    { label: "Compliance Date", value: "compliance_date" },
    { label: "No. of days", value: "no_of_days" },
  ]);

  const [displayInvoiceUpload, setDisplayInvoiceUpload] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [fileName, setFileName] = useState("");
  const [processJobId, setProcessJobId] = useState(null);
  const [invoiceUploadError, setInvoiceUploadError] = useState(null);
  const [customerFileProcessJobId, setCustomerFileProcessJobId] =
    useState(null);
  const [balanceAmount, setBalanceAmount] = useState(null);

  const onClickInvoiceUpload = (data) => {
    // setFileUploadData(data);
    setProcessId(data?.customerFileProcess?.id); // customerFileProcess?.id form admin dashboard api
    setProcessJobId(data?.customerFileProcessJobDTOList[0]?.id); // customerFileProcessJobDTOList[0]?.id
    setDisplayInvoiceUpload(true);
  };

  const onHideInvoiceUpload = () => {
    setFileName("");
    setInvoiceUploadError("");
    setDisplayInvoiceUpload(false);
  };

  const handleInvoiceUpload = (event) => {
    event.preventDefault();
    const fileInput = event.target.querySelector('input[type="file"]');
    const invoice = fileInput.files[0];
    const invoiceNo = input.invoiceNo;

    if (invoice && invoiceNo) {
      uploadInvoice(processId, processJobId, invoice, invoiceNo)
        .then((response) => {
          // handle the response
          onHideInvoiceUpload();
          setSearchQuery("");
          successMessage("Invoice Upload Successfully");
          const data = {
            startDate: new Date(),
            endDate: new Date(),
            types: [],
            status: ["COMPLETED"],
            users: [],
          };
          setInput("");
          fetchCustomerJobProcess(data);
        })
        .catch((error) => {
          // handle errors
          setInvoiceUploadError("Invoice Upload Failed");
        });
    } else if (invoiceNo) {
      postInvoiceNumber(processId, processJobId, invoiceNo)
        .then((response) => {
          // handle the response
          onHideInvoiceUpload();
          setSearchQuery("");
          successMessage("Invoice Upload Successfully");
          const data = {
            startDate: new Date(),
            endDate: new Date(),
            types: [],
            status: ["COMPLETED"],
            users: [],
          };
          setInput("");
          fetchCustomerJobProcess(data);
        })
        .catch((error) => {
          // handle errors
          setInvoiceUploadError("Invoice Upload Failed");
        });
    } else if (invoice) {
      setInvoiceUploadError("Please provide an invoice number.");
    } else {
      setInvoiceUploadError(
        "Please upload an invoice file and provide an invoice number."
      );
    }
  };

  // const handleInvoiceUpload = (event) => {
  //   event.preventDefault();
  //   const fileInput = event.target.querySelector('input[type="file"]');
  //   const invoice = fileInput.files[0];
  //   const invoiceNo = input.invoiceNo;

  //   // if (!invoice) {
  //   // console.error("No file selected");
  //   // setInvoiceUploadError("Please Upload Invoice File");
  //   // return;
  //   // }

  //   console.log("processId", processId);
  //   console.log("processJobId", processJobId);
  //   console.log("invoice", invoice);
  //   console.log("invoiceNo", invoiceNo);
  //   // setInput("");
  //   postInvoiceNumber(processId, processJobId, invoiceNo)
  //     .then((response) => {
  //       // handle the response
  //       onHideInvoiceUpload();
  //       setSearchQuery("");
  //       successMessage("Invoice Upload Successfully");
  //       const data = {
  //         startDate: new Date(),
  //         endDate: new Date(),
  //         types: [],
  //         status: ["COMPLETED"],
  //         users: [],
  //       };
  //       setInput("");
  //       fetchCustomerJobProcess(data);
  //     })
  //     .catch((error) => {
  //       // handle errors
  //       errorMessage("Invoice Upload Failed");
  //     });

  //   uploadInvoice(processId, processJobId, invoice, invoiceNo)
  //     .then((response) => {
  //       // handle the response
  //       onHideInvoiceUpload();
  //       setSearchQuery("");
  //       successMessage("Invoice Upload Successfully");
  //       const data = {
  //         startDate: new Date(),
  //         endDate: new Date(),
  //         types: [],
  //         status: ["COMPLETED"],
  //         users: [],
  //       };
  //       setInput("");
  //       fetchCustomerJobProcess(data);
  //     })
  //     .catch((error) => {
  //       // handle errors
  //       errorMessage("Invoice Upload Failed");
  //     });
  // };

  const handlePayment = (event) => {
    event.preventDefault();

    if (!inputAmount) {
      setInputAmountError("Please Enter Payment Amount");
      errorMessage("Please Enter Payment Amount");
      return;
    }
    if (inputAmount > balanceAmount) {
      setInputAmountError(
        "Entered Amount Can Not Be Greater Then Balance Amount"
      );
      errorMessage("Entered Amount Can Not Be Greater Then Balance Amount");
      return;
    }
  
    const data = {
      customerFileProcessJob: {
        id: customerFileProcessJobId,
      },
      receivedBy: {
        id: profile.id,
      },
      amount: inputAmount,
    };

    postProcessJobPayment(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          // handle the response
          setSearchQuery("");
          successMessage("Payment Added Successfully ");
          const data = {
            startDate: new Date(),
            endDate: new Date(),
            types: [],
            status: ["COMPLETED"],
            users: [],
          };
          setInput("");
          fetchCustomerJobProcess(data);
          setInputAmount(null);
        }
      })
      .catch((error) => {
        // handle errors
        errorMessage("Invoice Upload Failed");
      });
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected
  };

  const renderDialogInvoiceUpload = () => (
    <Dialog
      header="Invoice Upload"
      visible={displayInvoiceUpload}
      onHide={() => onHideInvoiceUpload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleInvoiceUpload}>
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="col-md-6">
                <label htmlFor="invoiceNo" className="form-label">
                  Invoice Number
                </label>
                <InputText
                  type="number"
                  id="invoiceNo"
                  name="remark"
                  value={input.invoiceNo}
                  onChange={(e) => {
                    setInput({
                      ...input,
                      invoiceNo: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label for="file-upload" className="custom-file-upload">
                <i className="pi pi-upload"></i> Choose Invoice
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {fileName && (
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: 14,
                    fontWeight: 600,
                    margin: 10,
                    paddingTop: 10,
                  }}
                >
                  {fileName}
                </p>
              )}

              <div>
                <p style={{ marginTop: "5px", color: "red" }}>
                  {invoiceUploadError}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button type="submit" className="move-btn move-btn-su">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );

  const handleInvoiceDownload = (data) => {
    const processId = data?.customerFileProcess?.id;
    const processJobId = data?.customerFileProcessJobDTOList[0]?.id;

    fetch(
      "https://mvn.financeseva.com/revenue-management/api/secure/v1/customer-job-process/" +
        processId +
        "/" +
        processJobId +
        "/invoice",
      {
        method: "GET",
        responseType: "arraybuffer", // Set response type to arraybuffer
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.arrayBuffer().then((buffer) => ({
          buffer,
          contentType: response.headers.get("content-type"),
        }));
      })
      .then(({ buffer, contentType }) => {
        // Create blob from array buffer
        const blob = new Blob([buffer], { type: contentType });
        // Create blob link to download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading invoice:", error);
      });
  };

  const resetFilters = () => {
    setInputData({
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: [],
      users: [],
    });
    setSearchQuery("");
  };

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((p) =>
      p.customerFileProcess.customerFile.customer.name
        .toLowerCase()
        .includes(searchQueryValue.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  // const handlePaymentRecord = (value, id) => {
  //   setPaymentRecordView(value);
  //   fetchProcessJobPayment(id);
  // };
  const toggleItemExpansion = async (id) => {
    setPaymentRecordView((prevPaymentRecordView) => ({
      ...prevPaymentRecordView,
      [id]: !prevPaymentRecordView[id],
    }));
    if (!paymentRecordView[id]) {
      try {
        // Assuming you have the necessary input data for fetching payment record
        const paymentData = await fetchProcessJobPayment(id);
        setPaymentRecordData((prevData) => ({
          ...prevData,
          [id]: paymentData,
        }));
      } catch (error) {
        errorMessage("Failed to fetch payment record data");
      }
    }
  };

  const renderListItem = (data, index) => {
    var startDateString = formatDate(
      data.customerFileProcessJobDTOList[0].startOn
    );
    var startDate = new Date(startDateString);

    // Parse end date
    var endDateString = formatDate(data.customerFileProcessJobDTOList[0].endOn);
    var endDate = new Date(endDateString);

    // Calculate the difference in days
    var differenceInTime = endDate.getTime() - startDate.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return (
      <div className="col-12">
        <Accordion className="accordion-custom" activeIndex={index}>
          <AccordionTab
            header={
              <React.Fragment>
                {/*<div className="product-list-item backgroundDesign">
                  <div className="product-list-detail">
                    <div className="product-name">
                      <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                      {data.customerFileProcess.customerFile.customer.name}
                    </div>
                    <div className="product-category">
                      <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>
                      {
                        data.customerFileProcess.customerFile.customer
                          .authorizedPersonMobile
                      }
                    </div>
                    <span className="product-category">
                      <i className="pi pi-file product-category-icon blueColorIcon"></i>
                      {data.customerFileProcess.customerFile.fileName} -{" "}
                      {data.customerFileProcess.customerFile.fileNumber}
                    </span>
                  </div>
                    </div>*/}

                <Table className="backgroundDesign">
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      <div className="rowDiv">
                        {data.customerFileProcess.customerFile.customer.name}
                      </div>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {
                        data.customerFileProcess.customerFile.customer
                          .authorizedPersonMobile
                      }
                    </td>
                    <td
                      style={{
                        width: "30%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {data.customerFileProcess.customerFile.fileName} -{" "}
                      {data.customerFileProcess.customerFile.fileNumber}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {
                        data.customerFileProcessJobDTOList[0].customerFileJob
                          .mvnJob.name
                      }
                    </td>
                  </tr>
                </Table>
              </React.Fragment>
            }
          >
            {data.customerFileProcessJobDTOList[0].totalPaid !==
              data.customerFileProcessJobDTOList[0].revenue && (
              <div style={{ float: "left" }}>
                <form onSubmit={handlePayment}>
                  <div className="row">
                    <label htmlFor="Amount" className="form-label">
                      Payment
                    </label>
                    <div className="col-md-6" style={{ display: "flex" }}>
                      <InputText
                        type="number"
                        id="amount"
                        name="remark"
                        value={input.amount}
                        onChange={(e) => {
                          setInputAmount(e.target.value);
                          setInputAmountError("");
                          if (data?.customerFileProcessJobDTOList?.length > 0) {
                            setCustomerFileProcessJobId(
                              data.customerFileProcessJobDTOList[0].id
                            );
                            setBalanceAmount(
                              data.customerFileProcessJobDTOList[0].revenue.toFixed(
                                0
                              ) -
                                data.customerFileProcessJobDTOList[0].totalPaid.toFixed(
                                  0
                                )
                            );
                          }
                        }}
                        style={{ marginRight: 10 }}
                      />
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ margin: "10px" }}>
                    <p style={{ marginTop: "5px", color: "red" }}>
                      {inputAmountError}
                    </p>
                  </div>
                </form>
              </div>
            )}
            <div style={{ float: "right" }}>
              <div className="product-list-action">
                <div className="product-category">
                  Revenue: ₹{" "}
                  {data.customerFileProcessJobDTOList[0].revenue.toFixed(0)}
                </div>
              </div>
              {/*<div className="product-list-action">
                <div className="product-category">
                  Balance Amount: ₹{" "}
                  {data.customerFileProcessJobDTOList[0].revenue.toFixed(0) -
                    data.customerFileProcessJobDTOList[0].totalPaid.toFixed(0)}
                </div>
                  </div>*/}
              <div className="product-list-action">
                <div className="product-category">
                  {data.customerFileProcessJobDTOList[0]?.status ===
                    "COMPLETED" &&
                    (data.customerFileProcessJobDTOList[0]?.invoice === null ? (
                      <Button
                        className="btn btn-success"
                        onClick={() =>
                          onClickInvoiceUpload(data, "displayInvoiceUpload")
                        }
                        style={{
                          // width: "100%",
                          textAlign: "left",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Invoice Upload{" "}
                        <i
                          className="pi pi-upload product-category-icon"
                          style={{ marginLeft: "10px" }}
                        ></i>
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-danger"
                        onClick={() => handleInvoiceDownload(data)}
                        style={{
                          // width: "100%",
                          textAlign: "left",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Invoice Download{" "}
                        <i
                          className="pi pi-download product-category-icon"
                          style={{ marginLeft: "10px" }}
                        ></i>
                      </Button>
                    ))}
                </div>
              </div>
            </div>

            <div className="product-list-item">
              <div className="product-list-detail">
                <div className="product-category">
                  <i className="pi pi-briefcase product-category-icon blueColorIcon"></i>{" "}
                  {
                    data.customerFileProcessJobDTOList[0].customerFileJob.mvnJob
                      .name
                  }
                </div>
                {/* <div className="product-category">
                  <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].startOn)} -{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].endOn)}
                </div>
                <div className="product-category">
                  <i className="pi pi-calendar product-category-icon blueColorIcon"></i>{" "}
                  Total Days: {differenceInDays}
                </div>*/}

                <div className="product-category">
                  <i className="pi pi-box product-category-icon orangeColorIcon"></i>{" "}
                  Status: {data.customerFileProcessJobDTOList[0].status}
                  {data.customerFileProcessJobDTOList[0].timeBarred &&
                    " Time Barred"}
                </div>

                {data.customerFileProcessJobDTOList[0]?.status ===
                  "COMPLETED" && (
                  <div>
                    <div className="product-category">
                      <i className="pi pi-calendar product-category-icon blueColorIcon"></i>
                      Completed On:{" "}
                      {formatDate(
                        data.customerFileProcessJobDTOList[0].completedOn
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="product-list-detail">
                {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                  (jobUser, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                        <span className="product-category">
                          {jobUser.jobUser.user.firstName}{" "}
                          {jobUser.jobUser.user.lastName}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="product-list-detail">
                {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                  (jobUser, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>{" "}
                        <span className="product-category">
                          {jobUser.jobUser.user.mobile}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div>
              <Button
                className="btn btn-primary"
                onClick={() =>
                  toggleItemExpansion(data.customerFileProcessJobDTOList[0].id)
                }
                // onClick={() =>
                //   handlePaymentRecord(
                //     !paymentRecordView,
                //     data.customerFileProcessJobDTOList[0].id
                //   )
                // }
                style={{
                  // width: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Payment Record
              </Button>
            </div>
            {paymentRecordView[data.customerFileProcessJobDTOList[0].id] && (
              <div className="product-list-item">
                {paymentRecordData[data.customerFileProcessJobDTOList[0].id]
                  ?.length > 0 ? (
                  <>
                    <div className="product-list-detail">
                      <div className="product-category">Date</div>
                      {paymentRecordData[
                        data.customerFileProcessJobDTOList[0].id
                      ].map((item) => (
                        <div className="product-category" key={item.id}>
                          <i className="pi pi-calendar product-category-icon blueColorIcon"></i>{" "}
                          {formatDateWithTime(item.createdDate)}
                        </div>
                      ))}
                    </div>
                    <div className="product-list-detail">
                      <div className="product-category">Amount</div>
                      {paymentRecordData[
                        data.customerFileProcessJobDTOList[0].id
                      ].map((item) => (
                        <div className="product-category" key={item.id}>
                          ₹ {item.amount}
                        </div>
                      ))}
                    </div>
                    <div className="product-list-detail">
                      <div className="product-category">Balance</div>
                      {(() => {
                        let balance =
                          data.customerFileProcessJobDTOList[0].revenue;
                        return paymentRecordData[
                          data.customerFileProcessJobDTOList[0].id
                        ].map((item) => {
                          balance -= item.amount;
                          return (
                            <div className="product-category" key={item.id}>
                              ₹ {balance.toFixed(0)}
                            </div>
                          );
                        });
                      })()}
                    </div>
                  </>
                ) : (
                  <div className="product-list-detail">
                    <p style={{ color: "red" }}>
                      No Payment Record Data Available
                    </p>
                  </div>
                )}
              </div>
            )}
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const renderGridItem = (data, index) => {
    var startDateString = formatDate(
      data.customerFileProcessJobDTOList[0].startOn
    );
    var startDate = new Date(startDateString);

    // Parse end date
    var endDateString = formatDate(data.customerFileProcessJobDTOList[0].endOn);
    var endDate = new Date(endDateString);

    // Calculate the difference in days
    var differenceInTime = endDate.getTime() - startDate.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return (
      <div className="col-12">
        <Accordion className="accordion-custom" activeIndex={index}>
          <AccordionTab
            header={
              <React.Fragment>
                <div className="product-grid-item backgroundDesign">
                  <div className="product-list-detail">
                    <div className="product-category">
                      <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                      {data.customerFileProcess.customerFile.customer.name}
                    </div>
                    <div className="product-category">
                      <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>
                      {
                        data.customerFileProcess.customerFile.customer
                          .authorizedPersonMobile
                      }
                    </div>
                    <div className="product-category">
                      <span className="product-category">
                        <i className="pi pi-file product-category-icon blueColorIcon"></i>
                        {data.customerFileProcess.customerFile.fileName}
                      </span>
                    </div>
                    <span className="product-category">
                      <i className="pi pi-file product-category-icon blueColorIcon"></i>
                      {
                        data.customerFileProcessJobDTOList[0].customerFileJob
                          .mvnJob.name
                      }
                    </span>
                  </div>
                </div>
              </React.Fragment>
            }
          >
            {data.customerFileProcessJobDTOList[0].totalPaid !==
              data.customerFileProcessJobDTOList[0].revenue && (
              <div className="product-list-action mb-2">
                <form
                  onSubmit={handlePayment}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="product-category" style={{ marginRight: 10 }}>
                    Payment
                  </div>

                  <div className="product-category">
                    <InputText
                      type="number"
                      id="amount"
                      name="remark"
                      style={{ width: "70%", marginRight: 10 }}
                      value={input.amount}
                      onChange={(e) => {
                        setInputAmount(e.target.value);
                        setInputAmountError("");
                        if (data?.customerFileProcessJobDTOList?.length > 0) {
                          setCustomerFileProcessJobId(
                            data.customerFileProcessJobDTOList[0].id
                          );
                          setBalanceAmount(
                            data.customerFileProcessJobDTOList[0].revenue.toFixed(
                              0
                            ) -
                              data.customerFileProcessJobDTOList[0].totalPaid.toFixed(
                                0
                              )
                          );
                        }
                      }}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                </form>
                <div className="product-category">
                  <p style={{ marginTop: "5px", color: "red" }}>
                    {inputAmountError}
                  </p>
                </div>
              </div>
            )}
            <div
              className="product-list-action mb-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="product-category">
                Revenue: ₹{" "}
                {data.customerFileProcessJobDTOList[0].revenue.toFixed(0)}
              </div>

              <div className="product-category">
                {data.customerFileProcessJobDTOList[0]?.status ===
                  "COMPLETED" &&
                  (data.customerFileProcessJobDTOList[0]?.invoice === null ? (
                    <Button
                      className="btn btn-success"
                      onClick={() =>
                        onClickInvoiceUpload(data, "displayInvoiceUpload")
                      }
                      style={{
                        // width: "100%",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Invoice Upload{" "}
                      <i
                        className="pi pi-upload product-category-icon"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-danger"
                      onClick={() => handleInvoiceDownload(data)}
                      style={{
                        // width: "100%",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Invoice Download{" "}
                      <i
                        className="pi pi-download product-category-icon"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </Button>
                  ))}
              </div>
            </div>
            <div className="product-grid-item">
              <div className="">
                <div className="product-category">
                  <i className="pi pi-briefcase product-category-icon blueColorIcon"></i>{" "}
                  {
                    data.customerFileProcessJobDTOList[0].customerFileJob.mvnJob
                      .name
                  }
                </div>
                {/*<div className="product-category">
                  <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].startOn)} -{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].endOn)}
                </div>
                <div className="product-category">
                  <i className="pi pi-calendar product-category-icon blueColorIcon"></i>{" "}
                  Total Days: {differenceInDays}
                </div>*/}
                <div className="product-category">
                  <i className="pi pi-box product-category-icon orangeColorIcon"></i>{" "}
                  Status: {data.customerFileProcessJobDTOList[0].status}{" "}
                  {data.customerFileProcessJobDTOList[0].timeBarred &&
                    " Time Barred"}
                </div>
              </div>
              {/*<div className="product-category">
                <i class="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                Estimated Cost:{" "}
                {data.customerFileProcessJobDTOList[0].totalCost.toFixed(0)}
                  </div>*/}
              {data.customerFileProcessJobDTOList[0].status === "COMPLETED" && (
                <div>
                  <div className="product-category">
                    <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>{" "}
                    Completed On:{" "}
                    {formatDate(
                      data.customerFileProcessJobDTOList[0].completedOn
                    )}
                  </div>

                  {/*<div className="product-category">
                    <i class="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                    Actual Cost:{" "}
                    {data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                      0
                    )}
                  </div>*/}

                  {/*data.customerFileProcessJobDTOList[0].revenue >
                  data.customerFileProcessJobDTOList[0].actualCost ? (
                    <Link style={{ justifyContent: "center", color: "green" }}>
                      + ₹{" "}
                      {data.customerFileProcessJobDTOList[0].revenue.toFixed(
                        0
                      ) -
                        data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                          0
                        )}{" "}
                      Profit
                    </Link>
                  ) : data.customerFileProcessJobDTOList[0].revenue <
                    data.customerFileProcessJobDTOList[0].actualCost ? (
                    <Link
                      className="p-button-danger"
                      style={{ justifyContent: "center", color: "red" }}
                    >
                      - ₹{" "}
                      {data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                        0
                      ) -
                        data.customerFileProcessJobDTOList[0].revenue.toFixed(
                          0
                        )}{" "}
                      Loss
                    </Link>
                  ) : (
                    <Link
                      className="p-button-info"
                      style={{ justifyContent: "center", color: "blue" }}
                    >
                      On Going
                    </Link>
                  )*/}
                </div>
              )}
              <div
                style={{ border: "1px solid #1b4077", marginBottom: "5px" }}
              ></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="">
                  {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                    (jobUser, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                          <span className="product-category">
                            {jobUser.jobUser.user.firstName}{" "}
                            {jobUser.jobUser.user.lastName}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="">
                  {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                    (jobUser, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>{" "}
                          <span className="product-category">
                            {jobUser.jobUser.user.mobile}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div>
                <Button
                  className="btn btn-primary"
                  onClick={() =>
                    toggleItemExpansion(
                      data.customerFileProcessJobDTOList[0].id
                    )
                  }
                  // onClick={() =>
                  //   handlePaymentRecord(
                  //     !paymentRecordView,
                  //     data.customerFileProcessJobDTOList[0].id
                  //   )
                  // }
                  style={{
                    // width: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5px",
                  }}
                >
                  Payment Record
                </Button>
              </div>

              {paymentRecordView[data.customerFileProcessJobDTOList[0].id] && (
                <div>
                  {paymentRecordData[data.customerFileProcessJobDTOList[0].id]
                    ?.length > 0 ? (
                    <>
                      <table className="table table-borderless">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(() => {
                            let balance =
                              data.customerFileProcessJobDTOList[0].revenue;
                            return paymentRecordData[
                              data.customerFileProcessJobDTOList[0].id
                            ].map((item) => {
                              balance -= item.amount;
                              return (
                                <tr>
                                  <td>
                                    {formatDateWithTime(item.createdDate)}
                                  </td>
                                  <td>₹{item.amount}</td>
                                  <td key={item.id}>{balance.toFixed(0)}</td>
                                </tr>
                              );
                            });
                          })()}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div className="product-list-detail">
                      <p style={{ color: "red" }}>
                        No Payment Record Data Available
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    const layout = windowWidth > 460 ? "list" : "grid";
    return (
      <div>
        
        {layout === "list" ? (
          <div className="col-12">
            <Table>
              <tr>
                <th style={{ width: "30%" }}>Client Name</th>
                <th style={{ width: "20%" }}>Mobile No.</th>
                <th style={{ width: "30%" }}>File</th>
                <th style={{ width: "20%" }}>Task</th>
              </tr>
            </Table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const header = isFilterVisible ? renderHeader() : null;

  return (
    <div className="dataview-demo">
      <div className="card">
        {/*<DataView
          value={filteredProducts || products}
          layout={layout}
          header={header}
          itemTemplate={itemTemplate}
          paginator
          rows={9}
  />*/}
        <div>
          {isFilterVisible && (
            <div>
              <div className="row">
                <div
                  className="col-md-4 col-sm-12 mb-2"
                  style={{ textAlign: "left" }}
                >
                  <InputText
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    style={{ width: "100%" }}
                    onChange={handleSearchInputChange}
                  />
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                  <Calendar
                    id="startDate"
                    name="startDate"
                    value={inputData.startDate}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        startDate: e.target.value,
                      });
                    }}
                    showIcon
                    required
                  />
                </div>
                <div
                  className="col-md-4 col-sm-12 mb-2"
                  style={{ textAlign: "right" }}
                >
                  <Calendar
                    id="startDate"
                    name="startDate"
                    value={inputData.endDate}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        endDate: e.target.value,
                      });
                    }}
                    showIcon
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-3 col-sm-12 mb-2"
                  style={{ textAlign: "left" }}
                >
                  <Dropdown
                    value={inputData.users[0]}
                    options={productUsers}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        users: [e.target.value],
                      });
                    }}
                    placeholder="Select Users"
                  />
                </div>
                <div
                  className="col-md-3 col-sm-12 mb-2"
                  style={{ textAlign: "right" }}
                >
                  <Button
                    type="button"
                    onClick={resetFilters}
                    className="p-button-danger"
                  >
                    <i className="pi pi-sync"></i>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <DataTable
          value={filteredProducts || products}
          paginator
          paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          dataKey="id"
          className="p-datatable-customers client-table-design"
          resizableColumns
          columnResizeMode="fit"
        >
          <Column
            field="customerFileProcess.customerFile.customer.name"
            header="Customer"
            resizeable={false}
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcess.customerFile.customer.authorizedPersonMobile"
            header="Mobile"
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name"
            header="Task"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {
                  rowData.customerFileProcessJobDTOList[0].customerFileJob
                    .mvnJob.name
                }
              </span>
            )}
          ></Column>
          <Column
            field="customerFileProcessJobDTOList[0].revenue.toFixed(0)"
            header="Revenue"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0].revenue.toFixed(0)}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Completed On"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0].status === "COMPLETED"
                  ? formatDate(
                      rowData.customerFileProcessJobDTOList[0].completedOn
                    )
                  : ""}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Invoice"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0]?.status ===
                  "COMPLETED" &&
                  (rowData.customerFileProcessJobDTOList[0]?.invoice ===
                  null ? (
                    <Button
                      className="btn btn-success"
                      onClick={() =>
                        onClickInvoiceUpload(rowData, "displayInvoiceUpload")
                      }
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Upload{" "}
                      <i
                        className="pi pi-upload product-category-icon"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-danger"
                      onClick={() => handleInvoiceDownload(rowData)}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Download{" "}
                      <i
                        className="pi pi-download product-category-icon"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </Button>
                  ))}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Payment"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0].totalPaid !==
                  rowData.customerFileProcessJobDTOList[0].revenue && (
                  <div style={{ float: "left" }}>
                    <form onSubmit={handlePayment}>
                      <InputText
                        type="number"
                        id="amount"
                        name="remark"
                        value={input.amount}
                        onChange={(e) => {
                          setInputAmount(e.target.value);
                          setInputAmountError("");
                          if (
                            rowData?.customerFileProcessJobDTOList?.length > 0
                          ) {
                            setCustomerFileProcessJobId(
                              rowData.customerFileProcessJobDTOList[0].id
                            );
                            setBalanceAmount(
                              rowData.customerFileProcessJobDTOList[0].revenue.toFixed(
                                0
                              ) -
                                rowData.customerFileProcessJobDTOList[0].totalPaid.toFixed(
                                  0
                                )
                            );
                          }
                        }}
                        style={{ marginRight: 10 }}
                      />
                      <button type="submit" className="btn btn-primary">
                        Add
                      </button>
                    </form>
                  </div>
                )}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Payment Record"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {
                  <Button
                    className="btn btn-primary"
                    onClick={() =>
                      toggleItemExpansion(
                        rowData.customerFileProcessJobDTOList[0].id
                      )
                    }
                  >
                    View
                  </Button>
                }
                {paymentRecordView[
                  rowData.customerFileProcessJobDTOList[0].id
                ] && (
                  <div>
                    {paymentRecordData[
                      rowData.customerFileProcessJobDTOList[0].id
                    ]?.length > 0 ? (
                      <>
                        <table className="table table-borderless">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(() => {
                              let balance =
                                rowData.customerFileProcessJobDTOList[0]
                                  .revenue;
                              return paymentRecordData[
                                rowData.customerFileProcessJobDTOList[0].id
                              ].map((item) => {
                                balance -= item.amount;
                                return (
                                  <tr>
                                    <td>
                                      {formatDateWithTime(item.createdDate)}
                                    </td>
                                    <td>₹{item.amount}</td>
                                    <td key={item.id}>{balance.toFixed(0)}</td>
                                  </tr>
                                );
                              });
                            })()}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div className="product-list-detail">
                        <p style={{ color: "red" }}>
                          No Payment Record Data Available
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </span>
            )}
          ></Column>

          <Column
            field="frequency"
            header="Users"
            className="product-category table-design"
            body={(rowData) => {
              const userNames =
                rowData.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                  (jobUser) =>
                    `${jobUser.jobUser.user.firstName} ${jobUser.jobUser.user.lastName}`
                );

              return <span>{userNames.join(", ")}</span>;
            }}
          ></Column>
        </DataTable>
      </div>
      <div>{renderDialogInvoiceUpload()}</div>
    </div>
  );
}
