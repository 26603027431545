import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import Footer from "../layout/Footer";
import SideBar from "../layout/SideBar";
import Header from "../layout/Header";
import { getUserProfile } from '../services/userProfile';

const MainLayout = () => {
    const { isSidebarOpen } = useSelector((state) => state.auth);

    const [userProfileData, setUserProfileData] = useState("");

    useEffect(() => {
      getUserProfile()
        .then((response) => {
          if (!response.data.error) {
            const Res = response.data;
            setUserProfileData(Res);
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    }, []);
    return (
        <div className={isSidebarOpen ? "" : "toggle-sidebar"}>
            <Header />
            <SideBar userProfileData={userProfileData}/>
            <Outlet />
            <Footer />
        </div>
    )
};

export default MainLayout;
