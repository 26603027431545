import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getNewLead = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/lead-info",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const addNewLead = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/lead",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateNewLead = (data) => {
  return axios({
    method: "put",
    url: BASE_URL + "api/secure/v1/lead",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};
