import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getRole = () => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/role",
    headers: getHeaders(),
  });
};

export const addRole = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/role",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateRole = (data) => {
  return axios({
    method: "put",
    url: BASE_URL + "api/secure/v1/role",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};
