import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const postProcessJobPayment = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/process-job-payment",
    data: JSON.stringify(data),
    headers: getHeaders(),
  });
};

export const getProcessJobPayment = (customerFileProcessJobId) => {
  return axios({
    method: "post",
    url:
      BASE_URL +
      "api/secure/v1/process-job-payment/" +
      customerFileProcessJobId,
    // data: JSON.stringify(data),
    headers: getHeaders(),
  });
};
