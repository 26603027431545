import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Root from "../authLayout/Root";
import AuthLayout from "../authLayout/AuthLayout";
import Authentication from "../authLayout/Authentication";
import Area from "../pages/area/Area";
import AreaList from "../pages/area/AreaList";

import Dashboard from "../pages/Dashboard";
import UserList from "../pages/UserMaster/User/UserList";
import UserCreate from "../pages/UserMaster/User/UserCreate";
import RoleList from "../pages/UserMaster/Role/RoleList";
import RoleCreate from "../pages/UserMaster/Role/RoleCreate";
import JobList from "../pages/JobMaster/Job/JobList";
import JobCreate from "../pages/JobMaster/Job/JobCreate";
import JobUserCreate from "../pages/JobMaster/JobUser/JobUserCreate";
import JobUserList from "../pages/JobMaster/JobUser/JobUserList";
import JobRoleList from "../pages/role/JobRoleList";
import JobRoleCreate from "../pages/role/JobRoleCreate";
import ClientList from "../pages/Client/ClientList";
import ClientCreate from "../pages/Client/ClientCreate";
import CustomerFileList from "../pages/Customer/CustomerFileList";
import CustomerFileCreate from "../pages/Customer/CustomerFileCreate";
import CustomerFileJobList from "../pages/Customer/CustomerFileJobList";
import CustomerFileJobCreate from "../pages/Customer/CustomerFileJobCreate";
import ProcessCreate from "../pages/Process/ProcessCreate";
import RunningProcessList from "../pages/Process/RunningProcessList";
import ProcessEdit from "../pages/Process/ProcessEdit";
import ProfileView from "../pages/ProfileView";
import EmployeeRevenue from "../pages/EmployeeRevenue/EmployeeRevenue";
import EmployeeTimeSheet from "../pages/EmployeeTimeSheet/EmployeeTimeSheet";
import ClientListView from "../pages/Client/ClientListView";
import ClientEdit from "../pages/Client/ClientEdit";
import ListOfClient from "../pages/Reports/ListOfClient";
import PendingFees from "../pages/Reports/PendingFees";
import PendingTask from "../pages/Reports/PendingTask";
import MonthlyRevenue from "../pages/Reports/MonthlyRevenue";
import TaskProfitabilityStatement from "../pages/TaskProfitabilityStatement";
import ResourceAllocation from "../pages/ResourceAllocation/ResourceAllocation";
import DailyReporting from "../pages/dailyReporting/DailyReporting";
import DailyReports from "../pages/dailyReporting/DailyReports";
import AdminDailyReports from "../pages/dailyReporting/AdminDailyReports";
import NewLeadList from "../pages/NewLead/NewLeadList";
import NewLeadCreate from "../pages/NewLead/NewLeadCreate";
import CustomerFileEdit from "../pages/Customer/CustomerFileEdit";
import CommitmentAndAchievement from "../pages/Reports/CommitmentAndAchievement";
import AdminCommitmentEdit from "../pages/dailyReporting/AdminCommitmentEdit";
import TemplateConfig from "../pages/TemplateConfig/TemplateConfig";
import TemplateConfigView from "../pages/TemplateConfig/TemplateConfigView";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/" element={<Authentication />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="user-master/role/list" element={<RoleList />} />
        <Route path="user-master/role/create" element={<RoleCreate />} />
        <Route path="user-master/role/edit" element={<RoleCreate />} />
        <Route path="user-master/user/list" element={<UserList />} />
        <Route path="user-master/user/create" element={<UserCreate />} />
        <Route path="user-master/user/edit" element={<UserCreate />} />
        <Route path="job-master/jobs/list" element={<JobList />} />
        <Route path="job-master/jobs/create" element={<JobCreate />} />
        <Route path="job-master/jobs/edit" element={<JobCreate />} />
        <Route path="job-master/jobs-user/list" element={<JobUserList />} />
        <Route path="job-master/jobs-user/create" element={<JobUserCreate />} />
        <Route path="job-master/jobs-user/edit" element={<JobUserCreate />} />
        <Route path="role/list" element={<JobRoleList />} />
        <Route path="role/create" element={<JobRoleCreate />} />
        <Route path="role/edit" element={<JobRoleCreate />} />
        <Route path="client/list" element={<ClientList />} />
        <Route path="client/create" element={<ClientCreate />} />
        <Route path="client/edit" element={<ClientEdit />} />
        <Route path="client/view" element={<ClientListView />} />
        <Route path="customer-file/list" element={<CustomerFileList />} />
        <Route path="customer-file/create" element={<CustomerFileCreate />} />
        <Route path="customer-file/edit" element={<CustomerFileCreate />} />
        <Route
          path="customer-file-job/list"
          element={<CustomerFileJobList />}
        />
        <Route
          path="customer-file-job/create"
          element={<CustomerFileJobCreate />}
        />
        <Route
          path="customer-file-job/edit"
          element={<CustomerFileJobCreate />}
        />
        <Route path="process/create" element={<ProcessCreate />} />
        <Route path="process/edit" element={<ProcessEdit />} />
        <Route path="process/running/list" element={<RunningProcessList />} />
        <Route path="employee-revenue/list" element={<EmployeeRevenue />} />
        <Route path="employee-time-sheet" element={<EmployeeTimeSheet />} />
        <Route path="area/list" element={<AreaList />} />
        <Route path="area/create" element={<Area />} />
        <Route path="area/edit" element={<Area />} />
        <Route path="profile" element={<ProfileView />} />
        <Route path="list-of-customer" element={<ListOfClient />} />
        <Route path="pending-task" element={<PendingTask />} />
        <Route path="pending-fees" element={<PendingFees />} />
        <Route path="monthly-revenue" element={<MonthlyRevenue />} />
        <Route
          path="task-profitability-statement"
          element={<TaskProfitabilityStatement />}
        />
        <Route path="task-allocation" element={<ResourceAllocation />} />
        <Route path="daily-reporting" element={<DailyReporting />} />
        <Route path="daily-reports" element={<DailyReports />} />
        <Route path="admin-daily-reports" element={<AdminDailyReports />} />
        <Route path="new-lead" element={<NewLeadList />} />
        <Route path="new-lead-create" element={<NewLeadCreate />} />
        <Route path="customer-file-edit" element={<CustomerFileEdit />} />
        <Route
          path="commitment-and-achievement"
          element={<CommitmentAndAchievement />}
        />
        <Route path="commitment-edit" element={<AdminCommitmentEdit />} />
        <Route path="template-config" element={<TemplateConfig />} />
        <Route path="template-config-view" element={<TemplateConfigView />} />
      </Route>
      <Route path="logout" element={<Logout />} />
    </Route>
  )
);

export default router;

// jobUsers 
// "USER_ACCOUNT":
// "CUSTOMER_FILE":
// "CUSTOMER_FILE_PROCESS":