import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link } from "react-router-dom";
import { errorMessage } from "../../../utils/alertMessage";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { getJobUser } from "../../../services/JobMasterJobsUser";
import "../../../assets/DataView/DataViewDemo.css";
import "../../../assets/DataView/index.css";
import "../../../assets/css/dialogDesign.css";
import { InputText } from "primereact/inputtext";

export default function JobUserList() {
  const [products, setProducts] = useState(null);
  const [layout, setLayout] = useState("list");
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [showData, setShowData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const onClick = (data) => {
    setShowData(data);
    setDisplayResponsive(true);
  };

  const onHide = () => {
    setDisplayResponsive(false);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  }

  const fetchData = () => {
    getJobUser()
      .then((response) => {
        if (!response.data.error) {
          setProducts(response.data.reverse());
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  // Update window width on resize
  useEffect(() => {
    fetchData();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

   const handleSearchInputChange = (event) => {
     const searchQueryValue = event.target.value;
     const filteredProducts = products.filter(
       (p) =>
         p.mvnJobDTO.name
           .toLowerCase()
           .includes(searchQueryValue.toLowerCase())
     );
     setFilteredProducts(filteredProducts);
     setSearchQuery(searchQueryValue);
   };

  const renderDialog = () => (
    <Dialog
      header="User"
      visible={displayResponsive}
      onHide={() => onHide()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <div className="user-details-dialog">
          <div className="user-detail-row">
            <span className="user-detail-label">Full Name:</span>
            <span className="user-detail-value">
              {showData.firstName + " " + showData.lastName}
            </span>
          </div>
          <div className="user-detail-row">
            <span className="user-detail-label">Email:</span>
            <span className="user-detail-value">{showData.email}</span>
          </div>
          <div className="user-detail-row">
            <span className="user-detail-label">Mobile:</span>
            <span className="user-detail-value">
              {showData.mobile || "N/A"}
            </span>
          </div>
          <div className="user-detail-row">
            <span className="user-detail-label">Role:</span>
            <span className="user-detail-value">{showData?.role}</span>
          </div>
          {/* Add more details as needed */}
        </div>
      </div>
    </Dialog>
  );

  const renderListItem = (data) => {
    return (
      <div className="col-12 backgroundDesign">
        <div className="product-list-item">
          <div className="product-list-detail">
            <div className="product-name mb-2">{data.mvnJobDTO.name}</div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
              {data.userDTOList.map((item, index) => (
                <div
                  key={index}
                  style={{ flex: "0 0 22%", boxSizing: "border-box" }}
                >
                  <span className="product-category">
                    <Button
                      className="btn btn-success"
                      onClick={() => onClick(item.user, "displayResponsive")}
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <i className="pi pi-user product-category-icon"></i>
                      {truncateText(
                        item.user.firstName + " " + item.user.lastName,
                        16
                      )}
                    </Button>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="product-list-action">
            <span className="product-price"></span>
            <Link
              to={"/job-master/jobs-user/edit"}
              state={data}
              className="move-btn move-btn-re center-text"
            >
              Edit <i className="bi bi-pencil-square"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderGridItem = (data) => {
    return (
      <div className="col-12 md:col-4 backgroundDesign">
        <div className="product-grid-item">
          <div className="product-grid-item-top">
            <div className="product-name mb-2">{data.mvnJobDTO.name}</div>
          </div>
          <div className="product-grid-item-content">
            <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
              {data.userDTOList.map((item, index) => (
                <div
                  key={index}
                  style={{ flex: "0 0 100%", boxSizing: "border-box" }}
                >
                  <span className="product-category">
                    <Button
                      className="btn btn-success"
                      onClick={() => onClick(item.user, "displayResponsive")}
                      style={{ width: "100%", textAlign: "left" }}
                    >
                      <i className="pi pi-user product-category-icon"></i>
                      {truncateText(
                        item.user.firstName + " " + item.user.lastName,
                        16
                      )}
                    </Button>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="product-grid-item-bottom mt-2">
            <span className="product-price"></span>
            <Link
              to={"/job-master/jobs-user/edit"}
              state={data}
              className="move-btn move-btn-re center-text"
            >
              Edit <i className="bi bi-pencil-square"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }

    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
    return (
      <div className="grid grid-nogutter">
        <div className="col-6" style={{ textAlign: "left" }}>
          <InputText
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
        </div>
        <div className="col-6" style={{ display: "none", textAlign: "right" }}>
          <DataViewLayoutOptions
            layout={layout}
            onChange={(e) => setLayout(e.value)}
          />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Jobs User List</h5>
                  <div className="col-md-2" style={{ alignSelf: "center" }}>
                    <Link
                      to="/job-master/jobs-user/create"
                      className="move-btn move-btn-su center-text"
                    >
                      Create
                    </Link>
                  </div>
                </div>
                <div className="dataview-demo">
                  <div className="card">
                    <DataView
                      value={filteredProducts || products}
                      layout={layout}
                      header={header}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
                    />
                  </div>
                  <div>{renderDialog()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
