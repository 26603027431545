import React, { useEffect, useState } from "react";
import { getEmployeeReportList } from "../../services/dailyReporting";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Link, useLocation } from "react-router-dom";
import { formatDate } from "../../constants/FormateDate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "react-bootstrap";
import { getUserProfile } from "../../services/userProfile";
import { getEmployeeReportTemplate } from "../../services/TemplateConfig";
import { InputText } from "primereact/inputtext";

const TemplateConfigView = () => {
  const { state } = useLocation();
  let userId = state?.userId;

  const [templateView, setTemplateView] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);

  const [profile, setProfile] = useState({});

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setProfile(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
    const fetchData = async () => {
      try {
        const response = await getEmployeeReportTemplate();
        if (!response.data.error) {
          const Res = response.data;

          setTemplateView(Res);
        }
      } catch (err) {
        errorMessage();
      }
    };
    fetchData();
  }, []);

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = templateView?.filter(
      (p) =>
        p.reportType.toLowerCase().includes(searchQueryValue.toLowerCase()) ||
        p.template.toLowerCase().includes(searchQueryValue.toLowerCase())
    );
    setFilteredProducts(
      filteredProducts)
    setSearchQuery(searchQueryValue);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Template Config List</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <div>
                    <InputText
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: 10,
                      // justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    <div className="col-md-2" style={{ alignSelf: "center" }}>
                      <Link
                        to="/template-config"
                        className="move-btn move-btn-su center-text"
                      >
                        Create
                      </Link>
                    </div>
                  </div>
                </div>
                <DataTable
                  value={templateView || filteredProducts}
                  paginator
                  paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  dataKey="id"
                  className="p-datatable-customers client-table-design"
                  resizableColumns
                  columnResizeMode="fit"
                >
                  <Column
                    field="name"
                    header="Created Date"
                    resizeable={false}
                    className="product-category table-design"
                    body={(rowData) => (
                      <span>{formatDate(rowData.createdDate)}</span>
                    )}
                  ></Column>
                  <Column
                    field="name"
                    header="Report Type"
                    resizeable={false}
                    className="product-category table-design"
                    body={(rowData) => (
                      <span className="word-wrap">{rowData.reportType}</span>
                    )}
                  ></Column>
                  <Column
                    field="template"
                    header="Template"
                    resizeable={false}
                    className="product-category table-design"
                    body={(rowData) => <span>{rowData.template}</span>}
                  ></Column>
                  <Column
                    field="templateCampaignName"
                    header="Template Campaign Name"
                    resizeable={false}
                    className="product-category table-design"
                    body={(rowData) => (
                      <span>{rowData.templateCampaignName}</span>
                    )}
                  ></Column>
                  <Column
                    field="templateAttributeSequence"
                    header="Template Attribute Sequence"
                    resizeable={false}
                    className="product-category table-design"
                    body={(rowData) => (
                      <span>{rowData.templateAttributeSequence}</span>
                    )}
                  ></Column>

                  <Column
                    field="name"
                    header="Edit"
                    resizeable={false}
                    className="product-category table-design"
                    body={(rowData) => (
                      <span>
                        <Link
                          className="btn"
                          to={"/template-config"}
                          // state={item}
                          state={rowData}
                        >
                          <i
                            className="bi bi-pencil"
                            style={{ fontSize: 15, color: "blue" }}
                          ></i>
                        </Link>
                      </span>
                    )}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TemplateConfigView;
