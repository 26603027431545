import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const costCalculation = (data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v2/customer-job-process/cost-calculation",
    data: JSON.stringify(data),
    headers: getHeaders(),
  });
};
