import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link } from "react-router-dom";
import { getUser } from "../../../services/userMasterUser";
import { errorMessage } from "../../../utils/alertMessage";
import "../../../assets/DataView/DataViewDemo.css";
import "../../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function UserList() {
  const [products, setProducts] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);

  const fetchData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          setProducts(
            response.data.sort((a, b) =>
              a.firstName
                .trim()
                .localeCompare(b.firstName.trim())
            )
          );
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter(
      (p) =>
        p.firstName.toLowerCase().includes(searchQueryValue.toLowerCase()) ||
        p.lastName.toLowerCase().includes(searchQueryValue.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">User List</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <InputText
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <div className="col-md-2" style={{ alignSelf: "center" }}>
                    <Link
                      to="/user-master/user/create"
                      className="move-btn move-btn-su center-text"
                    >
                      Create
                    </Link>
                  </div>
                </div>
                {/*<div className="dataview-demo">
                  <div className="card">
                    <DataView
                      value={filteredProducts || products}
                      layout={layout}
                      header={header}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
                    />
                  </div>
                </div>*/}
                <DataTable
                  value={filteredProducts || products}
                  paginator
                  paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  dataKey="id"
                  className="p-datatable-customers client-table-design"
                  resizableColumns
                  columnResizeMode="fit"
                >
                  <Column
                    field="name"
                    header="Name"
                    className="product-category table-design"
                    body={(rowData) => (
                      <span>{rowData.firstName + " " + rowData.lastName}</span>
                    )}
                  ></Column>
                  <Column
                    field="email"
                    header="Email"
                    className="product-category table-design"
                  ></Column>
                  <Column
                    field="userLoginId"
                    header="User Id"
                    className="product-category table-design"
                  ></Column>
                  <Column
                    field="mobile"
                    header="Mobile No."
                    className="product-category table-design"
                  ></Column>
                  <Column
                    field="role"
                    header="Role"
                    className="product-category table-design"
                  ></Column>
                  <Column
                    field="cost"
                    header="Cost"
                    className="product-category table-design"
                    body={(rowData) => <span>{"₹ " + rowData.cost}</span>}
                  ></Column>
                  <Column
                    field="workingDays"
                    header="Working Days"
                    className="product-category table-design"
                  ></Column>
                  <Column
                    field="data.customerFileProcessJobDTOList[0].status"
                    header="Action"
                    className="product-category table-design"
                    body={(rowData) => (
                      <span>
                        {
                          <Link
                            to={"/user-master/user/edit"}
                            state={rowData}
                            className="move-btn-small move-btn-re center-text"
                          >
                            <i className="bi bi-pencil-square"></i>
                          </Link>
                        }
                      </span>
                    )}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
