import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import { deleteArea, getAreaList } from '../../services/area';
import { errorMessage, successMessage } from '../../utils/alertMessage';

export default function AreaList() {
  const [data, setData] = useState([]);
  // const fetchData = () => {
  //   getAreaList()
  //     .then(result => {
  //       if (result.data.error === false) {
  //         setData(result.data.result);
  //       }
  //     });
  // }
  // useEffect(() => fetchData(), []);

  // const handleRemove = (id) => {
  //   deleteArea(id)
  //     .then(result => {
  //       if (result.data.error === false) {
  //         successMessage();
  //         fetchData();
  //       } else {
  //         errorMessage();
  //       }
  //     })
  // }

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Area List</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Title</th>
                      <th>Edit</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.area}</td>
                        <td>
                          <Link
                            to={"/area/edit"}
                            state={{ item }}
                            className="move-btn move-btn-re center-text"
                          >
                            Edit <i className="bi bi-pencil-square"></i>
                          </Link>
                        </td>
                        <td>
                          <button
                            // onClick={() => handleRemove(item.id)}
                            className="btn btn-danger"
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

