import axios from "axios";
import { BASE_URL } from "../constants";
import getHeaders from "../constants/headers";

export const getCustomerFile = (id) => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/customer/" + id + "/file",
    headers: getHeaders(),
  });
};

export const getCustomerFileByFileId = (customerId, fileId) => {
  return axios({
    method: "get",
    url: BASE_URL + "api/secure/v1/customer/" + customerId + "/file/" + fileId,
    headers: getHeaders(),
  });
};

export const addCustomerFile = (id,data) => {
  return axios({
    method: "post",
    url: BASE_URL + "api/secure/v1/customer/" + id + "/file",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateCustomerFile = (id,data) => {
  return axios({
    method: "put",
    url: BASE_URL + "api/secure/v1/customer/" + id + "/file",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};
