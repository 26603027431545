import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addEmployeeReport,
  updateEmployeeReport,
} from "../../services/dailyReporting";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Calendar } from "primereact/calendar";

const AdminCommitmentEdit = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let userId = state?.userId;
  let edit = state?.edit;
  let reportType = state?.edit.reportType;
  const [input, setInput] = useState({
    todayCommitment: "",
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (edit) {
      setInput({
        todayCommitment: edit.data.todayCommitment || "",
      });
    }
  }, [edit]);

  const validate = () => {
    const newErrors = {};

    if (!input.todayCommitment) {
      newErrors.todayCommitment = "Today's Commitment can never be empty";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    const updatedData = {
      createdDate: new Date().toISOString(),
      data: {
        ...edit.data,
        todayCommitment: input.todayCommitment,
      },
      reportType: reportType,
      user: {
        id: userId,
      },
      id: edit?.id,
    };

    try {
      let response;

      response = await updateEmployeeReport(updatedData); // Update the API call for editing

      if (!response.data.error) {
        if (response.data.success === false) {
          errorMessage(response.data.message);
        } else {
          successMessage(response.data.message);
        }
        setInput({
          todayCommitment: "",
        });
        navigate("/commitment-and-achievement", {
          state: userId,
        });
      } else {
        errorMessage(response.data.errors.errors[0].message);
      }
    } catch (err) {
      errorMessage();
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Edit Today's Commitment</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Today's Commitment</label>
                        <textarea
                          type="number"
                          name="todayCommitment"
                          value={input.todayCommitment}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              todayCommitment: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.todayCommitment && (
                          <div className="text-danger">
                            {errors.todayCommitment}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 text-center">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success ms-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AdminCommitmentEdit;
