import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addEmployeeReport, updateEmployeeReport } from "../../services/dailyReporting";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";

const LoanBackOffice = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let item = state?.item;
  let userId = state?.userId;
  let edit = state?.edit;
  const [checked, setChecked] = useState(false);

  let initialStartDate = new Date();
  initialStartDate.setDate(initialStartDate.getDate() - 1); // subtract 7 days to current date

  const [input, setInput] = useState({
    createdDate: new Date(),
    todayCommitment: "",
    new_file_login: "",
    new_file_login_amount_lacs: "",
    file_sanction: "",
    file_sanction_amount_lacs: "",
    file_disburse: "",
    file_disburse_amount_lacs: "",
  });

  useEffect(() => {
    if (edit) {
      setInput({
        createdDate: new Date(edit.createdDate) || "",
        todayCommitment: edit.data.todayCommitment || "",
        new_file_login: edit.data.new_file_login || "",
        new_file_login_amount_lacs: edit.data.new_file_login_amount_lacs || "",
        file_sanction: edit.data.file_sanction || "",
        file_sanction_amount_lacs: edit.data.file_sanction_amount_lacs || "",
        file_disburse: edit.data.file_disburse || "",
        file_disburse_amount_lacs: edit.data.file_disburse_amount_lacs || "",
      });
    }
  }, [edit]);

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (!input.createdDate) {
      newErrors.createdDate = "Date of Creation can never be empty";
    }

    if (!input.todayCommitment) {
      newErrors.todayCommitment = "Today's Commitment can never be empty";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEdit = () => {
    const newErrors = {};

    if (!input.createdDate) {
      newErrors.createdDate = "Date of Creation can never be empty";
    }

    if (!input.todayCommitment) {
      newErrors.todayCommitment = "Today's Commitment can never be empty";
    }
    if (!input.new_file_login) {
      newErrors.new_file_login = "New File Login is required";
    } else if (input.new_file_login < 0) {
      newErrors.new_file_login = "New File Login cannot be less than 0";
    }

    if (!input.new_file_login_amount_lacs) {
      newErrors.new_file_login_amount_lacs =
        "New File Login Amount Lacs is required";
    } else if (input.new_file_login_amount_lacs < 0) {
      newErrors.new_file_login_amount_lacs =
        "New File Login Amount Lacs cannot be less than 0";
    }

    if (!input.file_sanction) {
      newErrors.file_sanction = "File Sanction is required";
    } else if (input.file_sanction < 0) {
      newErrors.file_sanction = "File Sanction cannot be less than 0";
    }

    if (!input.file_sanction_amount_lacs) {
      newErrors.file_sanction_amount_lacs =
        "File Sanction Amount Lacs is required";
    } else if (input.file_sanction_amount_lacs < 0) {
      newErrors.file_sanction_amount_lacs =
        "File Sanction Amount Lacs cannot be less than 0";
    }

    if (!input.file_disburse) {
      newErrors.file_disburse = "File Disburse is required";
    } else if (input.file_disburse < 0) {
      newErrors.file_disburse = "File Disburse cannot be less than 0";
    }

    if (!input.file_disburse_amount_lacs) {
      newErrors.file_disburse_amount_lacs =
        "File Disburse Amount Lacs is required";
    } else if (input.file_disburse_amount_lacs < 0) {
      newErrors.file_disburse_amount_lacs =
        "File Disburse Amount Lacs cannot be less than 0";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (edit) {
      if (!validateEdit()) {
        return;
      }
    } else {
      if (!validate()) {
        return;
      }
    }
    const { createdDate, ...filteredInput } = input;
    filteredInput.sendReportStatus = checked ? "yes" : "no";
    const data = {
      createdDate: new Date(input.createdDate).toISOString(),
      data: filteredInput,
      reportType: item,
      user: {
        id: userId,
      },
    };

    const updateData = {
      createdDate: new Date(input.createdDate).toISOString(),
      data: filteredInput,
      reportType: item,
      user: {
        id: userId,
      },
      id: edit?.id,
    };

    try {
      let response;
      if (edit) {
        response = await updateEmployeeReport(updateData); // Update the API call for editing
      } else {
        response = await addEmployeeReport(data);
      }
      if (!response.data.error) {
        if (response.data.success === false) {
          errorMessage(response.data.message);
        } else {
          successMessage(response.data.message);
        }
        setInput({
          createdDate: "",
          todayCommitment: "",
          new_file_login: "",
          new_file_login_amount_lacs: "",
          file_sanction: "",
          file_sanction_amount_lacs: "",
          file_disburse: "",
          file_disburse_amount_lacs: "",
        });
        navigate("/daily-reports", {
          state: { userId },
        });
      } else {
        errorMessage(response.data.errors.errors[0].message);
      }
    } catch (err) {
      errorMessage();
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  {item.replace(/_/g, " ")} {edit ? "Edit" : "Create"}
                </h5>
                <h6
                  className="card-title"
                  style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                >
                  Today's Commitment
                </h6>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="createdDate">Date of Creation</label>
                        <Calendar
                          id="createdDate"
                          name="createdDate"
                          value={input.createdDate}
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              createdDate: e.target.value,
                            });
                          }}
                          showIcon
                          maxDate={new Date()}
                          minDate={initialStartDate}
                          // disabled={true}
                        />
                        <div className="text-danger">{errors.createdDate}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-1">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Today's Commitment</label>
                        <textarea
                          type="number"
                          name="todayCommitment"
                          value={input.todayCommitment}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              todayCommitment: e.target.value,
                            });
                          }}
                          className="form-control"
                        />
                        {errors.todayCommitment && (
                          <div className="text-danger">
                            {errors.todayCommitment}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {edit && (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          flex: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6
                          className="card-title"
                          style={{ fontSize: 18, fontWeight: 700, padding: 1 }}
                        >
                          Today's Achievement
                        </h6>
                        <div
                          style={{
                            marginTop: 20,
                            display: "flex",
                            flex: "row",
                          }}
                        >
                          <label style={{ marginRight: 10 }}>
                            Send This Report To WhatsApp
                          </label>
                          <InputSwitch
                            checked={checked}
                            onChange={(e) => setChecked(e.value)}
                          />
                        </div>
                      </div>

                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>New File Login</label>
                            <input
                              type="number"
                              name="new_file_login"
                              value={input.new_file_login}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  new_file_login: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.new_file_login && (
                              <div className="text-danger">
                                {errors.new_file_login}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Amount (Lacs)</label>
                            <input
                              type="number"
                              name="new_file_login_amount_lacs"
                              value={input.new_file_login_amount_lacs}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  new_file_login_amount_lacs: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.new_file_login_amount_lacs && (
                              <div className="text-danger">
                                {errors.new_file_login_amount_lacs}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>File Sanction</label>
                            <input
                              type="number"
                              name="file_sanction"
                              value={input.file_sanction}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  file_sanction: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.file_sanction && (
                              <div className="text-danger">
                                {errors.file_sanction}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Amount (Lacs)</label>
                            <input
                              type="number"
                              name="file_sanction_amount_lacs"
                              value={input.file_sanction_amount_lacs}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  file_sanction_amount_lacs: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.file_sanction_amount_lacs && (
                              <div className="text-danger">
                                {errors.file_sanction_amount_lacs}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row g-3 mb-1">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>File Disburse</label>
                            <input
                              type="number"
                              name="file_disburse"
                              value={input.file_disburse}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  file_disburse: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.file_disburse && (
                              <div className="text-danger">
                                {errors.file_disburse}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Amount (Lacs)</label>
                            <input
                              type="number"
                              name="file_disburse_amount_lacs"
                              value={input.file_disburse_amount_lacs}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  file_disburse_amount_lacs: e.target.value,
                                });
                              }}
                              className="form-control"
                            />
                            {errors.file_disburse_amount_lacs && (
                              <div className="text-danger">
                                {errors.file_disburse_amount_lacs}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="row g-3 text-center">
                    <div className="col-12">
                      <button type="submit" className="btn btn-success ms-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LoanBackOffice;
