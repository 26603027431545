export const setUserData = ({ token }) => {
  localStorage.setItem("adminToken", token);
  // localStorage.setItem("userId", userId);
};

export const getUserData = () => {
  const token = localStorage.getItem("adminToken");
  // const userId = localStorage.getItem("userId");
  // return { token, userId };
  return { token};
}

export const removeUserData = () => {
  localStorage.removeItem("adminToken");
  // localStorage.removeItem("userId");
};