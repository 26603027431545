import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Link } from "react-router-dom";
import { DataView } from "primereact/dataview";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getJobProcess } from "../../services/process";
import { formatDate } from "../../constants/FormateDate";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import "../../assets/css/AccordionDemo.css";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { getUser } from "../../services/userMasterUser";
import { errorMessage } from "../../utils/alertMessage";
import { getStatus } from "../../services/getStatus";
import Table from "react-bootstrap/Table";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function JobProcessAdminList({ isFilterVisible }) {
  const [products, setProducts] = useState(null);
  const [productUsers, setProductUsers] = useState(null);
  const [statusData, setStatusData] = useState(null);
  const [layout, setLayout] = useState("list");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const fetchCustomerJobProcess = (data) => {
    getJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setProducts(Res.sort((a, b) =>
              a.customerFileProcess.customerFile.customer.name
                .trim()
                .localeCompare(b.customerFileProcess.customerFile.customer.name.trim())
            ));
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  const fetchUserData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const filteredData = response.data.map((users) => ({
            label: users.firstName + " " + users.lastName,
            value: users.id,
          }));
          setProductUsers(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  const fetchStatus = (data) => {
    getStatus(data)
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const Res = response.data;
          const transformedStatusData = Res.map((item) => ({
            label: item === "TO_BE_STARTED" ? "PENDING" : item, // Change label for status with id 2
            value: item,
          }));
          setStatusData(transformedStatusData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  useEffect(() => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: [],
      users: [],
    };
    fetchCustomerJobProcess(data);
    fetchUserData();
    fetchStatus("CUSTOMER_FILE_PROCESS");
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [inputData, setInputData] = useState({
    startDate: new Date(),
    endDate: new Date(),
    types: [],
    status: [],
    users: [],
  });

  useEffect(() => {
    const data = inputData;
    fetchCustomerJobProcess(data);
  }, [inputData]);

  const [jobsData, setJobsData] = useState([
    { label: "Compliance Date", value: "compliance_date" },
    { label: "No. of days", value: "no_of_days" },
  ]);

  const resetFilters = () => {
    setInputData({
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: [],
      users: [],
    });
    setSearchQuery("");
  };

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((p) =>
      p.customerFileProcess.customerFile.customer.name
        .toLowerCase()
        .includes(searchQueryValue.toLowerCase())
    );
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  const renderListItem = (data, index) => {
    var startDateString = formatDate(
      data.customerFileProcessJobDTOList[0].startOn
    );
    var startDate = new Date(startDateString);

    // Parse end date
    var endDateString = formatDate(data.customerFileProcessJobDTOList[0].endOn);
    var endDate = new Date(endDateString);

    // Calculate the difference in days
    var differenceInTime = endDate.getTime() - startDate.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return (
      <div className="col-12">
        <Accordion className="accordion-custom" activeIndex={index}>
          <AccordionTab
            header={
              <React.Fragment>
                {/*<div className="product-list-item backgroundDesign">
                  <div className="product-list-detail">
                    <div className="product-name">
                      <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                      {data.customerFileProcess.customerFile.customer.name}
                    </div>
                    <div className="product-category">
                      <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>
                      {
                        data.customerFileProcess.customerFile.customer
                          .authorizedPersonMobile
                      }
                    </div>
                    <span className="product-category">
                      <i className="pi pi-file product-category-icon blueColorIcon"></i>
                      {data.customerFileProcess.customerFile.fileName} -{" "}
                      {data.customerFileProcess.customerFile.fileNumber}
                    </span>
                  </div>
                    </div>*/}

                <Table className="backgroundDesign">
                  <tr>
                    <td
                      style={{
                        width: "30%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      <div className="rowDiv">
                        {data.customerFileProcess.customerFile.customer.name}
                      </div>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {
                        data.customerFileProcess.customerFile.customer
                          .authorizedPersonMobile
                      }
                    </td>
                    <td
                      style={{
                        width: "30%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {data.customerFileProcess.customerFile.fileName} -{" "}
                      {data.customerFileProcess.customerFile.fileNumber}
                    </td>
                    <td
                      style={{
                        width: "20%",
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {
                        data.customerFileProcessJobDTOList[0].customerFileJob
                          .mvnJob.name
                      }
                    </td>
                  </tr>
                </Table>
              </React.Fragment>
            }
          >
            <div className="product-list-action">
              <div className="product-category" style={{ float: "right" }}>
                Revenue: ₹{" "}
                {data.customerFileProcessJobDTOList[0].revenue.toFixed(0)}
              </div>
            </div>
            <div className="product-list-item">
              <div className="product-list-detail">
                <div className="product-category">
                  <i className="pi pi-briefcase product-category-icon blueColorIcon"></i>{" "}
                  {
                    data.customerFileProcessJobDTOList[0].customerFileJob.mvnJob
                      .name
                  }
                </div>
                <div className="product-category">
                  <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].startOn)} -{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].endOn)}
                </div>
                <div className="product-category">
                  <i className="pi pi-calendar product-category-icon blueColorIcon"></i>{" "}
                  Total Days: {differenceInDays}
                </div>

                <div className="product-category">
                  <i className="pi pi-box product-category-icon orangeColorIcon"></i>{" "}
                  Status: {data.customerFileProcessJobDTOList[0].status}
                  {data.customerFileProcessJobDTOList[0].timeBarred &&
                    " Time Barred"}
                </div>
                {/*<div className="product-category">
                  <i class="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                  Estimated Cost:{" "}
                  {data.customerFileProcessJobDTOList[0].totalCost.toFixed(0)}
                  </div>*/}
                {data.customerFileProcessJobDTOList[0].status ===
                  "COMPLETED" && (
                  <div>
                    <div className="product-category">
                      <i className="pi pi-calendar product-category-icon blueColorIcon"></i>
                      Completed On:{" "}
                      {formatDate(
                        data.customerFileProcessJobDTOList[0].completedOn
                      )}
                    </div>

                    <div className="product-category">
                      <i class="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                      Actual Cost:{" "}
                      {data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                        0
                      )}
                    </div>

                    {data.customerFileProcessJobDTOList[0].revenue >
                    data.customerFileProcessJobDTOList[0].actualCost ? (
                      <Link
                        style={{ justifyContent: "center", color: "green" }}
                      >
                        + ₹{" "}
                        {data.customerFileProcessJobDTOList[0].revenue.toFixed(
                          0
                        ) -
                          data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                            0
                          )}{" "}
                        Profit
                      </Link>
                    ) : data.customerFileProcessJobDTOList[0].revenue <
                      data.customerFileProcessJobDTOList[0].actualCost ? (
                      <Link style={{ justifyContent: "center", color: "red" }}>
                        - ₹{" "}
                        {data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                          0
                        ) -
                          data.customerFileProcessJobDTOList[0].revenue.toFixed(
                            0
                          )}{" "}
                        Loss
                      </Link>
                    ) : (
                      <Link style={{ justifyContent: "center", color: "blue" }}>
                        On Going
                      </Link>
                    )}
                  </div>
                )}
              </div>
              <div className="product-list-detail">
                {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                  (jobUser, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                        <span className="product-category">
                          {jobUser.jobUser.user.firstName}{" "}
                          {jobUser.jobUser.user.lastName}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="product-list-detail">
                {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                  (jobUser, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>{" "}
                        <span className="product-category">
                          {jobUser.jobUser.user.mobile}
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const renderGridItem = (data, index) => {
    var startDateString = formatDate(
      data.customerFileProcessJobDTOList[0].startOn
    );
    var startDate = new Date(startDateString);

    // Parse end date
    var endDateString = formatDate(data.customerFileProcessJobDTOList[0].endOn);
    var endDate = new Date(endDateString);

    // Calculate the difference in days
    var differenceInTime = endDate.getTime() - startDate.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return (
      <div className="col-12">
        <Accordion className="accordion-custom" activeIndex={index}>
          <AccordionTab
            header={
              <React.Fragment>
                <div className="product-grid-item backgroundDesign">
                  <div className="product-list-detail">
                    <div className="product-category">
                      <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                      {data.customerFileProcess.customerFile.customer.name}
                    </div>
                    <div className="product-category">
                      <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>
                      {
                        data.customerFileProcess.customerFile.customer
                          .authorizedPersonMobile
                      }
                    </div>
                    <div className="product-category">
                      <span className="product-category">
                        <i className="pi pi-file product-category-icon blueColorIcon"></i>
                        {data.customerFileProcess.customerFile.fileName}
                      </span>
                    </div>
                    <span className="product-category">
                      <i className="pi pi-file product-category-icon blueColorIcon"></i>
                      {
                        data.customerFileProcessJobDTOList[0].customerFileJob
                          .mvnJob.name
                      }
                    </span>
                  </div>
                </div>
              </React.Fragment>
            }
          >
            <div
              className="product-list-action mb-2"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="product-category">
                Revenue: ₹{" "}
                {data.customerFileProcessJobDTOList[0].revenue.toFixed(0)}
              </div>
            </div>
            <div className="product-grid-item">
              <div className="">
                <div className="product-category">
                  <i className="pi pi-briefcase product-category-icon blueColorIcon"></i>{" "}
                  {
                    data.customerFileProcessJobDTOList[0].customerFileJob.mvnJob
                      .name
                  }
                </div>
                <div className="product-category">
                  <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].startOn)} -{" "}
                  {formatDate(data.customerFileProcessJobDTOList[0].endOn)}
                </div>
                <div className="product-category">
                  <i className="pi pi-calendar product-category-icon blueColorIcon"></i>{" "}
                  Total Days: {differenceInDays}
                </div>
                <div className="product-category">
                  <i className="pi pi-box product-category-icon orangeColorIcon"></i>{" "}
                  Status: {data.customerFileProcessJobDTOList[0].status}{" "}
                  {data.customerFileProcessJobDTOList[0].timeBarred &&
                    " Time Barred"}
                </div>
              </div>
              {/*<div className="product-category">
                <i class="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                Estimated Cost:{" "}
                {data.customerFileProcessJobDTOList[0].totalCost.toFixed(0)}
                  </div>*/}
              {data.customerFileProcessJobDTOList[0].status === "COMPLETED" && (
                <div>
                  <div className="product-category">
                    <i className="pi pi-calendar product-category-icon orangeColorIcon"></i>{" "}
                    Completed On:{" "}
                    {formatDate(
                      data.customerFileProcessJobDTOList[0].completedOn
                    )}
                  </div>

                  <div className="product-category">
                    <i class="bi bi-currency-rupee product-category-icon blueColorIcon"></i>
                    Actual Cost:{" "}
                    {data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                      0
                    )}
                  </div>

                  {data.customerFileProcessJobDTOList[0].revenue >
                  data.customerFileProcessJobDTOList[0].actualCost ? (
                    <Link style={{ justifyContent: "center", color: "green" }}>
                      + ₹{" "}
                      {data.customerFileProcessJobDTOList[0].revenue.toFixed(
                        0
                      ) -
                        data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                          0
                        )}{" "}
                      Profit
                    </Link>
                  ) : data.customerFileProcessJobDTOList[0].revenue <
                    data.customerFileProcessJobDTOList[0].actualCost ? (
                    <Link
                      className="p-button-danger"
                      style={{ justifyContent: "center", color: "red" }}
                    >
                      - ₹{" "}
                      {data.customerFileProcessJobDTOList[0].actualCost.toFixed(
                        0
                      ) -
                        data.customerFileProcessJobDTOList[0].revenue.toFixed(
                          0
                        )}{" "}
                      Loss
                    </Link>
                  ) : (
                    <Link
                      className="p-button-info"
                      style={{ justifyContent: "center", color: "blue" }}
                    >
                      On Going
                    </Link>
                  )}
                </div>
              )}
              <div
                style={{ border: "1px solid #1b4077", marginBottom: "5px" }}
              ></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="">
                  {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                    (jobUser, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                          <span className="product-category">
                            {jobUser.jobUser.user.firstName}{" "}
                            {jobUser.jobUser.user.lastName}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="">
                  {data.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                    (jobUser, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <i className="pi pi-mobile product-category-icon orangeColorIcon"></i>{" "}
                          <span className="product-category">
                            {jobUser.jobUser.user.mobile}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  const renderHeader = () => {
     const layout = windowWidth > 460 ? "list" : "grid";
    return (
      <div>
        {isFilterVisible && (
          <div>
            <div className="row">
              <div
                className="col-md-4 col-sm-12 mb-2"
                style={{ textAlign: "left" }}
              >
                <InputText
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  style={{ width: "100%" }}
                  onChange={handleSearchInputChange}
                />
              </div>
              <div className="col-md-4 col-sm-12 mb-2">
                <Calendar
                  id="startDate"
                  name="startDate"
                  value={inputData.startDate}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      startDate: e.target.value,
                    });
                  }}
                  showIcon
                  required
                />
              </div>
              <div
                className="col-md-4 col-sm-12 mb-2"
                style={{ textAlign: "right" }}
              >
                <Calendar
                  id="startDate"
                  name="startDate"
                  value={inputData.endDate}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      endDate: e.target.value,
                    });
                  }}
                  showIcon
                  required
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-3 col-sm-12 mb-2"
                style={{ textAlign: "left" }}
              >
                <Dropdown
                  value={inputData.types[0]}
                  options={jobsData}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      types: [e.value], // Ensure it's always an array
                    });
                  }}
                  placeholder="Select a type"
                />
              </div>
              <div
                className="col-md-3 col-sm-12 mb-2"
                style={{ textAlign: "left" }}
              >
                <Dropdown
                  value={inputData.status[0]}
                  options={statusData}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      status: [e.value], // Ensure it's always an array
                    });
                  }}
                  placeholder="Select a status"
                />
              </div>
              <div
                className="col-md-3 col-sm-12 mb-2"
                style={{ textAlign: "left" }}
              >
                <Dropdown
                  value={inputData.users[0]}
                  options={productUsers}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      users: [e.target.value],
                    });
                  }}
                  placeholder="Select Users"
                />
              </div>
              <div
                className="col-md-3 col-sm-12 mb-2"
                style={{ textAlign: "right" }}
              >
                <Button
                  type="button"
                  onClick={resetFilters}
                  className="p-button-danger"
                >
                  <i className="pi pi-sync"></i>
                </Button>
              </div>
            </div>
          </div>
        )}
        {layout === "list" ? (
          <div className="col-12">
            <Table className="table-design-heading">
              <tr>
                <th style={{ width: "30%", padding: "5px 5px" }}>
                  Client Name
                </th>
                <th style={{ width: "20%", padding: "5px 1px" }}>Mobile No.</th>
                <th style={{ width: "30%", padding: "5px 1px" }}>File</th>
                <th style={{ width: "20%", padding: "5px 1px" }}>Task</th>
              </tr>
            </Table>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const header =  renderHeader();

  return (
    <div className="dataview-demo">
      <div className="card">
        {/*<DataView
          value={filteredProducts || products}
          layout={layout}
          header={header}
          itemTemplate={itemTemplate}
          paginator
          rows={9}
  />*/}
        <div>
          {isFilterVisible && (
            <div>
              <div className="row">
                <div
                  className="col-md-4 col-sm-12 mb-2"
                  style={{ textAlign: "left" }}
                >
                  <InputText
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    style={{ width: "100%" }}
                    onChange={handleSearchInputChange}
                  />
                </div>
                <div className="col-md-4 col-sm-12 mb-2">
                  <Calendar
                    id="startDate"
                    name="startDate"
                    value={inputData.startDate}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        startDate: e.target.value,
                      });
                    }}
                    showIcon
                    required
                  />
                </div>
                <div
                  className="col-md-4 col-sm-12 mb-2"
                  style={{ textAlign: "right" }}
                >
                  <Calendar
                    id="startDate"
                    name="startDate"
                    value={inputData.endDate}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        endDate: e.target.value,
                      });
                    }}
                    showIcon
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-3 col-sm-12 mb-2"
                  style={{ textAlign: "left" }}
                >
                  <Dropdown
                    value={inputData.types[0]}
                    options={jobsData}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        types: [e.value], // Ensure it's always an array
                      });
                    }}
                    placeholder="Select a type"
                  />
                </div>
                <div
                  className="col-md-3 col-sm-12 mb-2"
                  style={{ textAlign: "left" }}
                >
                  <Dropdown
                    value={inputData.status[0]}
                    options={statusData}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        status: [e.value], // Ensure it's always an array
                      });
                    }}
                    placeholder="Select a status"
                  />
                </div>
                <div
                  className="col-md-3 col-sm-12 mb-2"
                  style={{ textAlign: "left" }}
                >
                  <Dropdown
                    value={inputData.users[0]}
                    options={productUsers}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setInputData({
                        ...inputData,
                        users: [e.target.value],
                      });
                    }}
                    placeholder="Select Users"
                  />
                </div>
                <div
                  className="col-md-3 col-sm-12 mb-2"
                  style={{ textAlign: "right" }}
                >
                  <Button
                    type="button"
                    onClick={resetFilters}
                    className="p-button-danger"
                  >
                    <i className="pi pi-sync"></i>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <DataTable
          value={filteredProducts || products}
          paginator
          paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          dataKey="id"
          className="p-datatable-customers client-table-design"
          resizableColumns
          columnResizeMode="fit"
        >
          <Column
            field="customerFileProcess.customerFile.customer.name"
            header="Customer"
            resizeable={false}
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcess.customerFile.customer.authorizedPersonMobile"
            header="Mobile"
            className="product-category table-design"
          ></Column>
          <Column
            field="customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name"
            header="Task"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {
                  rowData.customerFileProcessJobDTOList[0].customerFileJob
                    .mvnJob.name
                }
              </span>
            )}
          ></Column>
          <Column
            field="customerFileProcessJobDTOList[0].revenue.toFixed(0)"
            header="Revenue"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                ₹ {rowData.customerFileProcessJobDTOList[0].revenue.toFixed(0)}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Status"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0].status === "TO_BE_STARTED"
                  ? "PENDING"
                  : rowData.customerFileProcessJobDTOList[0].status}
                {rowData.customerFileProcessJobDTOList[0].timeBarred &&
                  " Time Barred"}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Completed On"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0].status === "COMPLETED"
                  ? formatDate(
                      rowData.customerFileProcessJobDTOList[0].completedOn
                    )
                  : ""}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Actual Cost"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0].status === "COMPLETED"
                  ? rowData.customerFileProcessJobDTOList[0].actualCost.toFixed(
                      0
                    )
                  : ""}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Profit / Loss"
            className="product-category table-design"
            body={(rowData) => (
              <span>
                {rowData.customerFileProcessJobDTOList[0].status ===
                "COMPLETED" ? (
                  rowData.customerFileProcessJobDTOList[0].revenue >
                  rowData.customerFileProcessJobDTOList[0].actualCost ? (
                    <Link
                      style={{
                        justifyContent: "center",
                        color: "green",
                      }}
                    >
                      + ₹{" "}
                      {rowData.customerFileProcessJobDTOList[0].revenue.toFixed(
                        0
                      ) -
                        rowData.customerFileProcessJobDTOList[0].actualCost.toFixed(
                          0
                        )}
                    </Link>
                  ) : rowData.customerFileProcessJobDTOList[0].revenue <
                    rowData.customerFileProcessJobDTOList[0].actualCost ? (
                    <Link
                      style={{
                        justifyContent: "center",
                        color: "red",
                      }}
                    >
                      - ₹{" "}
                      {rowData.customerFileProcessJobDTOList[0].actualCost.toFixed(
                        0
                      ) -
                        rowData.customerFileProcessJobDTOList[0].revenue.toFixed(
                          0
                        )}
                    </Link>
                  ) : (
                    <Link
                      style={{
                        justifyContent: "center",
                        color: "blue",
                      }}
                    >
                      On Going
                    </Link>
                  )
                ) : (
                  ""
                )}
              </span>
            )}
          ></Column>
          <Column
            field="frequency"
            header="Users"
            className="product-category table-design"
            body={(rowData) => {
              const userNames =
                rowData.customerFileProcessJobDTOList[0].customerFileProcessJobUsers.map(
                  (jobUser) =>
                    `${jobUser.jobUser.user.firstName} ${jobUser.jobUser.user.lastName}`
                );

              return <span>{userNames.join(", ")}</span>;
            }}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
}
