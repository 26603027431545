import React, { useState } from "react";
import JobProcessList from "./jobProcess/JobProcessList";

const DashboardUsers = ({ userProfileData }) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  return (
    <main id="main" className="main">
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              {/* Sales Card */}
              <div className="col-md-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 className="card-title">
                        Employee Task <span>| MVN</span>
                      </h5>
                      <i
                        className="pi pi-filter-fill"
                        onClick={toggleFilter}
                        style={{ color: "#1e439b" }}
                      ></i>
                    </div>
                    <JobProcessList
                      userProfileData={userProfileData}
                      isFilterVisible={isFilterVisible}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DashboardUsers;
