import { getUserData } from "../utils/localStorage";

const getHeaders = (contentType) => {
  const { token } = getUserData();
  const headers = {
    Authorization: "Bearer " + token,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
  };

  if (contentType === "multipart/form-data") {
    headers["Content-Type"] = "multipart/form-data";
  } else {
    headers["Content-Type"] = "application/json";
  }

  return headers;
};

export default getHeaders;
