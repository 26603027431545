import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { addNewLead, updateNewLead } from "../../services/newLead";
import { updateCustomerFile } from "../../services/customerFile";

export default function CustomerFileEdit() {
  const navigate = useNavigate();
  const { state } = useLocation();

  let clientId = state?.clientId;
  let customerFile = state?.rowData;

  const [input, setInput] = useState({
    fileName: customerFile?.fileName || "",
    fileNumber: customerFile?.fileNumber || "",
  });
  const [errors, setErrors] = useState({
    fileName: "",
    fileNumber: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.fileName.trim() === "") {
      newErrors.fileName = "Please enter the file name.";
      isValid = false;
    } else {
      newErrors.fileName = "";
    }

    if (input.fileNumber.trim() === "") {
      newErrors.fileNumber = "Please enter the file Number.";
      isValid = false;
    } else {
      newErrors.fileNumber = "";
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      
        let data = {
          ...customerFile,
          id: customerFile?.id,
          fileName: input.fileName,
          fileNumber: input.fileNumber,
        };

        updateCustomerFile(clientId, data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/customer-file/list", {
                  state: clientId,
                });
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Customer File Edit</h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="fileName" className="form-label">
                        File Name
                      </label>
                      <InputText
                        type="text"
                        style={{ width: "100%" }}
                        id="fileName"
                        name="fileName"
                        value={input.fileName}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            fileName: e.target.value,
                          });
                        }}
                        required
                      />
                      <div className="text-danger">{errors.fileName}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="fileNumber" className="form-label">
                        File Number
                      </label>
                      <InputText
                        type="text"
                        value={input.fileNumber}
                        id="fileNumber"
                        name="fileNumber"
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            fileNumber: e.target.value,
                          })
                        }
                        required
                      />
                      <div className="text-danger">{errors.fileNumber}</div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
