import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { errorMessage } from "../../utils/alertMessage";
import { getUser } from "../../services/userMasterUser";
import { getEmployeeRevenue } from "../../services/EmployeeRevenue";
import { DataView } from "primereact/dataview";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatDate } from "../../constants/FormateDate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function EmployeeTimeSheet() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [usersData, setUsersData] = useState([]);
  const [employeeRevenueData, setEmployeeRevenueData] = useState([]);

  const fetchUserData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const filteredData = response.data.map((users) => ({
            label: users.firstName + " " + users.lastName,
            value: users.id,
          }));
          setUsersData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  useEffect(() => {
    fetchUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (usersData.length > 0) fetchData();
  }, [usersData]);


  let startDate = new Date();
  let initialEndDate = new Date();

  startDate.setDate(startDate.getDate() - 7);

  const fetchData = () => {
    let data = {
      users: usersData.map((item) => item.value),
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(initialEndDate).toISOString(),
      status: ["TO_BE_STARTED", "ONGOING", "COMPLETED"],
    };

    getEmployeeRevenue(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            setEmployeeRevenueData(
              response.data.sort((a, b) =>
                a.jobs[0].jobUser.user.firstName
                  .trim()
                  .localeCompare(b.jobs[0].jobUser.user.firstName.trim())
              )
            );
          }
        } 
      })
      .catch((err) => {
        errorMessage();
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderListItem = (data, indexing) => {
    let ongoingCount = 0;
    let toBeStartedCount = 0;
    let completedCount = 0;

    data.jobs.forEach((job) => {
      const status = job.customerFileProcessJob.status;
      if (status === "ONGOING") {
        ongoingCount++;
      } else if (status === "TO_BE_STARTED") {
        toBeStartedCount++;
      } else if (status === "COMPLETED") {
        completedCount++;
      }
    });

     let tableData = data.jobs.sort((a, b) =>
       a.customerFileProcessJob.customerFileJob.customerFile.customer.name
         .trim()
         .localeCompare(
           b.customerFileProcessJob.customerFileJob.customerFile.customer.name.trim()
         )
     );
    return (
      <div className="col-12 ">
        <Accordion className="accordion-custom" activeIndex={indexing}>
          <AccordionTab
            header={
              <React.Fragment>
                <div className="product-list-item backgroundDesign">
                  <div className="product-list-detail">
                    <div className="product-category">
                      <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                      {data.jobs[0].jobUser.user.firstName +
                        " " +
                        data.jobs[0].jobUser.user.lastName}
                    </div>
                  </div>
                  <div className="product-list-detail">
                    <div className="product-category">
                      <span style={{ color: "blue" }}>PENDING: </span>{" "}
                      {toBeStartedCount}
                    </div>
                  </div>
                  <div className="product-list-detail">
                    <div className="product-category">
                      <span style={{ color: "Orange" }}>ONGOING: </span>{" "}
                      {ongoingCount}
                    </div>
                  </div>
                  <div className="product-list-detail">
                    <div className="product-category">
                      <span style={{ color: "green" }}> COMPLETED: </span>{" "}
                      {completedCount}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          >
            <DataTable
              value={tableData}
              paginator
              scrollable
              scrollHeight="flex"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              dataKey="id"
              className="p-datatable-customers"
              showGridlines
              resizableColumns
              columnResizeMode="fit"
            >
              <Column
                field="customerFileProcessJob.customerFileJob.customerFile.customer.name"
                header="Company"
                body={(rowData) => (
                  <span>
                    {
                      rowData.customerFileProcessJob.customerFileJob
                        .customerFile.customer.name
                    }
                  </span>
                )}
                className="product-category table-design"
                style={{ width: "35%" }}
              ></Column>
              <Column
                field="customerFileProcessJob.customerFileJob.mvnJob.name"
                header="Task"
                className="product-category table-design"
                body={(rowData) => (
                  <span>
                    {rowData.customerFileProcessJob.customerFileJob.mvnJob.name}
                  </span>
                )}
                style={{ width: "35%" }}
              ></Column>
              <Column
                field="customerFileProcessJob.startOn"
                header="Engagement Period"
                className="product-category table-design"
                body={(rowData) => (
                  <span>
                    {formatDate(rowData.customerFileProcessJob.startOn)} -{" "}
                    {formatDate(rowData.customerFileProcessJob.endOn)}
                  </span>
                )}
                style={{ width: "15%" }}
              ></Column>
              <Column
                field="customerFileProcessJob.status"
                header="Status"
                className="product-category table-design"
                body={(rowData) => (
                  <span
                    style={{
                      color:
                        rowData.customerFileProcessJob.status === "ONGOING"
                          ? "Orange"
                          : rowData.customerFileProcessJob.status ===
                            "COMPLETED"
                          ? "green"
                          : "blue",
                    }}
                  >
                    {rowData.customerFileProcessJob.status === "TO_BE_STARTED"
                      ? "PENDING"
                      : rowData.customerFileProcessJob.status}
                  </span>
                )}
                style={{ width: "15%" }}
              ></Column>
            </DataTable>
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const renderGridItem = (data, index) => {
     let ongoingCount = 0;
     let toBeStartedCount = 0;
     let completedCount = 0;

     data.jobs.forEach((job) => {
       const status = job.customerFileProcessJob.status;
       if (status === "ONGOING") {
         ongoingCount++;
       } else if (status === "TO_BE_STARTED") {
         toBeStartedCount++;
       } else if (status === "COMPLETED") {
         completedCount++;
       }
     });

     let tableData = data.jobs.sort((a, b) =>
       a.customerFileProcessJob.customerFileJob.customerFile.customer.name
         .trim()
         .localeCompare(
           b.customerFileProcessJob.customerFileJob.customerFile.customer.name.trim()
         )
     );
    return (
      <div className="col-12">
        <Accordion className="accordion-custom" activeIndex={index}>
          <AccordionTab
            header={
              <React.Fragment>
                <div className="product-grid-item backgroundDesign">
                  <div className="product-list-detail">
                    <div
                      className="product-category"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                        {data.jobs[0].jobUser.user.firstName +
                          " " +
                          data.jobs[0].jobUser.user.lastName}
                      </div>
                      <div className="product-list-detail">
                        <div className="product-category">
                          <span style={{ color: "blue" }}>PENDING: </span>{" "}
                          {toBeStartedCount}
                        </div>
                      </div>
                    </div>
                    <div
                      className="product-category"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="product-list-detail">
                        <div className="product-category">
                          <span style={{ color: "Orange" }}>ONGOING: </span>{" "}
                          {ongoingCount}
                        </div>
                      </div>
                      <div className="product-list-detail">
                        <div className="product-category">
                          <span style={{ color: "green" }}> COMPLETED: </span>{" "}
                          {completedCount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          >
            <div>
              <DataTable
                value={tableData}
                paginator
                paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                dataKey="id"
                resizableColumns
                columnResizeMode="fit"
              >
                <Column
                  field="customerFileProcessJob.customerFileJob.customerFile.customer.name"
                  header="Company"
                  body={(rowData) => (
                    <span>
                      {
                        rowData.customerFileProcessJob.customerFileJob
                          .customerFile.customer.name
                      }
                    </span>
                  )}
                  className="product-category table-design"
                  style={{ width: "35%" }}
                ></Column>
                <Column
                  field="customerFileProcessJob.customerFileJob.mvnJob.name"
                  header="Task"
                  className="product-category table-design"
                  body={(rowData) => (
                    <span>
                      {
                        rowData.customerFileProcessJob.customerFileJob.mvnJob
                          .name
                      }
                    </span>
                  )}
                  style={{ width: "35%" }}
                ></Column>
                <Column
                  field="customerFileProcessJob.startOn"
                  header="Engagement Period"
                  className="product-category table-design"
                  body={(rowData) => (
                    <span>
                      {formatDate(rowData.customerFileProcessJob.startOn)} -{" "}
                      {formatDate(rowData.customerFileProcessJob.endOn)}
                    </span>
                  )}
                  style={{ width: "15%" }}
                ></Column>
                <Column
                  field="customerFileProcessJob.status"
                  header="Status"
                  className="product-category table-design"
                  body={(rowData) => (
                    <span
                      style={{
                        color:
                          rowData.customerFileProcessJob.status === "ONGOING"
                            ? "green"
                            : "blue",
                      }}
                    >
                      {rowData.customerFileProcessJob.status === "TO_BE_STARTED"
                        ? "PENDING"
                        : rowData.customerFileProcessJob.status}
                    </span>
                  )}
                  style={{ width: "15%" }}
                ></Column>
              </DataTable>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }

    const layout = windowWidth > 460 ? "list" : "grid";
    if (layout === "list") return renderListItem(product);
    else if (layout === "grid") return renderGridItem(product);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Employee Time Sheet</h5>
                </div>
                {/*<form>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="jobUser" className="form-label">
                        Job User
                      </label>
                      <MultiSelect
                        display="chip"
                        value={input.users}
                        style={{ width: "100%" }}
                        options={usersData}
                        onChange={(e) => {
                          setInput({
                            ...input,
                            users: e.target.value,
                          });
                        }}
                      />
                      <div className="text-danger mt-1">{errors.users}</div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <InputText
                        type="text"
                        id="status"
                        name="status"
                        style={{ width: "100%", textTransform: "capitalize" }}
                        value="COMPLETED"
                        onChange={(e) => {
                          setInput({
                            ...input,
                            status: e.target.value,
                          });
                        }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mb-5">
                    
                    {[...Array(12).keys()].map((month) => (
                      <div style={{ flex: 1, marginBottom: "5px" }} key={month}>
                        <button
                          type="button"
                          className="move-btn move-btn-month"
                          onClick={() => handleMonthButtonClick(month + 1)}
                        >
                          {new Date(2000, month).toLocaleString("default", {
                            month: "short",
                          })}
                        </button>
                      </div>
                    ))}
                  </div>
                        </form>*/}
                <div className="dataview-demo">
                  <div className="card">
                    <DataView
                      value={employeeRevenueData}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
