import React from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";

import JobProcessListEdit from "../jobProcess/JobProcessListWithEdit";

export default function RunningProcessList() {
  return (
    <main id="main" className="main">
      <section className="section">
        <JobProcessListEdit />
      </section>
    </main>
  );
}
