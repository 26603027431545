import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "../../assets/DataView/DataViewDemo.css";
import "../../assets/DataView/index.css";
import { InputText } from "primereact/inputtext";
import { getJobProcess } from "../../services/process";
import "../../assets/css/AccordionDemo.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import logoBig from "../../assets/logo1.png";
import { Button } from "primereact/button";

export default function PendingFees() {
  const [products, setProducts] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const fetchCustomerJobProcess = (data) => {
    getJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const filteredData = Res.filter(
            (item) =>
              item.customerFileProcessJobDTOList[0].revenue !==
              item.customerFileProcessJobDTOList[0].totalPaid
          );
          // setProducts(filteredData.reverse());
          setProducts(
            filteredData.sort((a, b) =>
              a.customerFileProcess.customerFile.customer.name
                .trim()
                .localeCompare(
                  b.customerFileProcess.customerFile.customer.name.trim()
                )
            )
          );
          
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: ["COMPLETED"],
      users: [],
    };
    fetchCustomerJobProcess(data);
  }, []);

  const handleSearchInputChange = (event) => {
    const searchQueryValue = event.target.value;
    const filteredProducts = products.filter((item) => {
      const name =
        item.customerFileProcess.customerFile.customer.name.toLowerCase();
      const task =
        item.customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name.toLowerCase();
      const fileName =
        item.customerFileProcess.customerFile.fileName.toLowerCase();
      const fileNumber =
        item.customerFileProcess.customerFile.fileNumber.toLowerCase();
      const status = item.customerFileProcessJobDTOList[0].status.toLowerCase();
      const matchesName = name.includes(searchQueryValue.toLowerCase());
      const matchesTask = task.includes(searchQueryValue.toLowerCase());
      const matchesFileName = fileName.includes(searchQueryValue.toLowerCase());
      const matchesFileNumber = fileNumber.includes(
        searchQueryValue.toLowerCase()
      );
      const matchesStatus = status.includes(searchQueryValue.toLowerCase());
      return (
        matchesName ||
        matchesTask ||
        matchesFileName ||
        matchesFileNumber ||
        matchesStatus
      );
    });
    setFilteredProducts(filteredProducts);
    setSearchQuery(searchQueryValue);
  };

  const downloadAndPrintPDF = (data, type) => {
    const doc = new jsPDF("landscape");
    const logoImg = new Image();
    logoImg.src = logoBig;

    doc.addImage(logoImg, "PNG", 10, 5, 50, 15);
    doc.setFontSize(24);
    doc.text("Pending Fees List", 200, 15);

    doc.autoTable({
      head: [
        ["Customer Name", "Mobile No.", "Task", "Status", "Revenue", "Balance"],
      ],
      body: data.map((item) => [
        item.customerFileProcess.customerFile.customer.name,
        item.customerFileProcess.customerFile.customer.authorizedPersonMobile,
        item.customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name,
        item.customerFileProcessJobDTOList[0].status,
        item.customerFileProcessJobDTOList[0].revenue,
        `${
          item.customerFileProcessJobDTOList[0].revenue -
          item.customerFileProcessJobDTOList[0].totalPaid
        }`,
      ]),
      startY: 25,
      theme: "grid",
      headStyles: {},
      styles: {
        overflow: "linebreak",
        fontSize: 12,
      },
    });

    if (type === "print") {
      // Create blob URL
      const blob = doc.output("blob");
      const blobURL = URL.createObjectURL(blob);

      // Open blob URL in a new window
      const printWindow = window.open(blobURL);
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    } else {
      doc.save("pending-fees.pdf");
    }
  };

  return (
    <main id="main" className="main">
      <section className="section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              {/* Sales Card */}
              <div className="col-md-12">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 className="card-title">Pending Fees List</h5>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 20,
                        }}
                      >
                        <i
                          onClick={() =>
                            downloadAndPrintPDF(
                              filteredProducts || products,
                              "download"
                            )
                          }
                          className="pi pi-download"
                          style={{ fontSize: "2rem", color: "#1e439b", cursor: "pointer" }}
                        ></i>
                        <i
                          onClick={() =>
                            downloadAndPrintPDF(
                              filteredProducts || products,
                              "print"
                            )
                          }
                          className="pi pi-print"
                          style={{ fontSize: "2rem", color: "#ff0101", cursor: "pointer" }}
                        ></i>
                      </div>
                    </div>
                    <div
                      className="col-12"
                      style={{
                        textAlign: "center",
                        margin: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <InputText
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                      />
                    </div>
                    <div id="pdfContent">
                      <DataTable
                        value={filteredProducts || products}
                        paginator={!isGeneratingPDF}
                        paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        rowsPerPageOptions={[10, 20, 50]}
                        dataKey="id"
                        className="p-datatable-customers client-table-design"
                        resizableColumns
                        columnResizeMode="fit"
                      >
                        <Column
                          field="customerFileProcess.customerFile.customer.name"
                          header="Customer Name"
                          resizeable={false}
                          className="product-category table-design"
                        ></Column>
                        <Column
                          field="customerFileProcess.customerFile.customer.authorizedPersonMobile"
                          header="Mobile No."
                          className="product-category table-design"
                        ></Column>
                        <Column
                          field="customerFileProcessJobDTOList[0].customerFileJob.mvnJob.name"
                          header="Task"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span>
                              {
                                rowData.customerFileProcessJobDTOList[0]
                                  .customerFileJob.mvnJob.name
                              }
                            </span>
                          )}
                        ></Column>
                        <Column
                          field="data.customerFileProcessJobDTOList[0].status"
                          header="Status"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span
                              style={{
                                color:
                                  rowData.customerFileProcessJobDTOList[0]
                                    .status === "ONGOING"
                                    ? "Orange"
                                    : rowData.customerFileProcessJobDTOList[0]
                                        .status === "COMPLETED"
                                    ? "green"
                                    : "blue",
                              }}
                            >
                              {rowData.customerFileProcessJobDTOList[0].status}
                            </span>
                          )}
                        ></Column>
                        <Column
                          field="data.customerFileProcessJobDTOList[0].revenue"
                          header="Revenue"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span>
                              ₹{" "}
                              {rowData.customerFileProcessJobDTOList[0].revenue}
                            </span>
                          )}
                        ></Column>
                        <Column
                          field="data.customerFileProcessJobDTOList[0].revenue"
                          header="Balance"
                          className="product-category table-design"
                          body={(rowData) => (
                            <span>
                              ₹{" "}
                              {rowData.customerFileProcessJobDTOList[0]
                                .revenue -
                                rowData.customerFileProcessJobDTOList[0]
                                  .totalPaid}
                            </span>
                          )}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
