import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { errorMessage } from "../../utils/alertMessage";
import { DataView } from "primereact/dataview";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getResourceAllocation } from "../../services/resourceAllocation";
import { Dropdown } from "primereact/dropdown";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logoBig from "../../assets/logo1.png";
import "jspdf-autotable";

export default function ListOfClient() {
  const [employeeRevenueData, setEmployeeRevenueData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState(null);
  const [filterServiceValue, setFilterServiceValue] = useState(null);

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [activeIndices, setActiveIndices] = useState([]);

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    let data = {
      dateFilterType: "",
    };

    getResourceAllocation(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            const formattedData = formatData(response.data);
            setEmployeeRevenueData(formattedData);
            setFilteredData(
              formattedData.sort((a, b) =>
                a.name
                  .trim()
                  .localeCompare(b.name.trim())
              )
            );
            setActiveIndices(formattedData.map((_, index) => index)); // Set all accordions to open initially
          }
        } else {
          errorMessage();
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const dropdownOptions = employeeRevenueData.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const dropdownServiceOptions = employeeRevenueData.map((item) => ({
    label: item.service,
    value: item.service,
  }));

  const uniqueServiceOptions = [];
  const serviceSet = new Set();

  dropdownServiceOptions.forEach((item) => {
    if (!serviceSet.has(item.value)) {
      serviceSet.add(item.value);
      uniqueServiceOptions.push(item);
    }
  });

  const formatData = (data) => {
    return Object.keys(data.jobMap).map((jobId) => ({
      ...data.jobMap[jobId],
      customerFileJobs: data.customerFileJobMap[jobId] || [],
    }));
  };

  const handleFilterChange = (e) => {
    setFilterValue(e.value);
    setFilterServiceValue("");
    if (e.value) {
      const filtered = employeeRevenueData.filter(
        (item) => item.name === e.value
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(employeeRevenueData);
    }
  };

  const handleFilterServiceChange = (e) => {
    setFilterServiceValue(e.value);
    setFilterValue("");
    if (e.value) {
      const filtered = employeeRevenueData.filter(
        (item) => item.service === e.value
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(employeeRevenueData);
    }
  };

  const generatePDF = (type) => {
    setIsGeneratingPDF(true);
    const allIndices = filteredData.map((_, index) => index);
    setActiveIndices(allIndices); // Open all accordions
    setTimeout(() => {
      const pdf = new jsPDF("landScape");

      filteredData.forEach((data, index) => {
        const logoImg = new Image();
        logoImg.src = logoBig;

        pdf.addImage(logoImg, "PNG", 10, 5, 50, 15);
        pdf.setFontSize(24);
        pdf.text("Service Wise Customer Data", 170, 15);
        // pdf.addPage();

        if (index > 0) {
          pdf.addPage();
        }
        pdf.setFontSize(14);
        pdf.text(data.name, 14, 30);

        const tableData = data.customerFileJobs.map((job) => [
          job.customerFile.customer.name,
          job.customerFile.customer.authorizedPerson,
          job.customerFile.customer.authorizedPersonMobile,
          job.customerFile.customer.email,
        ]);

        pdf.autoTable({
          startY: 35,
          head: [
            ["Customer Name", "Authorized Person", "Mobile No.", "Email Id"],
          ],
          body: tableData,
          theme: "grid",

          styles: {
            overflow: "linebreak",
            fontSize: 10,
          },
        });
      });

      if (type === "print") {
        // Create blob URL
        const blob = pdf.output("blob");
        const blobURL = URL.createObjectURL(blob);

        // Open blob URL in a new window
        const printWindow = window.open(blobURL);
        if (printWindow) {
          printWindow.onload = () => {
            printWindow.print();
          };
        }
      } else {
        pdf.save("service-wise-customer-data.pdf");
      }

      setIsGeneratingPDF(false);
      setActiveIndices([]); // Reset accordions to their previous state
    }, 1000);
  };

  const renderListItem = (data, indexing) => {
    let startDate = new Date();
    let initialEndDate = new Date();

    const dateType = data?.type;
    const jobDate = data?.jobDate;
    const jobDays = data?.jobDays;

    if (dateType === "compliance_date") {
      initialEndDate = new Date(jobDate);
    } else if (dateType === "no_of_days") {
      initialEndDate.setDate(initialEndDate.getDate() + jobDays - 1);
    }

    let tableData = data.customerFileJobs.sort((a, b) =>
      a.customerFile.customer.name
        .trim()
        .localeCompare(b.customerFile.customer.name.trim())
    );
    return (
      <div className="col-12 ">
        {initialEndDate > startDate && (
          <Accordion
            className="accordion-custom"
            activeIndex={activeIndices}
            multiple
          >
            <AccordionTab
              header={
                <React.Fragment>
                  <div className="product-list-item backgroundDesign">
                    <div className="product-list-detail">
                      <div className="product-category">
                        <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                        {data.name}
                      </div>
                    </div>
                    <div className="product-list-detail">
                      <div className="product-category"></div>
                    </div>
                  </div>
                </React.Fragment>
              }
            >
              <DataTable
                value={tableData}
                paginator={!isGeneratingPDF}
                scrollable
                scrollHeight="flex"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                dataKey="id"
                className="p-datatable-customers"
                showGridlines
                resizableColumns
                columnResizeMode="fit"
              >
                <Column
                  field="customerFile.customer.name"
                  header="Customer Name"
                  body={(rowData) => (
                    <span>{rowData.customerFile.customer.name}</span>
                  )}
                  className="product-category table-design"
                ></Column>
                <Column
                  field="authorizedPerson"
                  header="Authorized Person"
                  className="product-category table-design"
                  body={(rowData) => (
                    <span>
                      {rowData.customerFile.customer.authorizedPerson}
                    </span>
                  )}
                ></Column>
                <Column
                  field="authorizedPersonMobile"
                  header="Mobile No."
                  className="product-category table-design"
                  body={(rowData) => (
                    <span>
                      {rowData.customerFile.customer.authorizedPersonMobile}
                    </span>
                  )}
                ></Column>
                <Column
                  field="email"
                  header="Email Id"
                  className="product-category table-design"
                  body={(rowData) => (
                    <span>{rowData.customerFile.customer.email}</span>
                  )}
                ></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        )}
      </div>
    );
  };

  const itemTemplate = (product, index) => {
    if (!product) {
      return;
    }

    return renderListItem(product, index);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">List Of Customer</h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 20,
                      marginTop: 10,
                    }}
                  >
                    <i
                      onClick={() => generatePDF("download")}
                      className="pi pi-download"
                      style={{
                        fontSize: "2rem",
                        color: "#1e439b",
                        cursor: "pointer",
                      }}
                    ></i>
                    <i
                      onClick={() => generatePDF("print")}
                      className="pi pi-print"
                      style={{
                        fontSize: "2rem",
                        color: "#ff0101",
                        cursor: "pointer",
                      }}
                    ></i>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Dropdown
                      value={filterServiceValue}
                      options={uniqueServiceOptions}
                      onChange={handleFilterServiceChange}
                      placeholder="Select a Service"
                      className="p-mr-2"
                      showClear
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <Dropdown
                      value={filterValue}
                      options={dropdownOptions}
                      onChange={handleFilterChange}
                      placeholder="Select a Task"
                      className="p-mr-2"
                      showClear
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="dataview-demo">
                  <div className="card" id="pdfContent">
                    <DataView
                      value={filteredData}
                      itemTemplate={itemTemplate}
                      paginator={!isGeneratingPDF}
                      rows={9}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
