import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { errorMessage, successMessage } from "../../../utils/alertMessage";
import { getUser } from "../../../services/userMasterUser";
import { addJobUser, updateJobUser } from "../../../services/JobMasterJobsUser";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { getJobs } from "../../../services/JobMasterJobs";

export default function JobUserCreate() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userDTOList = state?.userDTOList.map((item) => (item.user.id));
  const [input, setInput] = useState({
    jobs: state?.mvnJobDTO.id || "",
    users: userDTOList || "",
  });
  const [errors, setErrors] = useState({
    jobs: "",
    users: "",
  });

  const [jobsData, setJobsData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const fetchJobUserData = () => {
    getJobs()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const filteredData = response.data.map((jobs) => ({
            label: jobs.name,
            value: jobs.id,
          }));
          setJobsData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  const fetchUserData = () => {
    getUser()
      .then((response) => {
        if (!response.data.error) {
          // Extracting only name and id from the response data
          const filteredData = response.data.map((users) => ({
            label: users.firstName + " " + users.lastName,
            value: users.id,
          }));
          setUsersData(filteredData);
        } else {
          errorMessage("Invalid Credentials");
        }
      })
      .catch((err) => {
        errorMessage();
      });
    // .finally(() => setPending(false));
  };

  useEffect(() => {
    fetchJobUserData();
    fetchUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.jobs === "") {
      newErrors.jobs = "Please select any Job";
      isValid = false;
    } else {
      newErrors.jobs = "";
    }
    if (input.users === "") {
      newErrors.users = "Please select any user";
      isValid = false;
    } else {
      newErrors.users = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (state) {
         let data = {
            // id: state.id,
           jobs: [input.jobs],
           users: input.users,
           status: true
         };
          updateJobUser(data)
            .then((response) => {
              if (!response.data.error) {
                if (response.data.success === false) {
                  errorMessage(response.data.message);
                } else {
                  successMessage(response.data.message);
                  navigate("/job-master/jobs-user/list");
                }
              } else {
                // errorMessage(response.data.errors.errors[0].message);
              }
            })
            .catch((err) => {
              errorMessage();
            });
      } else {
        let data = {
          jobs: [input.jobs],
          users: input.users,
          status: true
        };
        addJobUser(data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/job-master/jobs-user/list");
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  Jobs User {state ? "Update" : "Create"}
                </h5>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="jobs" className="form-label">
                        Jobs
                      </label>
                    </div>
                    <Dropdown
                      value={input.jobs}
                      options={jobsData}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          jobs: e.target.value,
                        });
                      }}
                      placeholder="Select a jobs"
                    />
                    <div className="text-danger mt-1">{errors.jobs}</div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <label htmlFor="users" className="form-label">
                        User
                      </label>
                    </div>

                    <MultiSelect
                      display="chip"
                      value={input.users}
                      options={usersData}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          users: e.target.value,
                        });
                      }}
                    />
                    <div className="text-danger mt-1">{errors.users}</div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="move-btn move-btn-su">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
