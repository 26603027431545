import React, { useEffect, useState } from "react";
import DashboardAdmin from "./DashboardAdmin";
import DashboardUsers from "./DashboardUsers";
import { getUserProfile } from "../services/userProfile";
import DashboardAccountant from "./DashboardAccountant";

const Dashboard = () => {
  const [userProfileData, setUserProfileData] = useState(null); // Initialize userProfileData with null
  const [isLoading, setIsLoading] = useState(true); // Initialize isLoading to true

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setUserProfileData(Res);
        }
        setIsLoading(false); // Set isLoading to false regardless of response
      })
      .catch((err) => {
        console.error("Error:", err);
        setIsLoading(false); // Set isLoading to false in case of error
      });
  }, []);

  if (isLoading) {
    // If loading, display a loading indicator or any other component
    return (
      <main id="main" className="main">
        <section className="section dashboard">
          <div className="row">
            {/* Left side columns */}
            <div className="col-lg-12">
              <div className="row">Loading...</div>
            </div>
          </div>
        </section>
      </main>
    );
  }

  return (
    <div>
      {userProfileData?.role === "ROLE EMPLOYEE" ? (
        <DashboardUsers userProfileData={userProfileData} />
      ) : userProfileData?.role === "ROLE_ACCOUNTANT" ? (
        <DashboardAccountant />
      ) : (
        <DashboardAdmin />
      )}
    </div>
  );
};

export default Dashboard;
