import React, { useEffect, useState } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { DataView } from "primereact/dataview";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { getResourceAllocation } from "../../services/resourceAllocation";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getJobProcess } from "../../services/process";

export default function ResourceAllocation() {
  const navigate = useNavigate();
  const [processCreate, setProcessCreate] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [jobProcessData, setJobProcessData] = useState(null);

  let startDate = new Date();
  let initialEndDate = new Date();

  const [employeeRevenueData, setEmployeeRevenueData] = useState({});

  useEffect(() => {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
      types: [],
      status: [],
      users: [],
    };
    getJobProcess(data)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          
          setJobProcessData(Res);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (startDate, endDate) => {
    let data = {
      startDate: startDate,
      endDate: endDate,
      dateFilterType: "dueOn",
    };
    getResourceAllocation(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            successMessage(response.data.message);
            
            // setEmployeeRevenueData(response.data);
            setEmployeeRevenueData(formatData(response.data));
          }
        } else {
          // errorMessage(response.data.errors.errors[0].message);
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  // startDate.setDate(startDate.getDate() - 7);

  const fetchData = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Get the number of days in the specified month

    const formattedMonth =
      currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
    const startDate = `${currentYear}-${formattedMonth}-01T00:00:00.000Z`;
    const endDate = `${currentYear}-${formattedMonth}-${daysInMonth}T23:59:59.999Z`;

    let data = {
      startDate: startDate,
      endDate: endDate,
      dateFilterType: "dueOn",
    };

    
    getResourceAllocation(data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            // setEmployeeRevenueData(response.data);
            setEmployeeRevenueData(formatData(response.data));
          }
        } else {
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  const formatData = (data) => {
    return Object.keys(data.jobMap).map((jobId) => ({
      ...data.jobMap[jobId],
      customerFileJobs: data.customerFileJobMap[jobId] || [],
    }));
  };

  const isCustomerPresentInJobProcess = (selectedData) => {
    if (!jobProcessData || !jobProcessData.length) return false;
    const customerId = selectedData?.customerFile?.customer?.id;
    const fileId = selectedData?.customerFile?.id;
    const mvnJobId = selectedData?.mvnJob?.id;
    if (!customerId || !fileId || !mvnJobId) return false;

    return jobProcessData.some((process) =>
      process.customerFileProcessJobDTOList.some(
        (job) =>
          job.customerFileJob.customerFile.customer.id === customerId &&
          job.customerFileJob.customerFile.id === fileId &&
          job.customerFileJob.mvnJob.id === mvnJobId
      )
    );
  };

  const onClickProcessCreate = (data) => {
    if (!isCustomerPresentInJobProcess(data)) {
      navigate("/process/create", {
        state: {
          data: data,
          clientId: data.customerFile.customer.id,
          fileId: data.customerFile.id,
        },
      });
    }
    setSelectedData(data);
    setProcessCreate(true);
  };

  const onHideProcessCreate = () => {
    setSelectedData(null);
    setProcessCreate(false);
  };

  const renderDialogMessage = () => {
    return (
      <Dialog
        header="Alert"
        visible={processCreate}
        onHide={() => onHideProcessCreate()}
        breakpoints={{ "960px": "75vw" }}
        style={{ width: "50vw" }}
      >
        <div>
          <h6>
            This Task is Already Running For This Client, If You Still Want To
            Create A Duplicate Process Please Click Below.
          </h6>
          <div className="col-md-12 text-center">
            <Link
              to={"/process/create"}
              state={{
                data: selectedData,
                clientId: selectedData?.customerFile?.customer?.id,
                fileId: selectedData?.customerFile?.id,
              }}
              className="move-btn-small move-btn-vu center-text"
            >
              CONFIRM
            </Link>
          </div>
        </div>
      </Dialog>
    );
  };

  const renderListItem = (data, indexing) => {
    let startDate = new Date();
    let initialEndDate = new Date();

    const dateType = data?.type;
    const jobDate = data?.jobDate;
    const jobDays = data?.jobDays;

    if (dateType === "compliance_date") {
      // If dateType is "compliance_date", set initialEndDate to a specific date
      initialEndDate = new Date(jobDate);
    } else if (dateType === "no_of_days") {
      initialEndDate.setDate(initialEndDate.getDate() + jobDays - 1);
    }
     let tableData = data.customerFileJobs.sort((a, b) =>
       a.customerFile.customer.name
         .trim()
         .localeCompare(
           b.customerFile.customer.name.trim()
         )
     );
    return (
      <div className="col-12 ">
        {initialEndDate > startDate && (
          <Accordion className="accordion-custom" activeIndex={indexing}>
            <AccordionTab
              header={
                <React.Fragment>
                  <div className="product-list-item backgroundDesign">
                    <div className="product-list-detail">
                      <div className="product-category">
                        <i className="pi pi-user product-category-icon blueColorIcon"></i>{" "}
                        {data.name}
                      </div>
                    </div>
                    <div className="product-list-detail">
                      <div className="product-category"></div>
                    </div>
                  </div>
                </React.Fragment>
              }
            >
              <DataTable
                value={tableData}
                paginator
                scrollable
                scrollHeight="flex"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                dataKey="id"
                className="p-datatable-customers"
                showGridlines
                resizableColumns
                columnResizeMode="fit"
              >
                <Column
                  field="customerFile.customer.name"
                  header="Customer Name"
                  body={(rowData) => (
                    <span>{rowData.customerFile.customer.name}</span>
                  )}
                  className="product-category table-design"
                ></Column>
                {/*<Column
                  field="customerFile.fileName"
                  header="File Name"
                  body={(rowData) => (
                    <span>{rowData.customerFile.fileName}</span>
                  )}
                  className="product-category table-design"
                ></Column>*/}
                <Column
                  field="customerFile.fileName"
                  header="Add New Process"
                  body={(rowData) => (
                    <Button
                      onClick={() =>
                        onClickProcessCreate(rowData, "displayMessage")
                      }
                      className="move-btn-small move-btn-vu center-text"
                    >
                      <i className="bi bi-box-arrow-in-right"></i>
                    </Button>
                  )}
                  className="product-category table-design"
                ></Column>
              </DataTable>
            </AccordionTab>
          </Accordion>
        )}
      </div>
    );
  };

  const itemTemplate = (product) => {
    if (!product) {
      return;
    }

    return renderListItem(product);
  };

  const handleMonthButtonClick = (month) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, month, 0).getDate(); // Get the number of days in the specified month

    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const startDate = `${currentYear}-${formattedMonth}-01T00:00:00.000Z`;
    const endDate = `${currentYear}-${formattedMonth}-${daysInMonth}T23:59:59.999Z`;

    handleSubmit(startDate, endDate);
  };

  return (
    <main id="main" className="main">
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5 className="card-title">Resource Allocation</h5>
                </div>
                <form>
                  <div className="row mb-5">
                    {/* Buttons for each month */}
                    {[...Array(12).keys()].map((month) => (
                      <div style={{ flex: 1, marginBottom: "5px" }} key={month}>
                        <button
                          type="button"
                          className="move-btn move-btn-month"
                          onClick={() => handleMonthButtonClick(month + 1)}
                        >
                          {new Date(2000, month).toLocaleString("default", {
                            month: "short",
                          })}
                        </button>
                      </div>
                    ))}
                  </div>
                </form>
                <div className="dataview-demo">
                  <div className="card">
                    <DataView
                      value={employeeRevenueData}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={9}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{renderDialogMessage()}</div>
      </section>
    </main>
  );
}
